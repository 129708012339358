@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.quantity-calculator {
  width: 185px;
  height: 55px;
  padding: 13px 15px;
  border: 2px solid $White;
  @extend %flex-justify-between-center;

  @media screen and (max-width: 991px) {
    width: 150px;
    height: 48px;
    padding: 12px 15px;
  }

  &_minus, &_plus {
    width: 32px !important;
    height: 32px;
    color: $White;
    cursor: pointer;
    font-weight: 100;
    background: none;
    border-radius: 50%;
    @extend %flex-center;
    border: 1px solid $White;

    @media screen and (max-width: 991px) {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: $A2-300;
      transition: 0.3s ease;
      border-color: $A2-300;
    }
  }

  &_minus {
    font-size: 33px;

    span {
      height: 45px;

      @include responsive-max(991px) {
        height: 31px;
      }
    }

    @include responsive-max(991px) {
      font-size: 23px;
    }
  }

  &_plus {
    font-size: 32px;

    @include responsive-max(991px) {
      font-size: 22px;
    }
  }

  &_input-blok {
    width: 58%;

    input {
      width: 100%;
      border: none;
      outline: none;
      color: $White;
      font-size: 20px;
      font-weight: 400;
      background: none;
      text-align: center;
      caret-color: transparent;

      @include responsive-max(991px) {
        font-size: 14px;
        font-weight: 300;
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
}