@import "src/scss/color2";
@import "src/scss/fontSize";

.kompete-pagination {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;

  .prev, .next {
    svg {
      @media screen and (max-width: 991px) {
        width: 6px;
        height: 12px;
      }
    }
  }

  li {
    user-select: none; /* standard syntax */
    -ms-user-select: none;
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */

    width: 40px;
    height: 40px;
    display: flex;
    margin: 0 12px;
    cursor: pointer;
    text-align: center;
    @extend .p-02-light;
    align-items: center;
    border: 1px solid $G-2;
    justify-content: center;

    span {
      display: block;
    }

    @media screen and (max-width: 991px) {
      width: 32px;
      height: 32px;
      margin: 0 5px;
      font-size: 12px;
    }

    @media screen and (max-width: 400px) {
      width: 25px;
      height: 25px;
      font-size: 10px;
    }

    &.active {
      background-color: $G-4;
    }

    &:hover:not(.active) {
      background-color: $G-4;
    }
  }

  li:first-child {
    margin-left: 0;
  }

  li:last-child {
    margin-right: 0;
  }
}