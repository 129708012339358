@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

@mixin close-button($position) {
  border: none;
  color: $G-5-O;
  top: $position;
  cursor: pointer;
  background: none;
  right: $position;
  position: absolute;
}

@mixin modal-size($size) {
  max-width: $size;

  &.modal-close {
    padding: 40px 32px 32px;

    @include responsive-max(991px) {
      padding: 40px 16px 32px;

      & > button {
        @include close-button(10px);

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    & > button {
      @include close-button(15px);

      @include responsive-max(991px) {
        top: 10px;
        right: 10px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &.modal-without-close {
    padding: 32px;

    @include responsive-max(991px) {
      padding: 32px 16px;
    }
  }

  & > h2 {
    @extend .h-02-e-bold;
  }
}

.modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  position: fixed;
  @extend %flex-center;
  background-color: $Dark-Violet-O;
}

.modal {

  &-content {
    width: 100%;
    margin: 0 25px;
    position: relative;
    background-color: $Black;
    border: 1px solid var(--Grays-G-1, #141414);
    background: var(--tanf, radial-gradient(160.94% 168.2% at 100% -22.38%, rgba(0, 0, 0, 0.75) 11.58%, rgba(11, 11, 11, 0.75) 22.91%, rgba(32, 31, 31, 0.75) 39.26%, rgba(0, 0, 0, 0.75) 70.36%), #000);

    & > h2 {
      text-align: center;
      text-transform: uppercase;
    }
  }

  &-large {
    @include modal-size(625px);

    @include responsive-max(991px){
      max-width: 500px;
    }
  }

  &-medium {
    @include modal-size(560px)
  }
}