@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.blocked {
  width: 491px;
  margin: auto;
  background: $G-1;
  text-align: center;
  padding: 60px 50px;
  border: 1px solid $G-1-O;

  @include responsive-max(991px) {
    width: 330px;
  }

  img {
    width: 145px;
    height: 145px;

    @include responsive-max(991px) {
      width: 80px;
      height: 80px;
    }
  }

  p {
    margin-top: 20px;
    @extend .p-01-regular;
  }
}