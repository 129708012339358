@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.network-button {
  color: $White;
  background: $G-1;
  @extend %flex-center;
  clip-path: polygon(10% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 10%);

  &:hover {
    background:  $G-1-O
  }

  &.lg {
    width: 72px;
    height: 72px;
    margin: 0 16px;

    @include responsive-max(991px) {
      width: 32px;
      height: 32px;
      margin: 0 8px;
      clip-path: polygon(20% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 20%);

      svg {
        width: 16px;
        height: 16px;
      }
    }

    span {
      background-size: 40px;

      @include responsive-max(991px) {
        background-size: 16px;
      }
    }
  }

  &.sm {
    width: 32px;
    height: 32px;
    margin: 0 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
