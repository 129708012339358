@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.grid-container {
  gap: 15px;
  display: grid;
  padding: 30px 0;
  @include grid-display(4);

  @include responsive-max(1440px) {
    @include grid-display(4);
  }

  @include responsive-max(1200px) {
    @include grid-display(3);
  }

  @include responsive-max(991px) {
    @include grid-display(4);
  }

  @include responsive-max(768px) {
    @include grid-display(3);
  }

  @include responsive-max(550px) {
    @include grid-display(2);
  }
}

.never-sold-again-card {
  gap: 5px;
  width: 130px;
  height: 24px;
  padding: 0 12px;
  @extend %flex-align;
  background: #101010;
  border: 0.5px solid #A8A8A8;

  @include responsive-max(991px) {
    gap: 2px;
    width: 105px;
    height: 16px;
  }

  span {
    color: $White;
    line-height: 12px;
    @extend .p-04-bold;
    text-transform: uppercase;

    @include responsive-max(991px){
      font-size: 6px;
      font-weight: 600;
      letter-spacing: 0.30px;
      text-transform: uppercase;
    }
  }

  svg {
    width: 13px;
    height: 13px;

    @include responsive-max(991px) {
      width: 9px;
      height: 9px;
    }
  }
}