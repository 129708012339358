@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.countdown-container {
  width: 100%;
  height: 110px;
  padding: 8px 0 10px;
  align-items: center;
  background: $A2-75-O;
  @extend %flex-column-center;
  border: 0.5px solid $A2-300;

  @include responsive-max(991px) {
    height: 90px;
  }

  &_text {
    margin: 0;
    color: $A2-300;
    text-align: center;
    @extend .p-01-regular;
  }

  &_time {
    height: 59px;
    min-width: 227.5px;

    @include responsive-max(991px) {
      width: 163px;
      height: 42px;
    }
  }

  h2 {
    text-align: center;
    @extend .h-02-e-bold;
    text-transform: uppercase;
    margin-top: 10px !important;
  }
}
