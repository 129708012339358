@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";

$linear-gradient-bg: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.57) 50%, rgba(0, 0, 0, 0) 100%);

@mixin background-styles($image-url) {
  background: #{$linear-gradient-bg}, url("#{$image-url}");
  background-size: cover;
  background-position-y: bottom;
}

.footer {
  &.background {
    @include background-styles("../../../assets/png/footer-background-max.png");

    @include responsive-max(1440px) {
      @include background-styles("../../../assets/png/footer-background.png");
    }

    @include responsive-max(480px) {
      @include background-styles("../../../assets/png/footer-background.png");
      background-position: center center;
    }
  }
}