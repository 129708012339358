@import "src/scss/color2";
@import "src/scss/fontSize";

.share {
  cursor: pointer;
  position: relative;
  width: 60px;

  &_text {
    @extend .p-02-light;
    transition: 0.3s ease;
  
    &:hover {
      color: $A2-300;
    }

    &::before {
      content: url("../../../assets/svg/shareWhite.svg");
      position: relative;
      width: 13px;
      height: 13px;
      top: 4px;
      right: 5px;

      @media screen and (max-width: 991px){
        width: 10px;
        height: 10px;
      }
    }
  }

  &_networks {
    width: 215px;
    position: absolute;
    border: 1px solid #252525;
    padding: 10px 25px 10px 10px;
    background-color: $Black;
    color: $White !important;
    z-index: 15;

    @media screen and (max-height: 991px){
      width: 178px;
    }

    &.bottom{
      right: 0;
      top: 30px;
    }

    &.top{
      right: 0;
      bottom: 30px;
    }

    div {
      margin: 10px 0;
      height: 100% !important;

      @media screen and (max-height: 991px){
        font-size: 12px;
      }

      img {
        width: 16px;
        cursor: pointer;
        margin-right: 2px;
      }

      &:hover {
        color: $A2-300;
        transition: 0.3s ease;
      }

      img {
        margin-right: 7px;
      }
    }

    button{
      margin: auto !important;
    }
  }
}