@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.home {
  &_main {
    width: 100%;
    margin-top: 80px;
    position: relative;

    @include responsive-max(991px) {
      margin-top: 60px;
    }

    &_back-img {
      z-index: 1;
      width: 100%;
      object-fit: cover;
      background: linear-gradient(180deg, #000 1.22%, rgba(0, 0, 0, 0.00) 15%, #000 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url("../../../assets/png/kompete_background.png") lightgray 50% / cover no-repeat;

      @include responsive-max(1650px) {
        max-height: 850px;
      }

      @include responsive-max(1440px) {
        height: 810px;
      }

      @include responsive-max(991px) {
        height: 700px;
      }

      @include responsive-max(768px) {
        height: 280px;
      }
    }

    &_download {
      gap: 70px;
      z-index: 15;
      display: flex;

      @include responsive-min(991px) {
        left: 50%;
        bottom: 100px;
        position: absolute;

        transform: translate(-50%, 0);
      }

      @include responsive-max(991px) {
        padding: 40px 0;
        justify-content: center;
      }

      @include responsive-max(768px) {
        gap: 12px;
        align-items: center;
        flex-direction: column;
      }

      &_for-free, &_coming-soon {
        & > span {
          width: 100%;
          height: 38px;
          display: block;
          color: $A2-350;
          padding: 0 10px;
          font-size: 22px;
          font-weight: 600;
          font-style: normal;
          line-height: 38.4px;
          margin-bottom: 10px;
          background-color: $G-0-0;
          text-transform: uppercase;
          text-shadow: 0 0 10px #000;
          border-bottom: 1px solid $White;

          @include responsive-max(991px) {
            font-size: 16px;
            margin-bottom: 5px;
            line-height: 36.4px;
          }
        }

        .device-card {
          margin: 0 6px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &_for-free {
        min-height: 160px;

        @include responsive-max(991px) {
          min-height: 113px;
        }

        @include responsive-max(400px) {
          max-width: 327px;
          margin: 0 24px;
        }

        &_links {
          display: flex;

          @include responsive-max(400px) {
            flex-wrap: wrap;
            gap: 15px;

            .device-card {
              margin: 0;

            }
          }

          @include responsive-min(400px) {
            justify-content: space-between;
          }

          .download_device-cart {
            width: 108px;
          }
        }
      }

      &_coming-soon {
        min-height: 160px;

        @include responsive-max(991px) {
          min-height: 115px;
        }

        &_links {
          display: flex;
          justify-content: space-between;

          .download_device-cart {
            width: 108px;
          }
        }
      }
    }
  }

  &_lets-kompete {
    @extend .container-padding;

    &_content {
      @extend .flex-between;
      @extend .content-margin-top;

      @include responsive-max(991px) {
        text-align: center;
      }
    }
  }

  &_current-game-mode {
    background: url("../../../assets/png/game-modes-background.png") no-repeat center center;
    background-size: cover;
    padding: 84px 0;

    @include responsive-max(991px) {
      padding: 40px 0;
    }

    .container-title {
      h3 {
        margin: auto;
        max-width: 790px;

        @include responsive-max(991px) {
          max-width: 550px;
        }

        @include responsive-max(480px) {
          max-width: 360px;
        }
      }

      & > p {
        padding: 0 20px;
        max-width: 800px;
      }
    }

    &_content {
      @extend .content-margin-top;

      & > div:not(:last-child) {
        margin-bottom: 80px;

        @include responsive-max(991px) {
          margin-bottom: 30px;
        }
      }

      &_one {
        @extend .flex-between;
      }

      &_two {
        @extend .flex-between;
      }

      &_three {
        @extend .flex-between;

        .second-block {
          @include responsive-max(991px) {
            margin-bottom: 0 !important;
          }
        }

        .first-block-content {
          @include responsive-max(991px) {
            margin-top: 25px !important;
          }
        }

        img {
          width: 100% !important;
          height: 100% !important;
        }
      }

      &_four {
        @extend .flex-between;

        .second-block {
          @include responsive-max(991px) {
            margin-bottom: 0 !important;
          }
        }

        img {
          width: 100% !important;
          height: 100% !important;
        }

        .first-block-content {
          @include responsive-max(991px) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  &_coming-soon {
    width: 100%;
    height: 515px;
    text-align: center;
    background: url("../../../assets/png/coming-soon-background-2.png") no-repeat center center #170C0C;
    background-size: cover;
    @extend %flex-center;

    @include responsive-max(991px) {
      height: 140px;
    }

    h1 {
      @extend .h-01-e-bold;
      text-transform: uppercase;

      span {
        color: $A2-300;
      }
    }
  }

  &_welcome {
    @extend .container-padding;

    &_content {
      @extend .content-margin-top;

      @include responsive-max(991px) {
        img {
          margin-bottom: 25px !important;
        }
        div {
          margin-bottom: 0 !important;
        }
      }

      &_main-img {
        max-width: 1280px;
        position: relative;
        margin: 0 auto 115px;

        @include responsive-max(991px) {
          padding: 0;
          margin-bottom: 0;
        }
      }

      &_island-img {
        margin: auto;
        padding: 0 20px;
        max-width: 1280px;
        @extend %flex-column;

        @include responsive-max(991px) {
          padding: 0;

          img {
            height: 65vw;
          }
        }


        & > div {
          @extend %flex-justify-between;

          @include responsive-max(991px) {
            flex-direction: column;
          }

          img {
            width: 49%;

            @include responsive-max(991px) {
              width: 100%;
            }
          }
        }

        & > div:first-child {
          margin-bottom: 115px;
        }

        & > div:last-child {
          margin-bottom: 115px;
        }
      }

      &_info {
        padding: 0 60px;
        @extend .flex-between;
        max-width: 1280px !important;

        @include responsive-max(991px) {
          flex-direction: column-reverse !important;
          padding: 0 24px;
        }

        .first-block-content {
          @include responsive-min(1200px) {
            max-width: 548px !important;
          }

          @include responsive-max(991px) {
            h2 {
              display: none;
            }
          }

          p {
            @include responsive-max(991px) {
              font-weight: 300;
            }
          }
        }

        & > div:last-child {
          width: 47%;

          @include responsive-max(991px) {
            width: 100%;
          }

          h2 {
            @extend .h-02-e-bold;
            text-transform: uppercase;
            margin-bottom: 15px;
            display: none;

            @include responsive-max(991px) {
              display: block;
              text-align: center;

              span {
                color: $A2-300;
              }
            }
          }
        }
      }
    }
  }

  &_why-kompete {
    @extend .container-padding;

    &_content {
      @extend .content-margin-top;
      @extend %flex-justify-center;

      &_why-list {
        display: grid;
        justify-content: space-between;

        @include grid-display(4);
        gap: 25px;

        @include responsive-max(1200px) {
          @include grid-display(3);
          gap: 50px;
        }

        @include responsive-max(991px) {
          gap: 15px;
        }

        @include responsive-max(768px) {
          gap: 15px;
          @include grid-display(2);
        }

        & > div {
          background-size: contain !important;
        }

        .one {
          background: url("../../../../public/images/highresScreenshot-1.png") no-repeat center center;
        }

        .two {
          background: url("../../../../public/images/highresScreenshot-2.png") no-repeat center center;
        }

        .three {
          background: url("../../../../public/images/highresScreenshot-3.png") no-repeat center center;
        }

        .four {
          background: url("../../../../public/images/highresScreenshot-4.png") no-repeat center center;
        }

        .five {
          background: url("../../../../public/images/highresScreenshot-5.png") no-repeat center center;
        }

        .six {
          background: url("../../../../public/images/highresScreenshot-6.png") no-repeat center center;
        }

        .seven {
          background: url("../../../../public/images/highresScreenshot-7.png") no-repeat center center;
        }

        .eight {
          background: url("../../../../public/images/highresScreenshot-8.png") no-repeat center center;
        }

        .nine {
          background: url("../../../../public/images/highresScreenshot-9.png") no-repeat center center;
        }

        .ten {
          background: url("../../../../public/images/highresScreenshot-10.png") no-repeat center center;
        }

        .eleven {
          background: url("../../../../public/images/highresScreenshot-11.png") no-repeat center center;
        }

        .twelve {
          background: url("../../../../public/images/highresScreenshot-12.png") no-repeat center center;
        }

        &_item {
          width: 260px;
          margin: auto;
          height: 260px;
          display: flex;
          justify-content: end;
          padding-bottom: 15px;
          flex-direction: column;

          @include responsive-max(991px) {
            width: 155px;
            height: 155px;
            padding-bottom: 10px;
          }

          &_block {
            min-height: 55px;

            @include responsive-max(991px) {
              min-height: 30px;
            }

            h5 {
              padding: 0 15px;
              text-align: center;
              @extend .h-05-e-bold;
              text-transform: uppercase;

              @include responsive-max(991px) {
                font-size: 10px;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }

  &_unreal-engine {
    position: relative;
    @extend .container-padding;
    @include background-overlay-cover-center("../../../assets/png/unreal-engine-background-max.png");

    @include responsive-max(1440px) {
      @include background-cover-center("../../../assets/png/unreal-engine-background.png")
    }

    @include responsive-max(991px) {
      h3 {
        max-width: 260px;
        margin: auto;
      }
    }

    @include responsive-max(480px) {
      background-position: center -35px !important;
    }

    &_content {
      @extend .content-margin-top;

      &_engine-img {
        width: 365px;
        height: 395px;
        margin: 0 auto 150px;

        @include responsive-max(991px) {
          width: 185px;
          height: 200px;
          margin-bottom: 70px;
        }
      }

      &_info {
        margin-bottom: 70px;
        @extend %flex-justify-between;

        @include responsive-max(991px) {
          margin-bottom: 20px;
          justify-content: center;
          flex-direction: column-reverse;
        }

        &_left {
          gap: 20px;
          width: 42%;

          @include responsive-max(1200px) {
            width: 50%;
          }

          @include responsive-max(991px) {
            width: 100%;
          }

          h2 {
            @extend .h-02-e-bold;
            text-transform: uppercase;

            @include responsive-max(991px) {
              display: none;
            }

            span {
              color: $A2-300;
            }
          }

          p {
            @extend .p-01-regular;
            margin-top: 15px;
          }
        }

        &_right {
          @include responsive-min(991px) {
            display: flex;
            align-items: center;
          }

          h2 {
            display: none;
            margin-bottom: 20px;
            @extend .h-02-e-bold;
            text-transform: uppercase;

            @include responsive-max(991px) {
              display: block;
            }

            span {
              color: $A2-300;
            }
          }

          &_img {
            @extend %flex-align;

            @include responsive-max(991px) {
              height: 148px;
              justify-content: center;
              border: 1px solid $G-1-O;
            }

            img {
              width: 346px;
              height: 100px;

              @include responsive-max(991px) {
                width: 220px;
                height: 63px;
              }
            }
          }
        }
      }
    }
  }

  &_news {
    @extend .container-padding;

    &_content {
      gap: 20px;
      display: grid;
      @extend .content-margin-top;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

      @include responsive-max(1200px) {
        @include grid-display(3)
      }
      @include responsive-max(991px) {
        @include grid-display(2);
        margin: 30px 0;
      }
    }

    &_btn {
      margin-top: 30px;

      @include responsive-max(991px) {
        margin-top: 15px;
      }

      button {
        width: 200px;
        margin: auto;
      }
    }
  }

  &_tech-specs {
    @extend .container-padding;

    &_content {
      @extend .content-margin-top;

      &_dropdown {
        @extend %flex-justify-between;

        @include responsive-max(768px) {
          flex-direction: column;
        }

        ul {
          margin: 0;
          padding: 0;
          width: 45%;
          list-style-type: none;

          @include responsive-max(768px) {
            width: 100%;
          }

          label {
            color: $A2-500;
            @extend .n-link-u;
            text-transform: uppercase;
          }

          p {
            @extend .p-01-regular;
          }

          @include responsive-max(768px) {
            li:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .specs-wrap {
      max-width: 1050px;
      border-top: 1px solid $G-4-O;

      &_content {
        @extend %flex-justify-between;

        @include responsive-max(600px) {
          flex-direction: column;
        }

        ul {
          margin: 0;
          padding: 0;
          width: 50%;
          list-style-type: none;

          @include responsive-max(600px) {
            width: 100%;
          }

          label {
            color: $P300;
            font-size: 13px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.24em;
            text-transform: uppercase;

            @include responsive-max(991px) {
              font-size: 10px;
              letter-spacing: 0.04em;
            }
          }

          p {
            @extend .p-01-regular;
          }
        }
      }
    }
  }
}

// Style utils for sections
.container-padding {
  padding: 85px 0;

  @include responsive-max(991px) {
    padding: 20px 0;
  }
}

.flex-between {
  margin: 0 auto;
  max-width: 1235px;
  @extend %flex-justify-between-center;

  @include responsive-max(991px) {
    flex-direction: column;
  }

  .first-block-content {
    gap: 25px;
    display: grid;
    max-width: 470px;
    position: relative;

    @include responsive-max(991px) {
      max-width: 100%;
      gap: 15px;
    }

    h2 {
      @extend .h-02-e-bold;
      text-transform: uppercase;

      @include responsive-max(991px) {
        display: none;
      }

      span {
        color: $A2-300;
      }
    }

    p {
      @extend .p-01-regular;
    }
  }

  .second-block {
    position: relative;
    max-width: calc(50% + 100px);

    & > h2 {
      display: none;
      text-align: left;
      @extend .h-02-e-bold;
      text-transform: uppercase;

      @include responsive-max(991px) {
        display: block;
        margin-bottom: 24px;
      }
    }

    @include responsive-max(991px) {
      order: -1;
    }

    @include responsive-max(1200px) {
      text-align: center;
    }

    @include responsive-max(991px) {
      max-width: 100%;
      margin-bottom: 25px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &.img_right {
      width: 100%;
      margin-right: -120px;

      h2 {
        span {
          color: $A2-300;
        }
      }

      @include responsive-max(1200px) {
        margin-right: 0;
        margin-left: 20px;
      }

      @include responsive-max(991px) {
        margin-left: 0;
      }
    }

    &.img_left {
      width: 100%;
      margin-left: -30px;
      margin-right: 80px;

      @include responsive-max(1200px) {
        margin-left: 0;
        margin-right: 20px;
      }

      @include responsive-max(991px) {
        margin-right: 0;
      }
    }

    .video-block {
      width: 615px;
      margin: auto;
      height: 346px;
      position: relative;

      iframe {
        border: 1px solid $A2-300;
      }

      @include responsive-max(1200px) {
        width: 445px;
        height: 265px;
      }

      @include responsive-max(991px) {
        width: 685px;
        height: 400px;
      }

      @include responsive-max(768px) {
        width: inherit;
        height: 40vw;
      }

      @include responsive-max(480px) {
        height: 50vw;
      }

      &_video {
        width: 100%;
        height: inherit;
      }
    }
  }

  .video-block {
    width: 710px;
    margin: auto;
    height: 395px;
    position: relative;

    @include responsive-max(1200px) {
      width: 445px;
      height: 265px;
    }

    @include responsive-max(991px) {
      width: 685px;
      height: 400px;
    }

    @include responsive-max(768px) {
      width: inherit;
      height: 40vw;
    }

    @include responsive-max(480px) {
      height: 50vw;
    }

    &_video {
      width: 100%;
      height: inherit;
    }

    .gameplay-container {
      position: absolute;
      filter: drop-shadow(0px 4px 5.5px #000);

      &.right {
        top: -1px;
        left: -22px;

        @include responsive-max(480px) {
          left: -9px;
        }
      }

      &.left {
        top: -1px;
        right: -22px;

        @include responsive-max(480px) {
          left: -9px;
        }
      }

      .gameplay {
        height: 40px;
        color: $White;
        padding: 0 15px;
        min-width: 160px;
        @extend .n-link-u;
        @extend %flex-center;
        background-color: $G-3;
        text-transform: uppercase;

        @include responsive-max(480px) {
          height: 20px;
          min-width: 82px;
          font-size: 6.5px;
        }

        &.right {
          clip-path: polygon(92% 1%, 100% 34%, 100% 100%, 0 100%, 0 0);
        }

        &.left {
          clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%, 0 40%)
        }
      }
    }

    .gameplay_vector {
      position: absolute;
      background: $Black;
      height: 10px;
      width: 21px;

      @include responsive-max(480px) {
        max-width: 10px;
        height: 5px;
      }

      &.right {
        left: -21px;
        top: 39px;
        clip-path: polygon(100% 96%, 100% 0, 0 0);

        @include responsive-max(480px) {
          top: 18.8px;
          left: -10px;
        }
      }

      &.left {
        top: 39px;
        right: -21px;
        clip-path: polygon(0 100%, 100% 0, 0 0);

        @include responsive-max(480px) {
          top: 18.8px;
          left: -9px;
        }
      }
    }
  }
}

.content-margin-top {
  margin-top: 60px;

  @include responsive-max(991px) {
    margin-top: 30px;
  }
}

.img-filter {
  filter: contrast(0.01);
}

.device-card {
  width: 108px;
  padding: 1px;
  height: 108px;
  cursor: pointer;
  background: $White;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);

  @include responsive-max(991px) {
    width: 70px;
    height: 70px;
  }

  &:hover {
    background: $A2-300;
  }

  a {
    width: 106px;
    height: 106px;
    display: block;
    background: $G-1;
    transition: .3s ease-in-out;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);

    @include responsive-max(991px) {
      width: 68px;
      height: 68px;
    }
  }

  &_icon-block {
    height: 90px;
    width: inherit;
    @extend %flex-center;

    @include responsive-max(991px) {
      height: 55px;
    }

    & > svg {
      width: 65px;
      height: 65px;

      @include responsive-max(991px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &_device {
    display: block;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    font-style: normal;
    margin-bottom: 5px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.50);

    @include responsive-max(991px) {
      font-size: 6.5px;
      line-height: 12px;
      letter-spacing: 0.251px;
    }
  }

  &.android {
    svg {
      color: #AAC148;
    }
  }

  &.modernize {
    svg {
      color: $White;
    }
  }

  &.playstation {
    svg {
      color: #0070D1;
    }
  }

  &.xbox {
    svg {
      color: #107C10;
    }
  }
}