@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";

.recently-viewed-items-section {
  padding: 60px 24px;
  background-color: $G-1-O;

  @include responsive-max(991px) {
    padding: 50px 24px;
  }

  h2 {
    text-align: center;
    @extend .h-02-e-bold;
    text-transform: uppercase;
  }

  &_block {
    gap: 33px;
    display: grid;
    max-width: 1185px;
    margin: 50px auto 0;
    @include grid-display(4);

    @include responsive-max(991px) {
      gap: 15px;
      margin-top: 25px;
    }

    @include responsive-max(768px) {
      max-width: 500px;
      @include grid-display(2);
    }

    @include responsive-max(480px) {
      gap: 15px;
    }
  }
}