@import "src/scss/color2";
@import "src/scss/vars";

.lds-default {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
}

.lds-default div {
  width: 6px;
  height: 6px;
  background: $G-1-O;
  border-radius: 50%;
  position: absolute;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  top: 37px;
  left: 66px;
  animation-delay: 0s;
}

.lds-default div:nth-child(2) {
  top: 22px;
  left: 62px;
  animation-delay: -0.1s;
}

.lds-default div:nth-child(3) {
  top: 11px;
  left: 52px;
  animation-delay: -0.2s;
}

.lds-default div:nth-child(4) {
  top: 7px;
  left: 37px;
  animation-delay: -0.3s;
}

.lds-default div:nth-child(5) {
  top: 11px;
  left: 22px;
  animation-delay: -0.4s;
}

.lds-default div:nth-child(6) {
  top: 22px;
  left: 11px;
  animation-delay: -0.5s;
}

.lds-default div:nth-child(7) {
  top: 37px;
  left: 7px;
  animation-delay: -0.6s;
}

.lds-default div:nth-child(8) {
  top: 52px;
  left: 11px;
  animation-delay: -0.7s;
}

.lds-default div:nth-child(9) {
  top: 62px;
  left: 22px;
  animation-delay: -0.8s;
}

.lds-default div:nth-child(10) {
  top: 66px;
  left: 37px;
  animation-delay: -0.9s;
}

.lds-default div:nth-child(11) {
  top: 62px;
  left: 52px;
  animation-delay: -1s;
}

.lds-default div:nth-child(12) {
  top: 52px;
  left: 62px;
  animation-delay: -1.1s;
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.0);
    background: $A2-300;
  }
}