@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.shop-limited {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.8) 100%), url('../../../../assets/png/limited-background.png');
  background-size: contain;
  @extend %flex-center;
  min-height: 700px;

  &.close {
    background: url("../../../../assets/png/limited-shop-close-background.png") no-repeat center center !important;
    background-size: cover !important;
  }

  @include responsive-max(991px) {
    min-height: 400px;
  }

  &_content {
    margin: 50px 0 60px;

    @include responsive-max(991px) {
      margin: 25px 0;
    }

    &_countDown {
      width: 100%;
      min-height: 40px;
      margin: 0 auto 50px;
      @extend %flex-center;

      &.skeleton {
        background-color: rgba(20, 20, 19, 0) !important;
      }

      @include responsive-max(991px) {
        max-width: 470px;
        margin: 0 auto 10px;
      }

      &_block {
        width: 100%;

        h4 {
          flex-wrap: wrap;
          text-align: center;

          @extend .h-04-e-bold;
          text-transform: uppercase;
          @extend %flex-justify-center;

          &_open {
            width: 60%;
            height: 60px;
          }

          &_close {
            width: 100%;
            height: 60px;
          }

          div {
            display: flex;

            span {
              display: block;
            }

            .time {
              min-width: 45px;
              text-align: center;
              padding: 0 5px;

              @include responsive-max(991px) {
                min-width: 25px;
              }
            }
          }
        }
      }
    }

    &_cosmetics {
      @extend %flex-justify-between;

      @include responsive-max(991px) {
        flex-direction: column;
      }

      &_big {
        width: 57%;
        @extend %flex-justify-between;

        @include responsive-max(991px) {
          width: 100%;
          justify-content: space-around;
        }

        @include responsive-max(768px) {
          flex-direction: column;

          & > div {
            margin: 10px auto;
          }
        }
      }

      &_small {
        gap: 25px;
        width: 37%;
        display: grid;
        @include grid-display(2);

        @include responsive-max(991px) {
          width: 100%;
          margin-top: 30px;
          @include grid-display(4);
        }

        @include responsive-max(768px) {
          width: 325px;
          margin: 10px auto 0;
          @include grid-display(2);
        }
      }
    }
  }

  &_loading-container {
    width: 100%;
    min-height: 700px;
    @extend %flex-center;
    background: url("../../../../assets/png/oridnary-opacity-background.png") no-repeat center center;
    background-size: cover;

    @include responsive-max(991px) {
      min-height: 400px;
    }

    &_block {
      text-align: center;

      @include responsive-max(991px) {
        svg {
          width: 54px;
          height: 54px;
        }
      }

      & > p {
        font-size: 27px;
        margin-top: 45px;
        font-weight: 700;
        line-height: 17.5px;
        text-transform: uppercase;

        @include responsive-max(991px) {
          font-size: 10.5px;
          line-height: 7px;
        }
      }
    }
  }

  &_close-container {
    width: 100%;
    padding: 0 25px;
    min-height: 700px;
    background: url("../../../../assets/png/oridnary-opacity-background.png") no-repeat center center;
    background-size: cover;
    @extend %flex-center;

    @include responsive-max(991px) {
      min-height: 400px;
    }

    text-align: center;

    & > p {
      max-width: 1095px;
      @extend .h-01-e-bold;
      text-transform: uppercase;

      @include responsive-max(991px) {
        font-size: 20px;
      }
    }
  }
}