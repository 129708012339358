@import "src/scss/fontSize";
@import "src/scss/color2";
@import "scss/mixin";

.main-page {
  position: relative;
  margin-top: 80px;
  height: 580px;

  @media screen and (min-width: 1440px) {
    height: 700px;
  }

  @include responsive-max(1440px) {
    height: 580px;
  }

  @include responsive-max(991px) {
    height: 500px;
    margin-top: 60px;
  }

  @include responsive-max(480px) {
    height: 300px;
  }

  &_back-img {
    z-index: 1;
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 580px;

    @media screen and (min-width: 1440px) {
      height: 700px;
    }

    @include responsive-max(1400px) {
      height: 580px;
    }

    @include responsive-max(991px) {
      height: 500px;
    }

    @include responsive-max(480px) {
      height: 300px;
    }
  }

  &_title {
    top: 35%;
    left: 11%;
    z-index: 5;
    position: absolute;

    h1 {
      @extend .d-01-e-bold;
      color: $P50;
      text-transform: uppercase;

      span {
        color: $A2-300;
      }
    }

    p {
      @extend .p-01-regular;
      color: $P50;
      max-width: 480px;
    }

    .gold-underline {
      margin: 25px 0;

      @include responsive-max(991px) {
        margin: 15px 0;
      }
    }
  }

  &_filter {

    @media screen and (min-width: 1440px) {
      height: 700px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.17690826330532217) 47%, rgba(0, 0, 0, 0.9920343137254902) 100%);
    }

    z-index: 2;
    width: 100%;
    position: absolute;
  }
}