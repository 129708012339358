@import "src/scss/vars";
@import "src/scss/mixin";
@import "src/scss/color2";
@import "src/scss/fontSize";

.download {
  &_device-cart {
    cursor: pointer;
    padding: 3px 0 5px;
    position: relative;
    background: linear-gradient(180deg, rgba(35, 35, 35, 0.70) 0%, #000 91.62%);

    &.sm {
      width: 120px;

      @include responsive-max(991px) {
        width: 75px;
      }

      .download_device-cart_icon-block {
        width: 50px;
        height: 50px;

        @include responsive-max(991px) {
          width: 32px;
          height: 32px;
        }
      }

      .download_device-cart_text-block {
        height: 28px;

        @include responsive-max(991px) {
          height: 14px;
        }
      }
    }

    &.md {
      width: 120px;

      @include responsive-max(991px) {
        width: 110px;
        height: 110px;
      }

      .download_device-cart_icon-block {
        width: 50px;
        height: 50px;

        @include responsive-max(991px) {
          width: 45px;
          height: 45px;
        }
      }

      .download_device-cart_text-block {
        height: 28px;

        @include responsive-max(991px) {
          height: 20px;

          p {
            width: 50px
          }
        }
      }
    }

    &_no-available {
      right: 5px;
      position: absolute;

      @include responsive-max(991px) {
        top: -5px;
        right: 2px;
      }

      svg {
        width: 13px;
        height: 13px;

        @include responsive-max(991px) {
          width: 8px;
          height: 8px;
        }
      }
    }

    &_text-block {
      @extend %flex-center;

      @include responsive-max(991px) {
        height: 14px;
      }

      p {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        line-height: 13.795px;
        letter-spacing: 0.4px;
        text-transform: uppercase;

        @include responsive-max(991px) {
          font-weight: 600;
          font-size: 6.274px;
          font-style: normal;
          line-height: 8.655px;
          letter-spacing: 0.251px;
        }
      }
    }

    &:hover {
      background: linear-gradient(180deg, rgba(35, 35, 35, 0.40) 0%, #000 91.62%);
    }

    &_device {
      display: block;
      font-size: 10px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      font-style: normal;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.50);

      @include responsive-max(991px) {
        font-size: 6.5px;
        line-height: 12px;
        letter-spacing: 0.251px;
      }
    }

    &_icon-block {
      margin: 5px auto 0;

      svg {
        width: inherit;
        height: inherit;
      }
    }

    &.android {
      svg {
        color: #AAC148;
      }
    }

    &.modernize {
      svg {
        color: $White;
      }
    }

    &.playstation {
      svg {
        color: #0070D1;
      }
    }

    &.xbox {
      svg {
        color: #107C10;
      }
    }

    svg {
      width: 100%;
    }
  }

  &_main {
    min-height: 780px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 65.46%, #000000 100%), url("../../../assets/png/download-background.png");
    background-size: 100% 100%;
    position: relative;
    padding-top: 120px;

    &.banner {
      padding-top: 150px !important;

      @include responsive-max(991px) {
        padding-top: 120px !important;
      }
    }

    @include responsive-max(991px) {
      height: inherit;
      padding: 100px 0 50px;
    }


    &_content {

      &_general {
        z-index: 1;
        height: 100%;
        @extend %flex-column;

        &_title {
          width: 100%;

          h1 {
            max-width: 530px;
            @extend .d-01-e-bold;
            text-transform: uppercase;

            @include responsive-max(991px) {
              margin: auto;
              max-width: 315px;
              text-align: center;
            }

            span {
              color: $A2-300;
            }
          }

          .gold-underline {
            margin: 20px 0 0 5px;

            @include responsive-max(991px) {
              margin: 5px auto;
            }
          }
        }

        &_main {
          min-height: 100px;
          @extend %flex-justify-between;

          @include responsive-max(991px) {
            flex-direction: column-reverse;
          }

          &_links {
            width: 49%;

            @include responsive-max(991px) {
              margin: auto;
              width: inherit;
            }

            & > p {
              color: $P50;
              margin: 10px 0;
              @extend .p-01-regular;

              @include responsive-max(991px) {
                text-align: center;
              }
            }

            &_available {
              display: flex;
              gap: 5px;

              @include responsive-max(991px) {
                justify-content: center;
              }

              @include responsive-max(480px) {
                gap: 10px;
                display: grid;
                @include grid-display(2);
              }
            }

            &_no-available {
              gap: 5px;
              display: flex;

              @include responsive-max(991px) {
                justify-content: center;
              }
            }
          }

          &_video {
            width: 49%;
            height: 315px;
            position: relative;

            @include responsive-max(991px) {
              width: 100%;
            }

            @include responsive-max(768px) {
              width: 100%;
              height: 50vw;
              margin: 10px 0;
            }
          }
        }
      }

      &_pc, &_mobile {
        height: calc(100vh - 275px - 100px);

        h1 {
          color: #FFF;
          font-size: 48px;
          font-weight: 800;
          line-height: 47px;
          font-style: normal;
          text-align: center;
          text-transform: uppercase;
          text-shadow: 0 7px 7px rgba(0, 0, 0, 0.25);

          @include responsive-max(768px) {
            font-size: 40px;
            line-height: 35px;
          }
        }

        h2 {
          color: #FFF;
          font-size: 24px;
          font-weight: 600;
          line-height: 47px;
          font-style: normal;
          text-align: center;
          text-transform: uppercase;
          text-shadow: 0 7px 7px rgba(0, 0, 0, 0.25);

          @include responsive-max(768px) {
            margin: 10px 0;
            padding: 0 40px;
            font-size: 16px;
            line-height: 18px;
          }
        }

        &_video-block {
          width: 60%;
          height: 385px;
          position: relative;
          margin: 10px auto 20px;

          @include responsive-max(991px) {
            width: 100%;
          }

          @include responsive-max(768px) {
            width: 100%;
            height: 50vw;
            margin: 10px 0;
          }
        }

        &_links {
          gap: 15px;
          width: 60%;
          margin: auto;
          display: flex;

          @include responsive-max(768px) {
            gap: 10px;
            width: 100%;
          }

          @include responsive-max(480px) {
            flex-direction: column;
          }

          button {
            margin: auto;
            max-width: 330px;
          }
        }
      }
    }
  }

  &_discord {
    display: flex;
    min-height: 490px;
    background-size: 100% 100%;
    background: url('../../../assets/png/lp-download-discord-background.png') no-repeat;
    background-size: cover;
    position: relative;

    @include responsive-max(480px) {
      background: url('../../../assets/png/lp-download-discord-mobile-background.png') no-repeat;
      background-size: 100% 100%;
    }

    &::before, &::after {
      left: 0;
      right: 0;
      content: "";
      height: 10px;
      position: absolute;
      background: linear-gradient(92deg, #181818 32.13%, #7E7E7E 50.23%, #181818 78.84%);

      @include responsive-max(991px) {
        height: 5px;
      }
    }

    &::after {
      bottom: 0;
    }

    &::before {
      top: 0;
    }

    &_content {
      display: flex;
      position: relative;
      align-items: center;

      @include responsive-max(991px) {
        flex-direction: column;
        padding: 25px 0;
      }

      &_info {
        max-width: 600px;

        @include responsive-max(991px) {
          display: flex;
          flex-direction: column;
        }

        h2 {
          margin-bottom: 25px;
          @extend .h-02-e-bold;
          text-transform: uppercase;

          @include responsive-max(991px) {
            text-align: center;
            font-size: 19px;
          }
        }

        button {
          max-width: 215px;

          @include responsive-max(991px) {
            margin: 20px auto 0;
          }
        }

        &_weapon {
          max-width: 250px;
          transform: scale(2.2);
          margin: auto;

          @include responsive-max(991px) {
            margin-top: -20px;
          }

          @include responsive-min(991px) {
            display: none;
          }
        }
      }

      &_reward {
        top: -110px;
        right: -10px;
        max-width: 688px;
        position: absolute;

        @include responsive-max(991px) {
          display: none;
        }

        &_weapon {
          transform: scale(1.5);
        }
      }
    }
  }
}
