@mixin grid-display($number) {
  grid-template-columns: repeat($number, minmax(0, 1fr));
}

@mixin responsive-max($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content
  }
}

@mixin responsive-min($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content
  }
}

@mixin responsive-max-height($breakpoint) {
  @media screen and (max-height: $breakpoint) {
    @content
  }
}

@mixin responsive-min-height($breakpoint) {
  @media screen and (min-height: $breakpoint) {
    @content
  }
}

@mixin background-image($url) {
  background-image: url($url);
  background-size: cover;
  background-position: 100% 100%;
}

@mixin background-overlay-cover-center($image-url) {
  background: linear-gradient(180deg, #000 1.22%, rgba(0, 0, 0, 0.00) 20%, #000 100%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%),
  url(#{$image-url}) no-repeat center center;
  background-size: cover;
}

@mixin background-cover-center($image-url) {
  background-image: url(#{$image-url});
  background-size: cover;
  background-position: center center;
}



