@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "scss/vars";

.payment-method-block {
  height: 90px;
  padding: 0 15px;
  cursor: pointer;
  background: $G-1-O;
  transition: 0.3s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0);
  @extend %flex-justify-between-center;

  &.insufficient {
    background: $A2-75-O;
    border: 1px solid $A2-300 !important;
  }

  &.checked {
    border: 1px solid $G-6;
  }

  @include responsive-max(991px) {
    height: 80px;
  }

  &:hover {
    border: 1px solid $G-6;
    transition: 0.1s ease-out;
  }

  &_left {
    display: flex;
    align-items: center;

    &_img {
      width: 56px;
      height: 56px;
      text-align: center;
      margin-right: 8px;

      @include responsive-max(991px) {
        width: 48px;
        height: 48px;
      }

      img {
        &.eth {
          width: 40px;
          height: 56px;

          @include responsive-max(991px) {
            width: 28px;
            height: 44px;
          }
        }

        &.voolah &.xsolla {
          width: 56px;
          height: 56px;
        }
      }
    }

    &_currency {
      &_name {
        display: flex;
        align-items: center;

        & > span:first-child {
          color: $P50;
          line-height: 20px;
          @extend .n-link-u;
          margin-right: 15px;
          text-transform: uppercase;
        }
      }

      &_balance {

        &.insufficient {
          p {
            color: $A2-300;
          }
        }

        p {
          margin: 0;
          color: $P300;
          @extend .p-02-light;

          @include responsive-max(991px) {
            font-size: 10px;
            line-height: 12px;
          }

          span {
            text-transform: uppercase;
          }
        }
      }

      &_info {
        span {
          color: $G-3;
          margin-left: 5px;
          @extend .p-02-light;

          @include responsive-max(991px) {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }

  &_right {
    width: 24px;
    height: 24px;

    img {
      width: inherit;
      height: inherit;
    }
  }
}