.skeleton-text {
  width: 100%;
  height: 13px;
  margin-bottom: 14px;

  @media screen and (max-width: 991px){
    height: 8px;
    margin-bottom: 10px;
  }
}

.text-skeleton-block {
  div:last-child {
    width: 50%;
  }
}