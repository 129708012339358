@import "src/scss/fontSize";
@import "src/scss/color2";

.container-title {
  h3 {
    @extend .h-01-e-bold;
    text-align: center;
    text-transform: uppercase;
    padding: 0 15px;

    span {
      color: $A2-300;
    }
  }

  .gold-underline {
    margin: 20px auto 0;

    @media screen and (max-width: 991px) {
      margin-top: 10px;
    }
  }

  &_underTitle{
    @extend .p-01-regular;
    text-align: center;
    margin: 15px auto 0;
    max-width: 940px;
    padding: 0 15px;
    color: $P50;

    @media screen and (max-width: 991px){
      margin-top: 10px;
      max-width: 600px;
    }
  }
}