@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.tab-block {
  &-display {
    font-size: 13px;
    @extend %flex-center;
    border-bottom: 1px solid $G-2;

    & > div {
      width: 210px;
      height: 66px;
      cursor: pointer;
      padding: 15px 25px;
      text-align: center;
      position: relative;
      @extend %flex-center;

      @include responsive-max(450px) {
        padding: 15px 7px
      }

      & > img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        padding-bottom: 2px;

        @include responsive-max(450px) {
          margin-right: 2px;
        }
      }

      & > div {
        min-width: 70px;
      }
    }

    .active {
      font-weight: 800;
      border-bottom: 1px solid $A2-300;
    }
  }

  &-mobile_block {
    width: 100%;
    margin: auto;
    position: relative;

    &_head {
      height: 56px;
      width: inherit;
      cursor: pointer;
      padding: 0 10px;
      border: 1px solid $G-2;
      @extend %flex-justify-between;

      & > div {
        @extend %flex-align;

        & > img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      div:last-child {
        @extend .p-02-light;
        transform: rotate(0);
        transition: 0.2s ease-in-out;
      }

      .icon-rotate {
        transform: rotate(180deg) !important;
      }
    }

    &_navItems {
      left: 0;
      right: 0;
      top: 60px;
      z-index: 120;
      background: $Black;
      position: absolute;
      border: 1px solid $G-2;

      & > div {
        height: 56px;
        cursor: pointer;
        padding: 0 10px;
        @extend %flex-align;
        transition: 0.3s ease-in-out;
        border-bottom: 1px solid $G-2;

        & > img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          border-bottom: 1px solid $A2-300;
        }

        div {
          margin-left: 8px;
          @extend .p-02-light;
        }
      }

      & > .active {
        border-bottom: 1px solid $A2-300;
      }
    }
  }
}

.TabContent {
  width: 100%;
  min-height: 400px;
}

.version-one {
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;

  div {
    padding: 15px 6%;

    @include responsive-max(400px) {
      padding: 15px 4%;
    }
  }

  @include responsive-max(991px) {
    font-size: 14px;
  }

  > div {
    &.active {
      color: $A2-300 !important;
    }
  }
}