@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.economy {
  background: $Black;

  &_modular {
    @include responsive-max(991px) {
      padding-top: 40px;
    }

    .container-title{
      h3{
        margin: auto;
        max-width: 800px;

        @include responsive-max(991px) {
          max-width: 400px;
        }
      }
    }

    &_content {
      margin-bottom: 85px;

      @include responsive-max(991px) {
        margin-bottom: 40px;
      }

      .item-one {
        padding: 0;
        margin: 60px;

        @include responsive-max(991px) {
          margin: 40px 0;
        }

        & > div:first-child {
          height: 440px;
          background: radial-gradient(52.73% 55.2% at 51.99% 94.58%, rgba(160, 160, 160, 0.18) 5%, rgba(255, 255, 255, 0) 97.48%);

          @include responsive-max(480px) {
            height: 235px;
          }
        }

        & > div:last-child {

          h3 {
            @extend .h-02-e-bold;
          }

          p {
            max-width: 420px;

            @include responsive-max(991px) {
              max-width: 100%;
            }
          }
        }
      }

      .item-two {
        margin-bottom: 60px;
        min-height: 350px;

        @include responsive-max(991px) {
          margin-bottom: 25px;

          & > div:first-child {
            margin-bottom: 40px;
          }
        }

        h2 {
          max-width: 520px;
        }

        p {
          max-width: 505px;
        }
      }

      .item-three {
        h2 {
          max-width: 520px;
        }

        @include responsive-max(991px) {
          & > div:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &_main {
    height: 58vw;
    max-height: 865px;
    position: relative;
    @extend %flex-center;
    background: url(../../../assets/png/game-economy-background-three.png) no-repeat center center;
    background-size: cover;

    @include responsive-max(991px) {
      height: 610px;
      max-height: 610px;
      background-position: left top;
    }

    @include responsive-max(480px) {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1769082633) 90%, rgba(0, 0, 0, 0.9920343137) 100%), url("../../../assets/png/game-economy-background-three.png");

      background-size: cover;
    }

    &_block {
      max-width: 1100px;
      min-height: 400px;
      position: relative;
      @extend %flex-justify-between-center;

      @include responsive-min(991px) {
        padding: 0 20px;
      }

      @include responsive-max(991px) {
        display: flex;
        max-width: 300px;
        margin-top: 100px;
        min-height: 485px;
        flex-direction: column;
      }

      &_left-part {
        z-index: 10;

        h1 {
          max-width: 545px;
          @extend .h-01-e-bold;
          text-transform: uppercase;

          @include responsive-max(991px) {
            width: 375px;
            margin: auto;
            font-size: 36px;
            padding: 0 24px;
            line-height: 40px;
            text-align: center;
          }

          span {
            color: $A2-300;
          }
        }

        p {
          margin: auto;
          max-width: 440px;
          @extend .p-01-regular;

          @include responsive-max(991px) {
            font-weight: 300;
            max-width: 320px;
            text-align: center;
          }
        }
      }

      &_right-part {
        @include responsive-min(1200px) {
          min-width: 570px;
        }

        @include responsive-min(991px) {
          min-width: 500px;
        }
      }

      .gold-underline {
        margin: 20px 0;

        @include responsive-max(991px) {
          margin: 10px auto;
        }
      }
    }
  }

  &_collecting {
    h3 {
      @include responsive-max(480px) {
        max-width: 300px;
        margin: auto;
      }
    }

    &_block {
      margin: 60px 0;
      display: flex;
      min-height: 375px;
      align-items: center;

      @include responsive-max(991px) {
        margin: 32px 0;
        min-height: 425px;
      }

      &_item {
        display: flex;
        align-items: center;
        @extend %flex-justify-between-center;

        @media screen and(max-width: 991px) {
          flex-direction: column;
        }

        & > div {
          height: inherit;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

          img {
            height: inherit;
          }
        }

        & > div:first-child {
          text-align: center;
        }

        & > div:last-child {
          @media screen and (max-width: 991px) {
            margin-top: 25px;
          }

          h3 {
            margin: 0 0 25px;
            @extend .h-03-e-bold;
            text-transform: uppercase;

            @include responsive-max(991px) {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
          }

          p {
            @extend .p-01-regular;
          }
        }

        &_img {
          &.red {
            @include responsive-max(991px) {
              background: radial-gradient(76.93% 82.44% at 68.75% 96.14%, rgba(255, 47, 55, 0.18) 0%, rgba(255, 47, 55, 0) 45.48%), radial-gradient(47.68% 45.1% at 44.1% 4.92%, rgba(255, 47, 55, 0.4) 0%, rgba(255, 47, 55, 0) 100%);
            }
          }

          &.gray {
            background: radial-gradient(53.73% 52.2% at 51.99% 94.58%, rgba(160, 160, 160, 0.18) 0%, rgba(255, 255, 255, 0.00) 97.48%);
          }
        }

        &.item-three, &.item-two {
          & > div:last-child {
            p {
              max-width: 420px;

              @include responsive-max(991px) {
                max-width: 100%;
              }
            }
          }
        }

        &.item-four {
          & > div:first-child {
            padding: 15px 0;

            img {
              @include responsive-max(991px) {
                width: 185px;
                height: 185px;
              }
            }
          }

          & > div:last-child {
            p {
              max-width: 450px;

              @include responsive-max(991px) {
                max-width: 100%;
              }
            }
          }
        }

        &.reverse {
          @include responsive-min(991px) {
            flex-direction: row-reverse !important;
          }
        }

        & > div:last-child {
          p {
            max-width: 445px;

            @include responsive-max(991px) {
              max-width: 100%;
            }
          }
        }
      }

      &.red {
        @include responsive-min(991px) {
          height: 375px;
          background-size: cover;
          background: url("../../../assets/png/red-gradient-back.png") no-repeat center;
        }
      }
    }
  }

  &_you-pay {
    padding: 85px 0;
    background-size: cover;
    background: url("../../../assets/png/economy-back.png") no-repeat;

    @include responsive-max(991px) {
      padding: 40px 30px;
    }

    .container-title {
      h3 {
        margin: auto;
        max-width: 710px;

        @include responsive-max(991px) {
          max-width: 315px;
        }
      }
    }

    &_options {
      margin-top: 80px;
      padding: 0 80px !important;
      @extend %flex-justify-between;

      @include responsive-max(991px) {
        margin-top: 30px;
        padding: 0 !important;
        flex-direction: column;
        justify-content: center;
      }

      & > div:first-child {
        @include responsive-max(991px) {
          margin-bottom: 40px;
        }
      }

      &_item {
        max-width: 375px;

        @include responsive-max(991px) {
          margin: auto;
        }

        &_img-block {
          width: 218px;
          margin: auto;
          height: 218px;

          @include responsive-max(480px) {
            width: 210px;
            height: 210px;
          }

          img {
            width: inherit;
            height: inherit;
          }
        }

        &_content {
          margin: 40px auto;
          text-align: center;

          @include responsive-max(991px) {
            margin: 20px 0 15px;
          }

          h2 {
            margin-bottom: 15px;
            @extend .h-02-e-bold;
            text-transform: uppercase;
          }

          p {
            @extend .p-01-regular;
          }
        }

        button {
          width: 240px;
          margin: auto;

          @include responsive-max(480px) {
            width: 220px;
          }
        }
      }
    }
  }

  &_buy-sell {
    padding: 85px 0;

    @include responsive-max(991px) {
      padding: 40px;
    }

    .container-title {
      h3 {
        margin: auto;
        max-width: 885px;

        @include responsive-max(991px) {
          max-width: 350px;
        }
      }
    }

    &_content {
      margin-top: 40px;
      @extend %flex-justify-between-center;

      h3 {
        max-width: 475px;
        text-transform: uppercase;
      }

      & > div {
        height: auto;
        max-width: 510px;

        @include responsive-max(991px) {
          max-width: 350px;
        }
      }

      @include responsive-max(991px) {
        margin-top: 30px;
        flex-direction: column;
      }

      &_img-block {

        img {
          width: inherit;
          height: inherit;
        }
      }

      &_info {
        @include responsive-max(991px) {
          text-align: center;
        }

        h3 {
          @extend .h-03-e-bold;

          @include responsive-max(991px) {
            font-size: 16px;
            max-width: 275px;
            line-height: 20px;
            margin: 50px auto 0;
            text-transform: uppercase;
          }
        }

        p {
          max-width: 425px;
          margin: 25px 0 45px;
          @extend .p-01-regular;

          @include responsive-max(991px) {
            max-width: 265px;
            margin: 5px 0 20px;
          }
        }

        button {
          width: 240px;

          @include responsive-max(991px) {
            margin: auto;
          }
        }
      }
    }
  }

  &_be-sure {
    padding-top: 85px;
    background: radial-gradient(65.69% 65.69% at 55.63% 100%, rgba(255, 47, 55, 0.25) 0%, rgba(255, 47, 55, 0) 100%);

    @include responsive-max(991px) {
      padding-top: 40px;
    }

    &_content {
      width: 100%;
      height: 485px;
      @extend %flex-align;
      background-size: contain;

      @include responsive-max(991px) {
        align-items: flex-end;
        background-size: contain !important;
        background: url("../../../assets/png/hero-eco-mobile.webp") no-repeat center;
      }

      & > div {
        display: flex;
        margin-top: 40px;
        justify-content: space-around;

        @include responsive-max(991px) {
          margin: 0 auto 40px;
          justify-content: center;
        }

        & > div {
          max-width: 510px;
        }

        .economy_be-sure_content {
          &_info {
            padding-top: 40px;

            h3 {
              @extend .h-03-e-bold;

              @include responsive-max(991px) {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                text-transform: uppercase;
              }
            }

            p {
              max-width: 420px;
              margin: 25px 0 45px;
              @extend .p-01-regular;

              @include responsive-max(991px) {
                text-align: center;
                margin: 5px 0 20px;
              }
            }

            button {
              width: 240px;

              @include responsive-max(991px) {
                margin: auto;
              }
            }
          }

          &_img-block {
            height: 445px;

            @include responsive-max(991px) {
              display: none;
            }

            img {
              width: inherit;
              height: inherit;
            }
          }
        }
      }
    }

    .container-title {
      h1 {
        max-width: 735px;
        margin: auto;

        @include responsive-max(991px) {
          max-width: 520px;
        }
      }
    }
  }
}

.block-margin {
  margin-top: 65px;

  @include responsive-max(991px) {
    margin-top: 20px;
  }
}

.item-block {
  @extend %flex-justify-between-center;

  @media screen and(max-width: 991px) {
    flex-direction: column;
  }

  & > div {
    width: 45%;
    height: inherit;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    img {
      height: inherit;
    }
  }

  & > div:first-child {
    text-align: center;
  }

  & > div:last-child {
    @media screen and (max-width: 991px) {
      margin-top: 25px;
    }

    h3 {
      margin: 0 0 25px;
      @extend .h-03-e-bold;
      text-transform: uppercase;

      @include responsive-max(991px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
    }

    p {
      @extend .p-01-regular;
    }
  }

  .red-back {
    @include responsive-min(991px) {
      height: 375px;
      background-size: cover;
      background: url("../../../assets/png/red-gradient-back.png") no-repeat center;
    }
  }

  &.reverse {
    @include responsive-min(991px) {
      flex-direction: row-reverse !important;
    }
  }
}