@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.success-modal {
  gap: 24px;
  @extend %flex-column-justify-center;

  &_image-block {
    @extend %flex-center;
  }

  & > h3 {
    @extend .h-03-e-bold;

    @include responsive-max(991px) {
      font-size: 24px;
    }
  }

  & > p {
    color: $G-5-O;
    @extend .p-02-light;

    @include responsive-max(991px) {
      font-size: 12px;
    }
  }

  & > p, h3 {
    text-align: center;
  }
}