@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";

.table-container {

  &_main {
    display: block;
    padding: 0 8px;
    overflow-x: auto;
    margin-bottom: 35px;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 8px;
      background: $G-1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.005);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $G-2;
      height: 100px
    }

    &_no-body {
      text-align: center;
      background: black;

      img {
        @include responsive-max(991px) {
          width: 100px;
          height: 100px;
        }
      }

      p {
        color: $G-3;
        margin-top: 40px;
        @extend .p-01-regular;

        @include responsive-max(991px) {
          margin-top: 20px;
        }
      }
    }

    &_main_skeleton {
      tr {
        height: 56px;

        td {
          min-height: 45px;
          padding: 0 !important;

          div {
            border: 1px;
            height: 35px !important;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border: none;
    max-width: 100%;
    min-width: 900px;
    border-collapse: collapse;

    &.details {
      min-width: 900px;
      min-height: 100%;

      &.no-data {
        min-width: 100% !important;
        min-height: 525px;

        @include responsive-max(991px) {
          min-height: 345px !important;
        }
      }
    }

    th {
      height: 64px;
      color: $A2-300;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;

      @include responsive-max(425px) {
        font-size: 8px;
      }
    }

    tbody {

      tr {
        height: 56px;

        &:nth-child(odd) {
          background-color: $G-1-O;
        }

        .table-name:first-child {
          display: flex;
          height: inherit;
          align-items: center;
        }

        td {
          font-size: 14px;
          border-bottom: 2px solid rgb(0,0,0,0);

          &:not(:first-child) {
            text-align: center;
          }

          @include responsive-max(425px) {
            font-size: 10px;
          }

          .img-block {
            width: 40px;
            display: flex;
            margin: 0 15px;
          }

          &.table-type {
            color: $A1-300
          }

          &.table-time {
            color: $P50
          }

          &.table-price {
            & > div {
              width: 100%;
              display: flex;
              justify-content: center;

              .price-block {
                width: 100%;
                display: flex;
                align-items: center;

                &_icon {
                  width: 40%;

                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }

                &_value {
                  width: 60%;

                  span {
                    display: block;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination-manage-block {
    margin-top: 40px;

    @include responsive-max(991px) {
      margin-top: 20px;
      flex-direction: row;
    }

    &_pagination {
      @include responsive-max(768px) {
        width: 330px;
        order: -1;
        margin: 0 auto 10px;
      }
    }
  }
}