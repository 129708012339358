@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.limited-time-current-cosmetic {
  &_main {
    min-height: 800px;
    @extend %flex-align;

    &_content {
      padding: 108px 0;

      @include responsive-max(991px) {
        padding: 25px 0;
      }

      &_deadline {
        margin: 25px 0;
      }

      &_price-info {
        margin-bottom: 25px;

        &_price {
          margin-bottom: 25px;

          &_content {
            @extend %flex-align;

            &_eth, &_voolah {
              @extend %flex-align;

              span {
                height: 35px;
                min-width: 60px;
                margin-left: 8px;
                @extend .h-04-e-bold;

                @include responsive-max(991px) {
                  height: 20px;
                }
              }

            }

            &_eth {
              img {
                width: 28px;
                height: 44px;
                margin: 0 10px;

                @include responsive-max(991px) {
                  width: 19px;
                  height: 30px;
                }
              }
            }

            &_voolah {
              img {
                width: 48px;
                height: 48px;

                @include responsive-max(991px) {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            & > span {
              color: $P50;
              height: 36px;
              min-width: 77px;
              margin-left: 20px;
              @extend .h-04-e-bold;

              @include responsive-max(991px) {
                width: 36px;
                height: 20px;
                margin-left: 15px;
              }
            }

            &_text {
              @extend .h-05-e-bold;
              text-decoration: underline;
              padding: 0 15px 0 20px;

              @include responsive-max(991px) {
                padding: 0 10px;
              }
            }
          }
        }
      }

      button {
        width: 100%;
        margin: 25px 0;
      }

      &_description {
        p {
          @extend .p-01-regular;
        }
      }
    }
  }

  &_order-summery {
    &_content {
      margin-top: 20px;

      @include responsive-max(991px) {
        margin-top: 25px;
      }

      &_info {
        display: flex;
        margin-bottom: 25px;

        &_img {
          width: 120px;
          height: 120px;
          margin-right: 25px;

          @include responsive-max(991px) {
            width: 90px;
            height: 90px;
            margin-right: 15px;
          }

          & > img {
            width: inherit;
            height: inherit;
          }
        }

        &_details {
          @extend %flex-align;

          @include responsive-max(480px) {
            width: calc(100% - 105px);
            justify-content: space-between;
          }

          &_title {
            margin-right: 40px;

            @include responsive-max(480px) {
              margin-right: 0;
            }

            h6 {
              margin-top: 8px;
              @extend .h-06-bold;
            }
          }

          &_qantity {
            @extend .h-06-bold;
            color: $P50;
          }
        }
      }

      &_payments-methods {
        &_head {
          @extend %flex-justify-between;

          &_quantity {
            font-size: 12px;
            @extend .p-02-light;
            margin-bottom: 3px;

            @include responsive-max(991px) {
              font-size: 12px;
              margin-bottom: 1px;
            }

            span:first-child {
              color: $P50;
            }

            span:last-child {
              color: $G-5;
            }
          }

          &_error {
            color: $A2-300;
            @extend .p-03-regular;

            @include responsive-max(991px) {
              display: none !important;
            }
          }

          & > span {
            display: block;
          }
        }

        &_bottom {
          &_error {
            p {
              color: $A2-300;
              padding-top: 15px;
              text-align: center;
              @extend .p-03-regular;

              @include responsive-min(991px) {
                display: none;
              }
            }
          }
        }

        & > div:last-child {
          margin-top: 10px;
        }
      }

      &_total {
        margin-top: 10px;
        @extend %flex-justify-between;

        div {
          color: $P50;
          @extend .n-link-u;
          text-transform: uppercase;
        }
      }

      &_mobile-error {
        display: none;
        color: $A2-300;
        padding-top: 25px;
        text-align: center;
        @extend .p-03-regular;

        @include responsive-max(991px) {
          display: block;
        }
      }

      &_buttons {
        margin-top: 25px;
        @extend %flex-justify-between;

        @include responsive-max(480px) {
          flex-direction: column;
        }

        button {
          order: 1;
          width: 48%;

          @include responsive-max(480px) {
            width: 100%;

            &:last-child {
              order: -1;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}