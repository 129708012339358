@import "src/scss/color2";
@import "src/scss/fontSize";

.buttonV3 {
  border: none;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  font-weight: 800;
  padding: 0 15px;

  &.right {
    flex-direction: row-reverse;
  }

  &:active {
    transition: 0.1s ease-out;
  }

  &.path {
    clip-path: polygon(12px 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 12px);
  }

  //types
  &.primary {
    background: $GR-Y1;
    color: $P50;

    &:hover {
      background: $P300;
    }

    &:active {
      background: $P500;
    }

    &:disabled {
      @extend .disabled
    }
  }

  &.secondary {
    background: $GR-Red;
    color: $P50;

    &:hover {
      background: $A2-300;
    }

    &:active {
      background: $A2-500;
    }

    &:disabled {
      @extend .disabled
    }
  }

  &.outline {
    background: none;
    color: $A2-300;
    border: 1px solid  $A2-300;

    &:hover {
      background: $A2-300;
      color: $P50;
    }

    &:active {
      background: $A2-200;
      color: $P50;
    }

    &:disabled {
      border: 1px solid $G-1;
      color: $G-1;
      background: none;
    }
  }

  &.opacity {
    background: $G-1-O;
    color: $P50;

    &:hover {
      background: $G-2-O;
    }

    &:active {
      background: $G-1-O;
    }

    &:disabled {
      @extend .disabled
    }
  }

  &.danger {
    background: $A2-75-O;
    color: $A2-300;

    &:hover {
      background: $A2-200-O;
    }

    &:active {
      background: $A2-75-O;
    }

    &:disabled {
      @extend .disabled
    }
  }

  &.white{
    background: $White;
    color: $Black;

    &:hover{
      background: $A2-300;
      color: $White;
    }

    &:active {
      background: $A2-500;
    }

    &:disabled {
      @extend .disabled
    }
  }

  //sizes
  &.lg {
    font-size: 16px;
    line-height: 20px;
    height: 56px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
      line-height: 16px;
      height: 48px;
    }

    .buttonV3_icon {
      margin-right: 20px;

      &.right {
        margin-right: 0;
        margin-left: 20px;
      }

      width: 32px;
      height: 32px;

      @media screen and (max-width: 991px) {
        width: 26px;
        height: 26px;
      }

      img {
        width: inherit;
        height: inherit;
      }
    }
  }

  &.md {
    font-size: 14px;
    line-height: 16px;
    height: 48px;

    .buttonV3_icon {
      margin-right: 15px;
      width: 26px;
      height: 26px;

      &.right {
        margin-right: 0;
        margin-left: 15px;
      }

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  &.sm {
    font-size: 9px;
    line-height: 12px;
    height: 32px;

    .button_icon {
      margin-right: 10px;

      &.right {
        margin-right: 0;
        margin-left: 10px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.disabled {
  color: $G-2;
  background: $G-1;
}