@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/vars";
@import "src/scss/mixin";

@mixin rarity-style($color,$image-url) {
  background-size: cover;
  border: 1.68px solid $color;
  background-image: url($image-url);

  & > .nft-cart-limited_info {
    & > .nft-cart-limited_info_name-block {
      background-color: $color;
    }
  }
}

.nft-cart-limited {
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  background-color: #141413 !important;

  &.skeleton {
    background-color: rgba(20, 20, 19, 0) !important;

    .nft-cart-limited_info_price-block {
      background-color: rgba(20, 20, 19, 0);
    }
  }

  &:hover {
    border: 1.68px solid $G-6 !important;
  }

  & > img {
    width: 100%;
    height: 84%;
  }

  &_info {
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 220px;
    position: absolute;
    @extend %flex-column-end;

    &_name-block {
      height: 35px;
      display: flex;
      align-items: center;

      &.two_lines_active_desktop {
        span {
          font-size: 12px !important;
        }
      }

      &.two_lines_active_mobile {
        span {
          font-size: 10px !important;
        }
      }

      span {
        width: 100%;
        display: block;
        text-align: center;
      }

      & > span:first-child {
        @extend .button-u-bold;
      }

      & > span:last-child {
        @extend .c-u-t-light;
        letter-spacing: 0.4px;
        text-transform: uppercase;
      }
    }

    &_price-block {
      height: 40px;
      background-color: #232222;
      @extend %flex-align-between;
      border-top: 0.857px solid rgba(255, 255, 255, 0.20);

      &_voolah, &_eth {
        @extend %flex-align;
        @extend .p-01-regular;

        img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }
      }

      &_eth {
        span {
          min-width: 35px;
          min-height: 15px;
        }
      }
    }
  }

  &.lg {
    width: 300px;
    height: 475px;

    @include responsive-max(1200px) {
      width: 255px;
      height: 400px;
    }

    @include responsive-max(768px) {
      width: 326px;
      height: 475px;
    }

    img {
      object-fit: contain;
    }

    .nft-cart-limited_info_price-block {
      padding: 0 20px;
    }

    .nft-cart-limited_info_price-block_voolah,
    .nft-cart-limited_info_price-block_eth {
      @include responsive-max(991px){
        font-size: 19px;
      }
    }
  }

  &.md {
    width: 200px;
    height: 226px;
    text-align: center;

    @include responsive-max(1200px) {
      width: 165px;
      height: 190px;
    }

    @include responsive-max(768px) {
      width: 155px;
      height: 185px;
    }

    img {
      object-fit: contain;
    }

    .nft-img {
      width: 100% !important;
      height: 73% !important;
    }

    .nft-cart-limited_info {
      height: 100px;

      & > .nft-cart-limited_info_name-block {
        height: 30px;
      }

      & > div:last-child {
        height: 30px;

        & > div {
          img {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
        }
      }

      .nft-cart-limited_info_price-block {
        padding: 0 4px;
      }

      .nft-cart-limited_info_price-block_voolah,
      .nft-cart-limited_info_price-block_eth {
        @include responsive-max(991px){
          font-size: 14px;
        }
      }
    }

    .nft-cart-limited_info_price-block {
      img {
        @include responsive-max(480px) {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  &.ordinary {
    background: linear-gradient(180deg, #161614 0%, #3E3E3E 100%);
    border-image: linear-gradient(180deg, #404040 0%, #7A7A7A 100%) 1;

    & > div {
      background: linear-gradient(180deg, rgba(40, 40, 38, 0.00) 0%, #858585 100%);

      & > div:last-child {
        -webkit-box-shadow: inset -5px -30px 14px -26px #D9D9D9;
        -moz-box-shadow: inset -5px -30px 14px -26px #D9D9D9;
        box-shadow: inset -5px -30px 14px -26px #D9D9D9;
      }
    }
  }

  &.common {
    @include rarity-style(#064500, "../../../../assets/png/common-cart-background-linear.png")
  }

  &.rare {
    @include rarity-style(#002F76, "../../../../assets/png/rare-cart-background-linear.png")
  }

  &.legendary {
    @include rarity-style(#8C2600, "../../../../assets/png/legendary-cart-background-linear.png")
  }

  &.elite {
    @include rarity-style(#69009F, "../../../../assets/png/elite-cart-background-linear.png");
  }

  &.close {
    background-size: contain;
    border: 1.68px solid #A70021;

    &.lg {
      background-image: url("../../../../assets/png/red_question_mark_medium.png");
    }

    &.md {
      background-image: url("../../../../assets/png/red_question_mark_small.png");
    }

    & > div {
      & > div:first-child {
        background-color: #A70021;
      }
    }
  }

  &.select {
    border: 2px solid $G-6;
  }

  &_never-sold-again {
    top: 0;
    left: 0;
    padding: 1.5px;
    position: absolute;
    background: #A8A8A8 !important;

    & > div {
      @extend %flex-align;
      background: #101010 !important;
    }

    span {
      color: $White;
      display: block;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    &.lg {
      width: 183px;
      height: 40px;
      clip-path: polygon(0 0, 100% 0, 87% 99%, 0% 100%);

      span {
        font-size: 12px;
        margin-left: 6px;
        line-height: 16px;
      }

      & > div {
        width: 179px;
        height: 37px;
        padding-left: 8.5px;
        clip-path: polygon(0 0, 100% 0, 87% 103%, 0% 100%);
      }
    }

    &.md {
      width: 123px;
      height: 25px !important;
      clip-path: polygon(0 0, 100% 0, 91% 99%, 0% 100%);

      span {
        font-size: 8px;
        margin-left: 3px;
        line-height: 12px;
        text-shadow: 0.409px 0.818px 0 #000;
      }

      & > div {
        width: 120px;
        height: 22px;
        padding-left: 7px;
        clip-path: polygon(0 0, 100% 0, 91% 99%, 0% 100%);

        svg {
          width: 9px;
          height: 9px;
        }
      }
    }
  }
}