@import "src/scss/color2";
@import "src/scss/fontSize";
@import "src/scss/main";

.deck {

  &_main {
    height: 950px;
    position: relative;

    @media screen and (max-width: 1650px) {
      height: 850px;
    }

    @media screen and (max-width: 1440px) {
      height: 830px;
    }

    @media screen and (max-width: 991px) {
      height: 700px;
    }

    @media screen and (max-width: 768px) {
      height: 570px;
    }

    &_back-img {
      z-index: 1;
      width: 100%;
      max-height: 950px;
      object-fit: cover;

      @media screen and (max-width: 1650px) {
        max-height: 850px;
      }

      @media screen and (max-width: 1440px) {
        height: 830px;
      }

      @media screen and (max-width: 991px) {
        height: 700px;
        object-position: right;
      }

      @media screen and (max-width: 768px) {
        height: 570px;
      }
    }

    &_content {
      position: absolute;
      top: 12%;
      left: 207px;
      width: 454px;
      height: 510px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        left: 0;
        width: 100%;
        height: 430px;
        text-align: center;
      }

      h1 {
        @extend .d-02-e-bold;
        color: $P50;
        margin-bottom: 40px;

        @media screen and (max-width: 991px) {
          width: 330px;
          text-align: center;
          margin: 0 auto 10px;
        }
      }

      button {
        max-width: 300px;

        @media screen and (max-width: 991px) {
          margin: auto;
        }

        @media screen and (max-width: 480px) {
          width: 240px;
        }
      }

      &_img {
        width: 310px;

        @media screen and (max-width: 991px) {
          width: 155px;
          margin: auto;
        }
      }
    }
  }

  &_island {
    @extend .section-padding;

    &_underTitle {
      @extend .deck-section-under-title
    }

    &_content {
      @extend .content-margin;

      &_island-block {
        width: 100%;

        img {
          height: 100%;
          width: inherit;
        }
      }

      &_game-view-block {
        display: flex;
        margin-top: 50px;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
          margin-top: 15px;
        }

        &_column {
          flex: 32%;
          max-width: 32%;

          img {
            width: 100%;
          }

          img:last-child {
            margin-top: 20px;

            @media screen and (max-width: 991px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &_download {
    background: $G-1-O;
    @extend .section-padding;

    button {
      width: 275px;
      margin: 40px auto 60px;
    }

    h4 {
      text-align: center;
      @extend .h-04-e-bold;
      text-transform: uppercase;
    }

    &_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @extend .content-margin;

      @media screen and (max-width: 1200px) {
        gap: 15px;
      }

      &_item {
        width: 212px;
        margin: auto;

        @media screen and (max-width: 991px) {
          width: 152px;
        }

        &_img-block {
          width: 212px;
          display: flex;
          height: 212px;
          align-items: center;
          justify-content: center;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(128, 128, 128, 0.02) 100%);

          img {
            width: 96px;
            height: 96px;

            @media screen and (max-width: 991px) {
              width: 64px;
              height: 64px;
            }
          }

          @media screen and (max-width: 991px) {
            width: 152px;
            height: 152px;
          }
        }

        h5 {
          @extend .h-05-e-bold;
          margin-top: 30px;
          text-align: center;
          text-transform: uppercase;

          @media screen and (max-width: 991px) {
            margin-top: 15px;
          }
        }
      }
    }
  }

  &_owner-message {
    height: 880px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 991px) {
      height: 495px;
    }

    &_text {
      max-width: 821px;
      padding: 0 25px;

      p {
        @extend .h-01-e-bold;
        text-transform: uppercase;
        text-align: center;
      }

      &_mes {
        margin: 15px 0 50px;

        &::before {
          content: "“";
          font-size: 504px;
          color: $G-1;
          font-weight: 800;
          z-index: -1;
          position: absolute;
          left: 156px;
          top: 250px;

          @media screen and(max-width: 991px) {
            font-size: 200px;
            left: 12px;
            top: 105px;
          }

        }
      }

      &_owner {
        margin-bottom: 15px;

        &::after {
          content: "“";
          font-size: 504px;
          color: $G-1;
          font-weight: 800;
          transform: rotate(180deg);
          z-index: -1;
          position: absolute;
          right: 156px;
          bottom: 250px;

          @media screen and(max-width: 991px) {
            font-size: 200px;
            right: 12px;
            bottom: 105px;
          }
        }
      }

      .gold-underline {
        margin: auto;
      }
    }
  }

  &_barriers {
    @extend .section-padding;
    background: url("../../../../src/assets/png/background-space.png");
    background-size: cover;
    width: 100%;

    &_underTitle {
      @extend .deck-section-under-title
    }

    &_cart {
      gap: 60px;
      display: grid;
      margin-top: 60px;
      @include grid-display(2);

      @media screen and (max-width: 991px) {
        gap: 40px;
        margin-top: 40px;
        @include grid-display(1);
      }

      &_item {
        width: 100%;

        @media screen and (max-width: 991px) {
          margin: auto;
          max-width: 350px;
        }

        &_title {
          max-width: 400px;
          text-align: center;
          margin: 30px auto 0;
          @extend .h-04-e-bold;
          text-transform: uppercase;

          @media screen and (max-width: 991px) {
            max-width: 275px;
            margin: 20px auto 0;
          }
        }
      }
    }
  }

  &_zero-barriers {
    @extend .section-padding;
    background: url("../../../assets/png/background-space01.png") no-repeat;
    background-size: cover;

    h3 {
      max-width: 820px;
      margin: auto;

      @media screen and (max-width: 991px) {
        max-width: 450px;
      }
    }

    &_underTitle {
      @extend .deck-section-under-title;
    }

    &_carts {
      display: flex;
      margin-top: 280px;
      max-width: 1115px;
      padding: 45px 0 65px;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        padding: 0;
        margin-top: 60px;
        text-align: center;
        flex-direction: column;

        @media screen and (max-width: 991px) {
          margin-top: 40px;
        }
      }

      &_item {

        @media screen and (max-width: 991px) {
          &:not(:first-child) {
            margin-top: 25px;
          }
        }

        img {
          width: 200px;
          height: 200px;
        }

        h6 {
          margin-top: 40px;
          text-align: center;
          @extend .h-06-bold;
        }
      }
    }
  }

  &_goal {
    width: 100%;
    padding-top: 80px;
    background: url("../../../assets/png/background-space03.png") no-repeat;
    background-size: cover;
    position: relative;

    &_content {
      display: flex;

      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
      }

      &_img {
        width: 60%;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 991px) {
          width: 100%;
        }

        &_gradient-background {
          right: 0;
          bottom: 0;
          z-index: -1;
          width: 609px;
          height: 495px;
          position: absolute;
          background: radial-gradient(44.85% 112.1% at 55.13% 104%, rgba(12, 163, 232, 0.3) 9%, rgba(12, 163, 232, 0) 100%);

          @media screen and (max-width: 991px) {
            right: 88px;
            width: 350px;
            height: 222px;
          }

          @media screen and (max-width: 480px) {
            right: 0;
          }
        }

        img {
          width: 90%;
          margin: -4px;

          @media screen and (max-width: 991px) {
            width: 400px;
          }
        }
      }

      &_text {
        width: 60%;
        display: flex;
        align-items: center;
        margin-left: -179px;
        z-index: 3;

        @media screen and (max-width: 991px) {
          margin: 50px auto 0;
          text-align: center;
        }

        @media screen and (max-width: 480px) {
          width: 90%;
        }

        p {
          @extend .h-04-e-bold;
          text-transform: uppercase;
          max-width: 565px;

          span {
            color: #1B2CC9;
          }
        }
      }
    }
  }

  &_audience {
    @extend .section-padding;

    &_content {

      &_diagram {
        display: flex;
        padding: 45px 25px;
        background: $G-1-O;
        @extend .content-margin;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
          padding: 25px;
        }

        &_img {
          width: 54%;

          @media screen and (max-width: 991px) {
            display: none;
          }

          img {
            width: 100%;
          }

          &_source {
            color: $G-4;
            margin-top: 20px;
            @extend .c-u-t-light;
          }
        }

        &_info {
          width: 40%;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

          &_title {
            @extend .h-02-e-bold;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
              text-align: center;
            }
          }

          &_underTitle {
            color: $G-3;
            @extend .n-link-u;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
              display: block;
              text-align: center;
            }
          }

          & > p {
            @extend .p-01-regular;
            margin: 20px 0;
          }

          & > p:last-child {
            margin: 40px 0 0 0;

            @media screen and (max-width: 991px) {
              margin: 20px 0 0 0;
            }
          }

          &_img {

            @media screen and (min-width: 991px) {
              display: none;
            }

            img {
              width: 100%;
              margin: 20px 0;
            }

            &_source {
              color: $G-4;
              font-size: 10px;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }

      &_players-details {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
          margin-top: 15px;
          flex-direction: column;
        }

        &_count-item {
          width: 16%;
          height: 178px;
          display: flex;
          background: $G-1-O;
          align-items: center;

          @media screen and (min-width: 480px) {
            justify-content: center;
          }

          @media screen and (max-width: 991px) {
            width: 100%;
            height: 65px;
            padding: 0 15px;
            margin-bottom: 15px;
          }

          &_block {

            @media screen and (min-width: 991px) {
              text-align: center;
            }

            @media screen and (max-width: 991px) {
              display: flex;
              align-items: center;
            }

            div:first-child {
              color: $A2-400;

              @media screen and (max-width: 991px) {
                margin-right: 20px;
              }
            }

            div:last-child {
              margin-top: 15px;

              @media screen and (max-width: 991px) {
                margin: 0;
              }

              span {
                display: block;
                text-transform: uppercase;
              }

              span:first-child {
                @extend .n-link-u;
                line-height: 20px;
              }

              span:last-child {
                color: $G-4;
                line-height: 16px;
                @extend .c-u-t-light;
              }
            }
          }
        }

        &_total-count {
          width: 47%;
          height: 178px;
          display: flex;
          background: $G-1-O;
          align-items: center;
          justify-content: space-around;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

          &_block {
            display: flex;
            align-items: center;


            & > div:first-child {
              display: flex;
              margin-right: 40px;
              align-items: center;

              @media screen and (max-width: 991px) {
                display: none;
              }

              & > div:first-child {
                width: 32px;
                height: 32px;
                display: flex;
                color: $A1-300;
                align-items: center;
                background: #00ecb01a;
                justify-content: center;
              }

              & > div:last-child {
                margin-left: 8px;

                span {
                  color: $A1-300;
                  @extend .h-05-e-bold
                }
              }
            }

            div:last-child {
              text-align: center;

              h4 {
                margin-bottom: 10px;
                @extend .h-04-e-bold;
                text-transform: uppercase;
              }

              span {
                color: $G-4;
                @extend .c-u-t-light;
                text-transform: uppercase;
              }

              & > div {
                margin: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                & > div:first-child {
                  width: 32px;
                  height: 32px;
                  display: flex;
                  color: $A1-300;
                  align-items: center;
                  background: #00ecb01a;
                  justify-content: center;
                }

                & > div:last-child {
                  margin-left: 8px;

                  span {
                    color: $A1-300;
                    @extend .h-05-e-bold
                  }
                }

                @media screen and (min-width: 991px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &_big-picture {
    @extend .section-padding;
    position: relative;


    &_red-back {
      top: 0;
      width: 100%;
      z-index: -1;
      display: none;
      height: 100%;
      position: absolute;

      @media screen and(max-width: 991px) {
        display: block;
        background: radial-gradient(60.73% 122.25% at 48.13% 20.99%, rgba(255, 47, 55, 0.3) 15%, rgba(255, 47, 55, 0) 50%), #000000;
      }

      @media screen and (max-width: 480px) {
        background: radial-gradient(90.73% 122.25% at 52.13% 22.99%, rgba(255, 47, 55, 0.3) 23%, rgba(255, 47, 55, 0) 51%), #000000;
      }
    }

    .row_img {
      display: flex;

      @media screen and (max-width: 991px) {
        justify-content: center;
        margin-top: 10px;
      }

      img {
        width: 490px;

        @media screen and (max-width: 991px) {
          width: 230px;
        }
      }
    }


    &_content {
      ul {
        @extend .list;
      }
    }
  }

  &_acquire-better {
    @extend .section-padding;
    padding-bottom: 0;
    background: rgba(255, 255, 255, 0.05);

    @media screen and (max-width: 991px) {
      padding-bottom: 40px;
    }

    .row_img {
      text-align: center;
      display: flex;
      background: radial-gradient(47.5% 103.07% at 53.03% 100%, rgba(255, 47, 55, 0.3) 33%, rgba(255, 47, 55, 0) 101%);


      @media screen and (max-width: 991px) {
        background: radial-gradient(50% 94.15% at 48.47% 95.98%, rgba(255, 47, 55, 0.3) 0%, rgba(255, 47, 55, 0) 82%);
        margin-top: 20px;
        justify-content: center;
      }

      img {
        width: 490px;

        @media screen and (max-width: 991px) {
          width: 230px;
        }
      }
    }

    .row_content {
      padding-bottom: 25px;
      @media screen and (max-width: 991px) {
        padding-top: 45px;
      }
    }

    &_content {
      ul {
        @extend .list;
      }
    }
  }

  &_retain-better {
    @extend .section-padding;
    padding-bottom: 0;

    @media screen and (max-width: 991px) {
      padding-bottom: 40px;
    }

    .row_img {
      text-align: center;
      display: flex;

      @media screen and (max-width: 991px) {
        background: radial-gradient(50% 94.15% at 48.47% 95.98%, rgba(255, 47, 55, 0.3) 0%, rgba(255, 47, 55, 0) 82%);
        margin-top: 20px;
        justify-content: center;
      }

      img {
        width: 490px;

        @media screen and (max-width: 991px) {
          width: 230px;
        }
      }
    }

    .row_content {
      padding-bottom: 25px;
      @media screen and (max-width: 991px) {
        padding-top: 45px;
      }
    }

    &_content {
      ul {
        @extend .list;
      }
    }
  }

  &_monetized-better {
    @extend .section-padding;
    background: $G-1-O;

    &_underTitle {
      @extend .deck-section-under-title;
      margin-top: 50px !important;
    }

    &_content {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      @media screen and (max-width: 991px) {
        margin-top: 30px;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }

      &_item {
        text-align: center;
        width: 32%;
        margin: 0 5px;

        @media screen and (max-width: 991px) {
          width: 100%;
        }

        img {
          max-width: 100%;

          @media screen and (max-width: 768px) {
            max-width: 300px;
            max-height: 420px;
            width: inherit;
            margin: 30px auto 0;
          }
        }

        h4 {
          width: 320px;
          display: block;
          text-align: center;
          margin: 42px auto 0;
          @extend .h-04-e-bold;
          text-transform: uppercase;

          @media screen and (max-width: 991px) {
            margin-top: 15px;
            width: 210px;
          }
        }
      }
    }
  }

  &_monetization {
    @extend .section-padding;

    @media screen and (max-width: 480px) {
      h3 {
        margin: auto;
        max-width: 270px;
      }
    }

    .row {
      padding: 60px 0;

      @media screen and (max-width: 991px) {
        padding: 48px 0;
      }
    }

    .row_img:not(:last-child) {
      text-align: center;

      img {
        width: 330px;

        @media screen and (max-width: 991px) {
          width: 230px;
          margin-bottom: -4px;
        }
      }
    }


    &_item-one {
      position: relative;

      &_red-back {
        width: 600px;
        left: -450px;
        height: 400px;
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 47, 55, 0.17) 0%, rgba(255, 47, 55, 0) 100%);

        @media screen and (max-width: 991px) {
          left: -301px;
          bottom: 200px;
        }
      }

      &_coin {
        width: 210px;
        height: 170px;
        right: -165px;
        @extend .back-item-style;
        background: url("../../../assets/png/c3.png") no-repeat;

        @media screen and (max-width: 991px) {
          width: 85px;
          right: -20px;
          height: 65px;
        }
      }

      .row_img {
        img {
          width: 100% !important;

          @media screen and (max-width: 991px) {
            width: 330px !important;
          }
        }
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    &_item-two {
      position: relative;

      .row_img {
        background: radial-gradient(45.52% 50.47% at 51.99% 94.58%, rgba(160, 160, 160, 0.18) 0%, rgba(255, 255, 255, 0) 97.48%);
      }

      &_coin {
        width: 85px;
        left: 380px;
        height: 85px;
        @extend .back-item-style;
        background: url("../../../assets/png/c4.png") no-repeat;

        @media screen and (max-width: 991px) {
          width: 45px;
          height: 45px;
          left: 200px;
          top: -30px;
        }
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    &_item-three {
      position: relative;

      .row_img {
        background: radial-gradient(45.52% 50.47% at 51.99% 94.58%, rgba(160, 160, 160, 0.18) 0%, rgba(255, 255, 255, 0) 97.48%)
      }

      &_coin {
        left: 160px;
        width: 128px;
        height: 117px;
        @extend .back-item-style;
        background: url("../../../assets/png/c4.png") no-repeat;

        @media screen and (max-width: 991px) {
          top: 10px;
          left: 50px;
          width: 45px;
          height: 45px;
        }
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    &_item-four {
      position: relative;

      .row_img {
        background: radial-gradient(45.52% 50.47% at 51.99% 94.58%, rgba(160, 160, 160, 0.18) 0%, rgba(255, 255, 255, 0) 97.48%);
      }

      &_red-back {
        top: -100px;
        width: 600px;
        height: 400px;
        right: -450px;
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 47, 55, 0.17) 0%, rgba(255, 47, 55, 0) 100%);

        @media screen and (max-width: 991px) {
          top: -260px;
          right: -343px;
        }
      }

      &_red-back-2 {
        top: 84px;
        left: 364px;
        width: 330px;
        height: 330px;
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 47, 55, 0.1) 0%, rgba(255, 47, 55, 0) 100%);
      }

      &_coin {
        top: 138px;
        width: 180px;
        height: 95px;
        right: -171px;
        @extend .back-item-style;
        background: url("../../../assets/png/c5.png") no-repeat;

        @media screen and (max-width: 991px) {
          right: 0;
          top: -20px;
          left: 15px;
          width: 45px;
          height: 45px;
        }
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    &_item-five {
      position: relative;

      &_red-back {
        top: -100px;
        width: 600px;
        height: 400px;
        right: -450px;
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 47, 55, 0.17) 0%, rgba(255, 47, 55, 0) 100%);

        @media screen and (max-width: 991px) {
          top: -260px;
          right: -343px;
        }
      }

      &_coin {
        top: -25px;
        width: 83px;
        left: 591px;
        height: 83px;
        @extend .back-item-style;
        background: url("../../../assets/png/c2.png");

        @media screen and (max-width: 991px) {
          width: 65px;
          height: 65px;
          top: -64px;
          left: 170px
        }
      }

      &_coin-2 {
        top: 325px;
        width: 120px;
        right: 140px;
        height: 97px;
        @extend .back-item-style;
        background: url("../../../assets/png/c3.png");

        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      img {
        width: 100% !important;
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    &_item-six {
      position: relative;

      &_coin {
        top: 0;
        left: 356px;
        width: 90px;
        height: 85px;
        @extend .back-item-style;
        background: url("../../../assets/png/c4.png");

        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      &_coin-2 {
        width: 105px;
        right: -55px;
        bottom: -55px;
        height: 115px;
        @extend .back-item-style;
        background: url("../../../assets/png/c5.png");

        @media screen and (max-width: 991px) {
          top: 215px;
          right: 25px;
          width: 50px;
          height: 55px;
          bottom: 0;
        }
      }

      &_text {
        @extend .p-01-regular;
      }

      &_estimate {
        @extend .estimate-style;
      }
    }

    .back-item-style {
      position: absolute;
      filter: opacity(0.1);
      background-size: contain;
    }

    .estimate-style {
      margin-top: 25px;

      @media screen and (max-width: 991px) {
        margin-top: 15px;
      }

      span {
        @extend .c-u-t-light;
        color: $A2-300;
        text-transform: uppercase;
      }

      p {
        @extend .n-link-u;
        margin-top: 3px;
        text-transform: uppercase;
      }
    }
  }

  &_win {
    @extend .section-padding;
    background: rgba(12, 13, 23, 0.9);;

    &_content {
      display: flex;
      padding: 45px 45px 0 45px;
      justify-content: space-between;

      @media screen and (max-width: 991px) {
        flex-direction: column;
      }

      &_option-item {
        width: 32%;
        padding: 0 0 0 8px;
        border-left: 1px solid $A2-300;

        @media screen and (max-width: 991px) {
          width: 85%;
          margin: auto;
          padding: 25px 20px;

          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }

        @media screen and (max-width: 480px) {
          width: 100%;
        }

        h4 {
          color: $P300;
          @extend .h-05-e-bold;
          text-transform: uppercase;

          @media screen and(max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        ul {
          @extend .list;

          @media screen and (max-width: 991px) {
            margin-top: 20px;
          }

          li {
            @extend .p-02-light;
          }
        }
      }
    }
  }

  &_similar-games {
    @extend .section-padding;

    &_content {
      h3:last-child {
        max-width: 935px;
      }

      &_item-one {
        @extend .item-block;

        div:nth-child(1) {
          background: url("../../../assets/png/roblox.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(2) {
          background: url("../../../assets/png/fortnite.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(3) {
          background: url("../../../assets/png/fallguys.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(4) {
          background: url("../../../assets/png/battlegrounds.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(5) {
          background: url("../../../assets/png/nbk2b.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(6) {
          background: url("../../../assets/png/apex.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(7) {
          background: url("../../../assets/png/warzone.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(8) {
          background: url("../../../assets/png/warzone2.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(9) {
          background: url("../../../assets/png/rocket.png") no-repeat;
          background-size: contain;
        }
      }

      &_item-two {
        padding-bottom: 0;
        @extend .item-block;

        div:nth-child(1) {
          background: url("../../../assets/png/rogue.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(2) {
          background: url("../../../assets/png/spellbreak.png") no-repeat;
          background-size: contain;
        }

        div:nth-child(3) {
          background: url("../../../assets/png/splitGate.png") no-repeat;
          background-size: contain;
        }
      }

      .item-block {
        gap: 15px;
        display: flex;
        flex-wrap: wrap;
        padding: 50px 0;
        justify-content: center;

        @media screen and (max-width: 991px) {
          padding: 30px 0;
        }

        div {
          width: 260px;
          height: 220px;

          @media screen and (max-width: 991px) {
            width: 152px;
            height: 125px;
          }
        }
      }
    }
  }

  &_modernize-game {
    background: url("../../../assets/png/founder-back-03.png") no-repeat center $G-1;
    @extend .section-padding;
    background-size: contain;
    height: 805px;
    width: 100%;

    @media screen and (max-width: 1400px) {
      background-size: cover;
    }

    @media screen and (max-width: 1200px) {
      height: 705px;
      background-size: cover;
    }

    @media screen and (max-width: 991px) {
      height: 100%;
      background: $G-1;
    }

    &_content {
      display: flex;

      @media screen and (max-width: 991px) {
        flex-direction: column;
      }

      & > div:first-child {
        width: 50%;
        min-height: 320px;
        visibility: hidden;

        @media screen and (max-width: 991px) {
          width: 100%;
          margin: 40px auto 0;
          visibility: visible;
        }


        img {
          width: 327px;
          margin: auto;
          height: 327px;
          display: none;

          @media screen and (max-width: 991px) {
            display: block;
          }
        }
      }

      & > div:last-child {
        width: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 991px) {
          width: 100%;
          max-width: 500px;
        }
      }

      &_founder {
        margin-top: 60px;

        @media screen and (max-width: 991px) {
          margin-top: 40px;
        }

        h4 {
          @extend .h-04-e-bold;
          text-transform: uppercase;
        }

        ul {
          @extend .list;
          margin-top: 20px;

          li {
            margin-top: 5px !important;
          }
        }
      }

      &_members {
        margin-top: 40px;

        h4 {
          @extend .h-04-e-bold;
          text-transform: uppercase;
        }

        ul {
          padding: 0;
          margin-top: 20px;
          list-style-type: none;

          li {
            margin-top: 5px;

            span:first-child {
              @extend .p-01-regular;
            }

            span:last-child {
              color: $A2-500;
              @extend .n-link-u;
              margin-left: 30px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &_now {
    background: url("../../../assets/png/hero-group.png") no-repeat center;
    justify-content: center;
    background-size: cover;
    align-items: center;
    display: flex;
    height: 555px;
    width: 100%;

    @media screen and (max-width: 480px) {
      clip-path: polygon(15% 0, 100% 0, 100% 54%, 87% 100%, 0 99%, 0 49%);
    }

    @media screen and (max-width: 991px) {
      height: 505px;
    }

    h3 {
      @media screen and (max-width: 991px) {
        max-width: 400px;
        margin: auto;
      }

      @media screen and (max-width: 375px) {
        max-width: 300px;
      }
    }

    &_content {
      max-width: 583px;

      p {
        @extend .p-01-regular;
        margin: 30px;
        text-align: center;
      }

      button {
        max-width: 300px;
        margin: auto;
      }
    }
  }

  &_info {
    background: url("../../../assets/png/contacts-back.png") no-repeat center $G-1;
    background-size: cover;
    align-items: center;
    position: relative;
    height: 600px;
    display: flex;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      display: block;
      height: 700px;
      background: $G-1;
      padding-top: 55px;
    }

    &_contact {
      position: relative;

      img {
        width: 210px;
        margin-bottom: 90px;

        @media screen and (max-width: 991px) {
          margin-bottom: 60px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        li:not(:last-child) {
          margin-bottom: 35px;

          @media screen and (max-width: 991px) {
            margin-bottom: 30px;
          }
        }

        li {
          display: flex;
          align-items: center;
          @extend .p-02-light;

          span {
            margin-left: 30px;
            width: 180px;
          }
        }
      }
    }

    & > div:last-child {
      display: none;
      width: 515px;
      height: 515px;
      background: url("../../../assets/png/game-parts.png");
      background-size: cover;
      position: absolute;
      right: -164px;
      bottom: -196px;

      @media screen and (max-width: 991px) {
        display: block;
      }
    }
  }
}

.content-margin {
  margin-top: 50px;

  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }
}

.deck-section-under-title {
  max-width: 820px;
  margin: 15px auto;
  text-align: center;
  @extend .p-01-regular;

  @media screen and (max-width: 991px) {
    max-width: 580px;
    padding: 0 10px;
  }
}

.list {
  padding-left: 15px;

  li {
    margin-top: 18px;
    padding-left: 15px;
    @extend .p-01-regular;

    @media screen and (max-width: 991px) {
      margin-top: 10px;
    }

    &::marker {
      content: "-";
      color: $A2-300;
      width: 5px;
      font-size: 28px;
      font-weight: 600;
    }
  }
}
