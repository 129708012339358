@import "src/scss/color2";

.wrapper {
  margin: auto;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    display: none;
  }
}