@import "src/scss/color2";
@import "src/scss/fontSize";

ul {
  padding-left: 30px;
}

.page-main_title-block_privacy-text {
  width: 560px;

  @media screen and (max-width: 991px) {
    width: 330px;
  }

  @media screen and (max-width: 375px) {
    width: auto;
  }
}

.rights-content {
  position: relative;
  bottom: 105px;
  z-index: 3;

  @media screen and (max-width: 480px) {
    bottom: 30px;
  }

  > div {
    padding: 0 25px;

    > div {
      max-width: 890px;
      margin: auto;
      padding: 70px 25px;
      background: $G-1;

      h6 {
        @extend .h-06-bold;
        color: $A2-500;
        padding: 0 35px 10px;

        @media screen and (max-width: 991px) {
          padding: 0 0 10px 0;
        }
      }

      @media screen and (max-width: 991px) {
        padding: 40px 16px;
      }
    }
  }

  &_block {
    background-color: $G-1;
    padding: 0 35px;
    height: 890px;
    overflow-y: scroll;

    p {
      margin-bottom: 1rem;
      @extend .p-01-regular;
    }

    @media screen and (max-width: 991px) {
      padding: 0 10px 0 0;
    }

    li {
      @extend .p-01-regular;
    }

    h3 {
      @extend .h-03-e-bold;
      margin-bottom: 30px;
      text-align: center;
    }

    h5 {
      @extend .h-05-e-bold;
      margin:15px 0 15px 30px;

      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 18px;
        margin: 10px 0 10px 15px;
      }

      span {
        padding-right: 20px;

        @media screen and (max-width: 991px) {
          padding-right: 10px;
        }
      }
    }

    table, th, td {
      border: 1px solid $P50;
    }

    table {
      margin-bottom: 20px;
      width: 100%;
      border-collapse: collapse;

      @media screen and (max-width: 991px) {
        font-size: 8px;
      }

      td {
        padding: 10px;
        @media screen and (max-width: 991px) {
          padding: 3px;
        }
      }

      th {
        padding: 10px;
        @media screen and (max-width: 991px) {
          padding: 3px;
        }
      }
    }

    a {
      color: $A2-300;
      transition: 0.3s ease-in-out;
      text-decoration: underline;

      &:hover {
        color: $P50;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $A2-500;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $A2-300-O;
    }
  }
}

