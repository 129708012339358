.refresh-tool {
  width: 25px;
  display: flex;
  cursor: pointer;
  align-items: center;
  transform: rotate(0);
  transition: 0.3s ease-out;

  &.refreshed {
    transition: 0.3s ease-out;
    transform: rotate(360deg);
  }
}