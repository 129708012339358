@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";

.support_content {
  z-index: 3;
  bottom: 42px;
  margin: auto;
  max-width: 910px;
  position: relative;

  @include responsive-max(991px){
    bottom: 0;
    margin: 68px 0;
  }

  &_main {
    margin: 0 38px;
    padding: 85px 64px;
    background-color: $G-1;

    @include responsive-max(991px){
      padding: 40px 18px;
    }

    & > h3 {
      margin-bottom: 12px;
      @extend .h-03-e-bold;
      text-transform: uppercase;

      @include responsive-max(991px){
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }

    & > p {
      @extend .p-01-regular;

      & > a {
        color: $White;
        text-decoration: underline;
      }
    }
  }
}