@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/vars";

.pagination-query-manage-block {
  @extend %flex-justify-between;

  &_limit-select {
    width: 80px;
  }

  &_arrow_controller {
    display: flex;

    &_prev, &_next {
      width: 40px;
      height: 40px;
      display: flex;
      margin: 0 12px;
      cursor: pointer;
      background: none;
      text-align: center;
      @extend .p-02-light;
      align-items: center;
      border: 1px solid $G-2;
      justify-content: center;
      transition: 0.2s ease-in-out;

      &:hover {
        background-color: $G-1-O;
      }
    }
  }
}