@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.shop-template {
  display: flex;

  @include responsive-max(991px) {
    flex-direction: column;
  }

  .left {
    width: 500px;
    height: 500px;
    display: flex;
    margin-right: 55px;
    align-items: center;

    @include responsive-max(1200px) {
      width: 417px;
      height: 417px;
    }

    @include responsive-max(991px) {
      width: 465px;
      height: 465px;
      margin: 0 auto 40px;
    }

    @include responsive-max(500px) {
      width: 100%;
      height: 90vw;
    }
  }

  .right {
    width: 570px;
    position: relative;

    &_header {
      height: 48px;
      @extend %flex-justify-between;

      @include responsive-max(991px) {
        height: 32px;
      }

      &_share-block {
        position: relative;
        @extend %flex-align-end;

        & > div {
          bottom: 0;
          position: absolute;
        }
      }

      &_transfer-block {
        position: relative;
        @extend %flex-align-end;

        & > span {
          bottom: 0;
          width: 85px;
          cursor: pointer;
          position: absolute;

          @extend .p-02-light;
          transition: 0.3s ease;

          &:hover {
            color: $A2-300;
          }

          &::before {
            background: url("../../../assets/svg/transfer.svg") no-repeat;
            display: inline-block;
            background-size: 23px 23px;
            position: relative;
            width: 23px !important;
            height: 23px !important;
            top: 4px;
            right: 5px;
            content: "";

            @include responsive-max(991px) {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }

    &_rarity {
      gap: 5px;
      height: 22px;
      display: flex;
      min-width: 150px;
      max-width: 400px;
      align-items: center;

      & > svg {
        width: 19px;
        margin-left: 10px;

        @include responsive-max(991px) {
          width: 15px;
          margin-left: 7px;
        }
      }
    }

    @include responsive-max(1200px) {
      width: 465px;
    }

    @include responsive-max(991px) {
      margin: auto;
    }

    @include responsive-max(500px) {
      width: 100%;
      height: 100%;
    }

    h2 {
      margin: 0;
      height: 46px;
      min-width: 200px;
      @extend .h-02-e-bold;

      @include responsive-max(991px) {
        height: 26px;
        min-width: 100px;
      }
    }
  }
}