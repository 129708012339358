@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.inp-block {
  position: relative;

  &_label-block {
    margin-bottom: 10px;
    @extend %flex-end-between;
    text-transform: uppercase;

    &_name {
      color: $White;
      min-width: 100px;
      font-weight: 700;
      @extend .c-u-t-light;
    }

    &_btn {
      color: $G-5-O;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-decoration: underline;

      &:hover {
        color: $G-6-O;
        transition: 0.3s ease-out;
      }
    }

    &_error {
      color: $A2-300;
      text-align: right;
      @extend .c-u-t-light;
    }
  }

  input {
    width: 100%;
    outline: none;
    padding: 0 15px;
    @extend .p-02-light;
    transition: 0.3s ease-out;

    &::placeholder {
      color: $G-6-O;
      @extend .p-02-light;
    }

    &.inp-default {
      color: $G-5-O;
      background: none;
      border: 1px solid $G-2-O;

      &:not(:disabled):hover {
        border: 1px solid $G-6-O;
        color: $G-6-O;
      }

      &:not(:disabled):focus {
        border: 1px solid $G-6-O;
        color: $G-6-O;
      }
    }

    &.inp-large {
      height: 56px;
    }

    &.error {
      color: $A2-300;
      background: $Black;
      border: 1px solid $A2-300;

      &:hover, &:focus {
        border: 1px solid $A2-300;
      }
    }
  }

  input[type="password"] {
    padding-right: 50px;
  }

  input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &_password-icon, &_icon {
    right: 20px;
    width: 24px;
    height: 24px;
    bottom: 15px;
    position: absolute;

    svg {
      width: 24px;
      height: 24px;
      color: $White;
    }
  }
}