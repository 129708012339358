@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.chain-block {
  height: 24px;
  padding: 0 12px;
  background: $G-2;
  @extend %flex-center;

  & > svg {
    width: 18px;
    height: 18px;
  }

  @include responsive-max(991px) {
    height: 16px;
    padding: 8px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}