@import "src/scss/fontSize";
@import "src/scss/color2";

.pagination-manage-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 305px !important;
  justify-content: space-between;

  &.pagination-wrap {
    @media screen and (max-width: 1250px) {
      margin: auto;

      .pagination-manage-block_pagination {
        order: -1;
        display: flex;
        flex: 1 0 100%;
        margin-bottom: 30px;
        justify-content: center;
      }
    }
  }

  &.pagination-unwrap {
    @media screen and (max-width: 550px) {
      margin: auto;

      .pagination-manage-block_pagination {
        order: -1;
        display: flex;
        flex: 1 0 100%;
        margin-bottom: 30px;
        justify-content: center;
      }
    }
  }

  &_limit-select {
    width: 140px;

    div {
      width: 85px;
    }

    .select-container {
      button {

        span {
          display: flex;
        }

        @media screen and (max-width: 400px) {
          height: 32px !important;
        }

        @media screen and (max-width: 400px) {
          height: 25px !important;
        }

        span {
          @media screen and (max-width: 400px) {
            font-size: 10px !important;
          }
        }
      }

      ul {
        @media screen and (max-width: 400px) {
          bottom: 35px !important;
        }

        li {
          @media screen and (max-width: 400px) {
            font-size: 10px !important;
          }
        }
      }
    }
  }

  &_pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }

  &_results {
    color: $G-3;
    @extend .p-02-light;

    span {
      float: right;
    }

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
}