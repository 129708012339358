@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/fontSize";
@import "src/scss/vars";

.shop-voolah {
  width: 100%;
  min-height: 670px;
  @extend %flex-align;

  background: url("../../../../assets/png/voolah-background.png") no-repeat;
  background-size: cover;

  @include responsive-max(991px) {
    background-position: right;
  }

  @include responsive-max(480px) {
    background: url("../../../../assets/png/voolah-background-mobile.png") no-repeat;
    background-size: cover;
  }

  &_tabs {
    &_container {
      margin: 80px 0 45px;

      @include responsive-max(991px) {
        margin: 10px 0 25px;
      }

      &_choice {
        gap: 24px;
        display: flex;
        flex-direction: column;

        &_items {
          &_img {
            width: 100%;
            height: 98px;
            @extend %flex-justify-between;

            @include responsive-max(1200px) {
              height: 80px;
            }

            @include responsive-max(425px) {
              height: 65px;
            }

            @include responsive-max(375px) {
              height: 52px;
            }

            div {
              width: 17.5%;
              height: 100%;
              cursor: pointer;
              @extend %flex-center;
              border: 1px solid $G-4;
              box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 14%, #1b1b1c 100%);

              @include responsive-max(991px) {
                width: 18%;
                height: 100%;
              }

              img {
                width: 98%;
                height: 98%;
              }
            }

            .active {
              border: 2px solid white;
            }
          }
        }
      }

      &_price {
        div {
          font-size: 24px;
          font-weight: 400;
        }
      }

      &_description {
        min-height: 185px;

        @include responsive-max(991px) {
          min-height: 200px;
        }

        p {
          @extend .p-01-regular;
        }
      }
    }
  }

  &_order-summery {
    &_content {
      margin-top: 20px;

      @include responsive-max(991px) {
        margin-top: 25px;
      }

      &_info {
        display: flex;
        margin-bottom: 25px;

        &_img {
          @extend .modal-img;
        }

        &_details {
          @extend %flex-align;

          @include responsive-max(480px) {
            width: calc(100% - 105px);
            justify-content: space-between;
          }

          &_title {
            @extend .modal-item-title;
          }
        }
      }

      &_payments-methods {

        &_head {
          &_quantity {
            @extend .p-02-light;
            margin-bottom: 3px;

            @include responsive-max(991px) {
              font-size: 12px;
              margin-bottom: 1px;
            }

            span:first-child {
              color: $P50;
            }

            span:last-child {
              color: $G-5;
            }
          }
        }

        .payment-method-block {
          background: rgb(0, 0, 0, 0);
        }

        .payment-method-block_left_currency_info {
          margin-top: 12px;

          @include responsive-max(991px) {
            margin-top: 12px;
          }
        }
      }

      &_promo-code {
        margin: 10px 0;

        label {
          font-size: 13px;
        }
      }

      &_total {
        margin-top: 10px;
        @extend %flex-justify-between;

        div {
          color: $P50;
          @extend .n-link-u;
          text-transform: uppercase;
        }
      }

      &_buttons {
        & > button {
          width: 100%;
        }

        margin-top: 25px;
      }

      &_terms {
        color: $G-5-O;
        font-size: 10px;
        font-weight: 400;
        max-width: 512px;
        line-height: 12px;
        text-align: center;
        margin: 24px auto 0;
      }
    }
  }

  &_purchase-successful {
    &_content {
      margin-top: 20px;

      @include responsive-max(991px) {
        margin-top: 25px;
      }

      &_info {
        display: flex;
        margin-bottom: 25px;

        &_img {
          @extend .modal-img;
        }

        &_details {
          @extend %flex-align;

          @include responsive-max(480px) {
            width: calc(100% - 105px);
            justify-content: space-between;
          }

          &_title {
            @extend .modal-item-title;
          }
        }
      }
    }
  }

  .right {
    .title {

      @include responsive-max(991px) {
        display: flex;
        align-items: center;

        svg {
          width: 98px;
          height: 18px;
          margin-left: 3px;
        }
      }
    }
  }
}

.modal-img {
  width: 120px;
  height: 120px;
  margin-right: 35px;
  border: 1px solid $G-4;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 14%, #1b1b1c 100%), url("../../../../assets/png/voolah-background.png") left;

  @include responsive-max(991px) {
    width: 90px;
    height: 90px;
    margin-right: 25px;
  }

  & > img {
    width: inherit;
    height: inherit;
  }
}

.modal-item-title {
  @extend %flex-align;
  text-transform: uppercase;

  img {
    width: 32px;
    height: 32px;

    @include responsive-max(480px) {
      width: 24px;
      height: 24px;
    }
  }

  h5 {
    margin-left: 10px;
    @extend .h-05-e-bold;
  }
}
