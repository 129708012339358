@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.banner {
  &_discord {
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    z-index: 200;
    position: fixed;
    @extend %flex-center;
    background: url("../../../assets/png/lp-download-top-line-background.png");

    &_desktop, &_mobile {
      display: flex;
      align-items: center;

      p, a {
        text-transform: uppercase;
      }

      p {
        @extend .n-link-u
      }

      a {
        color: $White;
        cursor: pointer;
        @extend .l-t-regular;
        text-decoration: underline;
        transition: 0.1s ease-in-out;

        &:hover {
          color: $A2-300;
        }
      }
    }

    &_desktop {
      svg {
        margin: 0 12px;
      }

      @include responsive-max(991px) {
        display: none;
      }
    }

    &_mobile {
      gap: 5px;

      p {
        font-size: 12px;
      }

      @include responsive-min(991px) {
        display: none;
      }
    }
  }
}