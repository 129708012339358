@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.landing-page {
  &_option {
    background: url("../../assets/png/alchemyrefiner_background.png");
    @extend %flex-column-between;
    background-size: 100% 100%;
    padding: 140px 25px 0;
    position: relative;
    height: calc(100vh + 880px);

    @include responsive-max(991px) {
      padding: 40px 25px 0;
    }

    @include responsive-max(480px){
      height: calc(100vh + 400px);
    }

    &_form {
      margin: 0 auto;
      max-width: 975px;
      padding: 50px 100px;
      background-color: $White;

      @include responsive-max(991px) {
        padding: 20px;
      }

      h1, h2, h3 {
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 5px !important;
      }

      h1 {
        color: $A2-300;
        font-size: 40px;
        font-weight: 800;
        line-height: 47px;

        @include responsive-max(991px) {
          font-size: 24px;
          line-height: 40px;
        }
      }

      h2 {
        color: $Black;
        font-size: 40px;
        max-width: 600px;
        font-weight: 700;
        line-height: 45px;

        @include responsive-max(991px) {
          margin: auto;
          font-size: 24px;
          max-width: 300px;
          line-height: 25px;
        }
      }

      h3 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: 47px;

        @include responsive-max(991px) {
          margin: auto;
          font-size: 14px;
          max-width: 275px;
          line-height: 20px;
        }
      }

      .inp-container {
        margin-bottom: 20px;

        @include responsive-max(991px) {
          margin-top: 15px;
        }

        &_label-block_error {
          color: $A2-300;
        }

        input {
          &.default, &.error {
            color: $G-4;
            font-size: 18px;
            border: 1px solid $G-5;
            background-color: $G-6;

            @include responsive-max(991px) {
              font-size: 14px;

              &::placeholder {
                font-size: 14px;
              }
            }

            &::placeholder {
              font-size: 18px !important;

              @include responsive-max(991px) {
                font-size: 14px;
              }
            }
          }
        }
      }

    }
  }

  &_success {
    background: url("../../assets/png/alchemyrefiner_background.png");
    @extend %flex-column-between;
    height: calc(100vh + 880px);
    background-size: 100% 100%;
    padding: 55px 0 0;
    position: relative;

    @include responsive-max(480px){
      height: calc(100vh + 400px);
    }

    &_content {
      h1, h2 {
        @include responsive-max(991px) {
          margin-bottom: 10px !important;
        }
      }

      h1 {
        margin: auto;
        color: $White;
        font-size: 48px;
        max-width: 765px;
        font-weight: 800;
        line-height: 47px;
        font-style: normal;
        text-align: center;
        text-shadow: 0 7px 7px rgba(0, 0, 0, 0.25);

        @include responsive-max(991px) {
          font-size: 40px;
          line-height: 39px;
        }
      }

      h2 {
        margin: auto;
        color: $White;
        font-size: 24px;
        max-width: 665px;
        font-weight: 600;
        line-height: 47px;
        font-style: normal;
        text-align: center;
        text-shadow: 0 7px 7px rgba(0, 0, 0, 0.25);

        @include responsive-max(991px) {
          font-size: 16px;
          line-height: 18px;
        }

        @include responsive-max(480px) {
          max-width: 200px;
        }
      }

      &_video-block {
        width: 60%;
        height: 385px;
        position: relative;
        margin: 10px auto 20px;

        @include responsive-max(991px) {
          width: 100%;
        }

        @include responsive-max(768px) {
          width: 100%;
          height: 50vw;
          margin: 10px 0;
        }
      }

      &_links {
        gap: 15px;
        width: 60%;
        margin: auto;
        display: flex;

        @include responsive-max(768px) {
          gap: 10px;
          width: 100%;
        }

        @include responsive-max(480px) {
          flex-direction: column;
        }

        a {
          margin: auto;
          max-width: 330px;
        }
      }
    }
  }
}
