@import "src/scss/color2";

.gold-underline {
  width: 95px;
  height: 4px;
  background-color: $A2-300;
  transform: skewX(-45deg);
  display: block;

  @media screen and (max-width: 991px) {
    width: 65px;
    height: 2px;
  }
}