@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.create-nickname-modal {
  gap: 24px;
  @extend %flex-column;

  & > *:first-child {
    margin-top: 24px;
  }

  &_rules {
    color: $G-5-O;
    @extend .p-02-light;

    @include responsive-max(991px) {
      font-size: 12px;
    }

    ul {
      padding-left: 16px;

      li:first-child {
        margin-bottom: 8px;
      }
    }
  }
}