@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.bazaar-current-cosmetic-section {
  display: flex;
  min-height: 800px;
  align-items: center;


  &_listed {
    width: 100%;
    padding: 25px;
    margin: 25px 0;
    background: $G-1-O;
    backdrop-filter: blur(2px);

    @include responsive-max(991px) {
      padding: 15px;
    }

    & > button {
      width: 100%;
    }

    &_status {
      & > span:first-child {
        margin-right: 5px;
      }
    }

    button {
      margin-top: 15px;
    }

    &_modal {

      &_content {
        margin: 24px 0;
        @extend %flex-justify-between;

        @include responsive-max(991px) {
          flex-direction: column;
        }

        &_img {
          width: 210px;
          height: 210px;
          background-size: cover;

          @include responsive-max(991px) {
            margin: auto;
            width: 295px;
            height: 295px;
          }

          @include responsive-max(480px) {
            width: 270px;
            height: 270px;
          }

          img {
            width: inherit;
            height: inherit;
          }
        }

        &_inputs {
          width: 58%;

          @include responsive-max(991px) {
            width: 100%;
            margin-top: 25px;
          }

          &_title {
            @extend .h-05-e-bold;
          }

          &_rarity {
            margin-bottom: 15px;
            @extend %flex-align;

            & > span:first-child {
              margin-right: 5px;
            }
          }

          &_price-inp {
            margin-bottom: 8px;
          }
        }

        &_btn {
          & > button {
            width: 100%;
          }

          button:first-child {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  &_remove-modal {
    margin-bottom: 0 !important;

    & > button {
      width: 100%;
    }
  }

  &_content {
    margin: 80px auto 45px;

    @media screen and (max-width: 991px) {
      margin: 85px auto 25px;
    }

    &_countdown {
      margin: 25px 0;
      backdrop-filter: blur(2px);
    }

    .right {
      & > button {
        width: 100%;
      }
    }
  }

  &_description {
    &_title {
      @extend .block-title
    }

    p {
      color: $P50;
      @extend .p-01-regular
    }
  }

  &_price {
    min-width: 115px;
    margin-bottom: 25px;

    &_title {
      @extend .block-title
    }

    &_content {
      @extend %flex-align;

      svg {
        width: 44px;
        height: 44px;
        margin-right: 10px;

        @include responsive-max(991px) {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      span {
        color: $P50;
        height: 36px;
        min-width: 77px;
        @extend .h-04-e-bold;

        @include responsive-max(991px) {
          height: 20px;
          min-width: 36px;
        }
      }
    }
  }

  button {
    margin: 25px 0;
  }
}

.block-title {
  color: $G-4;
  display: block;
  @extend .n-link-u;
  margin-bottom: 6px;
  text-transform: uppercase;

  @include responsive-max(991px) {
    margin-bottom: 12px;
  }
}

.bazaar-transaction-history-section,
.bazaar-price-history-section {
  .custom-accordion {
    margin: 25px 0;
  }
}