@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.ERC20 {
  max-width: 910px;
  margin: 85px auto;

  @include responsive-max(991px) {
    margin: 24px auto;
    padding: 0 24px;
  }

  & > h2 {
    text-align: center;
    margin-bottom: 25px;
    @extend .h-02-e-bold;
    text-transform: uppercase;
  }

  &_content {
    @extend %flex-justify-between;

    @include responsive-max(991px) {
      & > div:not(:last-child) {
        margin-right: 10px;
      }
    }

    @include responsive-max(991px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .ERC20-card {
      z-index: 1;
      width: 420px;
      height: 140px;
      cursor: pointer;
      border: 1px solid;
      position: relative;
      transition: 0.2s ease-in-out;
      @extend %flex-justify-between;
      border-image: linear-gradient(to right, #ffffff7a, #ffffff26) 1;

      @include responsive-max(1200px) {
        width: 100%;
        height: 115px;
        margin: 0 10px;
      }

      &_img {
        right: 0;
        z-index: -1;
        width: 165px;
        height: inherit;
        position: absolute;
        background-size: contain !important;

        &.ethereum {
          background: url("../../../../../assets/png/eth-gray.png") no-repeat right;
        }

        &.base {
          background: url("../../../../../assets/png/base-icon-gray.png") no-repeat right;
        }
      }

      &:hover {
        border-image: linear-gradient(to right, #ff2f37d1, #ff2f374f) 1;
        background-image: radial-gradient(55.67% 86.54% at 8.05% 100%, rgba(176, 44, 44, 0.3) 0%, rgba(176, 44, 44, 0.15) 100%, rgba(176, 44, 44, 0.15) 100%);

        .ERC20-card_content {
          span {
            color: $A2-300-O;
          }
        }
      }

      &_content {
        padding: 22px 0 34px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          margin-bottom: 12px;
          @extend .h-04-e-bold;
          text-transform: uppercase;
        }

        span {
          color: $G-3;
          @extend .n-link-u;
          text-transform: uppercase;

          @include responsive-max(480px) {
            font-size: 10px;
          }
        }
      }
    }
  }
}