@import "src/scss/fontSize";
@import "src/scss/mixin";
@import "src/scss/vars";

.delete {
  .main-page_title {
    max-width: 760px;

    @include responsive-max(991px){
      max-width: 500px;
    }

    @include responsive-max(480px){
      left: 7% !important;
    }

    @include responsive-max(375px){
      top: 20% !important;
    }
  }

  .rights-content , &.delete {
    &_block {
      height: inherit !important;

      h6 {
        padding: 0 !important;
        margin-bottom: 30px;

        @include responsive-max(991px) {
          margin-bottom: 20px;
        }
      }

      .delete {
        &_under-title {
          p {
            @extend .p-01-regular;
            margin-bottom: 0 !important;

            @include responsive-max(991px) {
              line-height: 32px;
            }

            span {
              font-weight: 800;
            }
          }
        }

        &_content {
          margin-top: 30px;

          @include responsive-max(991px) {
            margin-top: 10px;
          }

          h3 {
            margin-bottom: 11px !important;
            text-align: left !important;

            @include responsive-max(991px) {
              font-size: 16px !important;
              line-height: 20px !important;
            }
          }
        }
      }
    }
  }
}

