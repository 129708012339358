@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.profile {
  @extend %flex-justify-center;

  &_info-block {
    height: 55px;
    padding: 16px;
    color: $G-5-O;
    background: $Black;
    @extend .p-02-light;
    border: 1px solid $G-2-O;

    @include responsive-max(991px) {
      height: 48px;
      padding: 13px;
    }
  }

  &_info-header {
    margin-bottom: 12px;
    @extend %flex-justify-between-center;

    @include responsive-max(991px) {
      min-height: 20px;
      margin-bottom: 8px;
    }

    h6 {
      @extend .h-06-bold;
    }

    &_process-info {
      @extend .p-02-light;

      @include responsive-max(991px) {
        font-size: 12px;
        line-height: 20px;
      }

      &.default {
        color: $G-3;
      }

      &.error {
        color: $A2-300;
      }

      &.success {
        color: $A1-300;
      }
    }
  }

  &_container {
    width: 890px;
    padding: 65px;
    max-width: 890px;
    background: $G-1-O;
    margin: 123px 12px 0;
    border: 1px solid $G-1-O;

    @include responsive-max(991px) {
      max-width: 600px;
      padding: 45px 15px;
    }

    h1 {
      @extend .h-01-e-bold;
      text-align: center;
      text-transform: uppercase;
    }

    &_info {

      &_avatar {
        text-align: center;
        margin-bottom: 65px;

        @include responsive-max(991px) {
          margin-bottom: 25px;
        }

        &_img {
          width: 175px;
          height: 175px;
          text-align: center;
          margin: 40px auto 25px;

          img {
            width: 175px;
            height: 175px;

            @include responsive-max(991px) {
              width: 115px;
              height: 115px;
            }
          }

          @include responsive-max(991px) {
            width: 115px;
            height: 115px;
            margin: 25px auto 25px;
          }
        }

        &_name {
          color: $P50;
          height: 35px;
          margin: 0 auto 0;
          min-width: 200px;
          @extend .h-04-e-bold;
          text-transform: uppercase;

          @include responsive-max(991px) {
            width: 129px;
            height: 19px;
            margin: 15px auto 0;
          }
        }
      }

      &_email, &_password, &_wallet, &_nickname, &_langauge {
        margin-bottom: 48px;

        @include responsive-max(991px) {
          margin-bottom: 24px;
        }
      }

      &_wallet {
        &_note {
          color: $G-5-O;
          margin: 10px 0;
          @extend .p-02-light;

          @include responsive-max(480px) {
            font-size: 10px;
          }
        }
      }

      &_nickname {
        .inp-container_icon {
          width: 18px;
          height: 21px;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        &_list {
          margin: 10px 0;
          padding-left: 17px;

          li {
            color: $G-5-O;
            @extend .p-02-light;

            @include responsive-max(480px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .btn {
      width: 100%;
    }
  }

  .select_container {
    &_language-option {
      width: 300px;
      @extend %flex-align;

      svg {
        border-radius: 20%;
      }

      span {
        color: $White;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}