@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";

@mixin button-text-style($font-size) {
  padding: 5px;
  font-size: $font-size;
  font-weight: 800;
  line-height: 32px;
}

.btn {
  //width: 100%;
  height: 48px;
  outline: none;
  padding: 8px 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-transform: uppercase;

  &-disabled {
    color: $G-4;
    cursor: not-allowed;
    background-color: $G-1;
  }

  &-default {
    color: $White;
    background-color: $G-2-O;
    border: 3px solid rgba(0, 0, 0, 0);

    &:not(.btn-disabled):hover {
      border-bottom: 3px solid $A2-300-O;
    }

    &.btn-disabled {
      cursor: not-allowed;
      color: $G-4 !important;
      background-color: $G-1 !important;
    }
  }

  &-loading {
    cursor: none;
    background-color: $G-1;
    display: flex;
    justify-content: center;

    .lds-block {
      top: -15px;
      width: inherit;
      position: absolute;
    }

    .lds-default {
      transform: scale(0.5);
    }
  }

  &-red {
    color: $White;
    background: $GR-Red;

    &:hover {
      background: $A2-300;
    }

    &:active {
      background: $A2-500;
    }
  }

  &-medium {
    @include button-text-style(14px)
  }

  &-large {
    @include button-text-style(18px);

    @include responsive-max(991px) {
      font-size: 14px;
    }
  }
}