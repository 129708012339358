@import "src/scss/color2";
@import "src/scss/fontSize";
@import "src/scss/mixin";

.checkbox-form-control {
  display: grid;
  grid-template-columns: 2.5em auto;
  cursor: pointer;
  margin-bottom: 10px;

  &.text-none {
    grid-template-columns: 0.5em auto;
  }

  &.large {
    @extend .p-02-light;
    color: $P50;

    @include responsive-max(991px){
      font-size: 10px !important;
      font-weight: 400 !important;
      line-height: 20px !important;

      span {
        margin-left: 5px;
      }
    }
  }

  &.medium {
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    color: $P50;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    cursor: pointer;

    &:checked::before {
      transform: scale(1);
    }

    &::before {
      content: "";
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
    }

    &.large {
      width: 24px;
      height: 24px;

      &::before {
        width: 14px;
        height: 13px;
        clip-path: polygon(4% 40%, 0 55%, 41% 90%, 103% 16%, 95% 0%, 45% 65%);
      }
    }

    &.medium {
      width: 20px;
      height: 20px;

      &::before {
        width: 12px;
        height: 11px;
        clip-path: polygon(4% 40%, 0 55%, 41% 90%, 103% 16%, 95% 0%, 45% 65%);
      }
    }

    &.small {
      width: 16px;
      height: 16px;

      &::before {
        width: 8px;
        height: 7px;
        clip-path: polygon(4% 40%, 0 55%, 41% 90%, 103% 16%, 95% 0%, 45% 65%);
      }
    }

    &.green {
      border: 1px solid $G-2-O;

      &::before {
        background-color: $White;
      }

      &:checked {
        background-color: $A1-500;
      }
    }

    &.white {
      border: 1px solid $G-2-O;

      &::before {
        background-color: $Black;
      }

      &:checked {
        background-color: $White;
      }
    }

    &.circle {
      border-radius: 15px;
    }
  }
}