@import "src/scss/color2";
@import "src/scss/color";
@import "src/scss/fontSize";

.select-container {
  justify-content: center;

  &_label-block {
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
    justify-content: space-between;

    &_name {
      @extend .c-u-t-light;
      color: $G-4;
      min-width: 100px;
    }

    &_btn {
      @extend .l-t-regular;
      color: $P50;
      border: none;
      outline: none;
      cursor: pointer;
      background: none;
      text-decoration: underline;

      &:hover {
        transition: 0.2s ease-out;
        color: $A2-300;
      }
    }

    &_error {
      text-align: right;
      @extend .c-u-t-light;

      &.error {
        color: $A2-300-O;
      }

      &.success {
        color: $A1-300-O;
      }

      &.warning {
        color: $P300-O;
      }
    }
  }

  &_body {
    width: 100%;
    margin-bottom: 5px;
    position: relative;

    button {
      border: 0;
      color: $G-3;
      width: 100%;
      display: flex;
      padding: 0 15px;
      font-weight: 300;
      background: $Black;
      @extend .p-02-light;
      align-items: center;
      transition: all 0.3s ease-out;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
      }

      span {
        color: $White;
        svg {
          transition: 0.3s ease-out;
        }
      }

      &.expanded {
        span:last-child {
          svg {
            transition: 0.3s ease-out;
            transform: rotate(180deg);
          }
        }
      }
    }

    &_options {
      width: 100%;
      z-index: 20;
      display: none;
      padding: 4px 0;
      list-style: none;
      font-weight: 300;
      max-height: 280px;
      position: absolute;
      overflow-y: scroll;

      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $A2-300;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $A2-200;
      }

      li {
        padding: 6px 10px;
        @extend .p-02-light;

        &:active, &:focus, &:hover, &[aria-selected="true"] {
          cursor: pointer;
          background: #252525;
        }
      }

      &.show {
        display: block;
      }
    }

    &.default {
      border: 1px solid $G-2-O;

      &:hover {
        border-color: $G-3-O;
      }

      .set-data {
        color: $White
      }

      .select-container_body_options {
        border: 1px solid $White;
        background: $Black !important;
      }

      &.expanded-cont {
        transition: 0.5s ease;
        border: 1px solid $White !important;
      }
    }

    &.error {
      border: 1px solid $A2-200-O;

      button {
        &:hover {
          background: $A2-75-O;
        }
      }

      .set-data {
        color: $White
      }

      .select-container_body_options {
        border: 1px solid $A2-200-O;
        background: $Black !important;
      }

      &.expanded-cont {
        transition: 0.5s ease;
        border: 1px solid $A2-300-O !important;

        button {
          background: $A2-75-O;
        }
      }
    }

    &.success {
      border: 1px solid $A1-300-O;

      button {
        &:hover {
          background: $A1-75-O;
        }
      }

      .set-data {
        color: $White
      }

      .select-container_body_options {
        border: 1px solid $A1-300-O;
        background: $Black !important;
      }

      &.expanded-cont {
        transition: 0.5s ease;
        border: 1px solid $A1-300-O !important;

        button {
          background: $A1-75-O;
        }
      }
    }

    &.warning {
      border: 1px solid $P300-O;

      button {
        &:hover {
          background: $P75-O;
        }
      }

      .set-data {
        color: $White
      }

      .select-container_body_options {
        border: 1px solid $P300-O;
        background: $Black !important;
      }

      &.expanded-cont {
        transition: 0.5s ease;
        border: 1px solid $P300-O !important;

        button {
          background: $P75-O;
        }
      }
    }

    &.lg {
      button {
        height: 56px;

        @media screen and (max-width: 991px) {
          height: 48px;
        }
      }

      .select-container_body_options {
        &.bottom {
          top: 60px;

          @media screen and (max-width: 991px) {
            top: 52px;
          }
        }

        &.top {
          bottom: 60px;

          @media screen and (max-width: 991px) {
            bottom: 52px;
          }
        }
      }
    }

    &.md {
      button {
        height: 48px;
      }

      .select-container_body_options {
        &.bottom {
          top: 52px;
        }

        &.top {
          bottom: 52px;
        }
      }
    }

    &.sm {
      button {
        height: 40px;
      }

      .select-container_body_options {
        &.bottom {
          top: 44px;
        }

        &.top {
          bottom: 44px;
        }
      }

      li {
        line-height: 15px;
      }
    }
  }
}


