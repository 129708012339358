@import "src/scss/color";
@import "src/scss/mixin";

@keyframes cartShow {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.skeleton-nft {
  transition: 1s ease-in-out;
  animation: cartShow 1s ease-in-out alternate;

  &-cart {
    &_img-block {
      width: 100%;
      position: relative;

      &.default {
        padding-top: 100%;
      }

      &.owned {
        padding-top: 125%;
      }

      &.listed {
        padding-top: 150%;
      }
    }

    &_content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}