@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.footer_follow-us {
  max-width: 1115px;
  @extend %flex-center;
  margin: 0 auto 130px;
  padding: 105px 0 40px;
  border-bottom: 1px solid $A2-200-O;

  @include responsive-max(991px) {
    max-width: 295px;
    padding: 75px 0 0;
    margin: 0 auto 50px;
  }

  &_block {
    max-width: 590px;
    width: 590px;

    @include responsive-max(991px) {
      max-width: 290px;
      width: 290px;
    }

    h3 {
      @extend .h-02-e-bold;
    }

    &_socials {
      margin: 40px 0;
      @extend %flex-justify-between-center;

      @include responsive-max(991px) {
        margin: 20px 0 60px;
      }
    }
  }
}