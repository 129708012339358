.transaction-history {
  margin: 25px 0;

  .kompete-pagination li {
    margin: 0 3px;
  }

  .pagination-manage-block {
    min-width: 275px !important;
  }
}