@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.locker {
  &_cosmetics {
    margin: 150px auto 55px;

    @include responsive-max(991px) {
      margin: 85px auto;
      padding: 0 24px;
    }

    &_tabs {
      width: 100%;

      .tab-block-display {
        @include responsive-max(991px) {
          display: none;
        }
      }

      .tab-block-mobile {
        display: none;

        @include responsive-max(991px) {
          display: block;
        }
      }
    }

    &_content {
      margin: auto;
      display: flex;
      min-height: 500px;
      max-width: 1440px;

      &.content-none {
        align-items: center;
        justify-content: center;
      }

      &_general {
        width: 100%;

        @include responsive-max(991px) {
          padding: 15px 0 0;
        }

        .TabContent {
          min-height: inherit;
        }

        &_owned {
          max-width: 1115px;
          margin: auto;

          @include responsive-min(991px) {
            padding: 0 24px;
          }
        }

        &_listed {
          display: flex;
          justify-content: space-between;

          &_items-container {
            width: 100%;
            min-height: 500px;
            padding: 30px 40px 0;

            @include responsive-max(1200px) {
              padding: 30px 25px 0;
            }

            @include responsive-max(991px) {
              padding: 0;
            }

            &_filtoration-tools {
              @extend %flex-justify-between-center;

              @include responsive-max(991px) {
                gap: 15px;
                padding: 0;
                flex-direction: column;

                .refresh-tool,
                .select-container {
                  display: none;
                }
              }

              .inp-container {
                width: 72%;
                margin: 0 8px;

                @include responsive-max(991px) {
                  margin: 0;
                  width: 100%;
                }
              }

              .select-container {
                margin-top: 5px;
                min-width: 250px;
              }

              .refresh-skeleton,
              .select-skeleton {
                @include responsive-max(991px) {
                  display: none;
                }
              }

              .refresh-skeleton {
                width: 25px;
                height: 25px;
              }

              .input-skeleton {
                width: 72%;
                height: 56px;
                margin: 0 8px;

                @include responsive-max(991px) {
                  width: 100%;
                  height: 48px;
                  margin: 0 0 15px;
                }
              }

              .select-skeleton {
                width: 250px;
                height: 56px;
              }

              &_mobile {
                gap: 15px;
                width: 100%;
                @extend %flex-justify-between;

                @include responsive-min(991px) {
                  display: none;
                }

                .filter-mobile-skeleton,
                .select-mobile-skeleton {
                  width: 50%;
                  height: 40px;
                }

                & > div {
                  width: 50%;
                }

                & > button {
                  width: 50%;
                }
              }
            }
          }
        }

        &_konvert {
          margin: auto;
          max-width: 1030px;
          padding-top: 20px;
          min-height: 850px;

          @include responsive-max(991px) {
            padding-top: 20px;
          }

          &_eligibility {
            padding: 0 20px;
            min-height: 230px;

            @include responsive-max(991px) {
              padding: 0;
            }

            h2 {
              margin-bottom: 5px;
              @extend .h-02-e-bold;
              text-transform: uppercase;

              @include responsive-max(991px) {
                margin-bottom: 15px;
                text-align: center;
              }
            }

            & > p {
              min-height: 32px;
              max-width: 560px;
              @extend .h-05-e-bold;
              text-transform: uppercase;

              &.eligibility {
                color: $A1-300;
              }

              &.no-eligibility {
                color: $A2-300;
              }

              @include responsive-max(991px) {
                margin: auto;
                min-height: 20px;
                min-width: 300px;
                line-height: 20px;
                text-align: center;
              }
            }

            &_items {
              width: 100%;
              margin: 5px 0 35px;

              @include responsive-max(991px) {
                margin: 10px 0 50px;
              }

              &_item {
                width: 100%;
                height: 45px;
                margin-top: 5px;
                border: 1px solid $P50;
                padding: 10px 40px 10px 10px;
                @extend %flex-justify-between-center;

                &.skeleton {
                  border: none;
                }

                @include responsive-max(991px) {
                  padding: 10px 20px 10px 10px;
                }

                &.display-hidden {
                  & > div {
                    visibility: hidden !important;
                  }
                }

                & > div:first-child {
                  @extend .p-01-regular;
                }

                & > div:last-child {
                  @extend %flex-align;
                }
              }
            }
          }

          &_selector {
            position: relative;
            min-height: 600px;

            @include responsive-max(991px) {
              min-height: 500px;
            }

            &_loading-container {
              gap: 8px;
              display: grid;
              padding: 0 30px;
              margin: 188px 0 40px;
              @include grid-display(2);

              @include responsive-max(991px) {
                padding: 0;
                margin: 150px 0 40px;
              }

              @include responsive-max(768px) {
                gap: 4px;
                @include grid-display(1);
              }
            }

            .lds-default {
              display: flex;
              margin: 200px auto 0 !important;
            }

            h2 {
              padding: 0 20px;
              max-width: 1030px;
              @extend .h-02-e-bold;
              text-transform: uppercase;

              @include responsive-max(991px) {
                text-align: center;
                margin-bottom: 5px;
              }
            }

            & > p {
              padding: 0 20px;
              @extend .h-06-bold;
              text-transform: uppercase;

              @include responsive-max(991px) {
                margin: auto;
                font-size: 16px;
                max-width: 310px;
                line-height: 20px;
                text-align: center;
              }
            }

            &_items {
              padding: 35px 0;

              @include responsive-max(991px) {
                padding: 30px 0;
              }

              &_head {
                display: flex;
                padding: 0 60px;

                @include responsive-max(991px) {
                  padding: 0;
                }

                label {
                  max-width: 300px;
                  margin-right: 10px;
                }

                .custom-text {
                  @extend %flex-align;

                  & > div {
                    margin-left: 15px;
                    @extend .p-01-regular;

                    @include responsive-max(991px) {
                      margin-left: 5px;
                      font-size: 13px;
                    }
                  }
                }

                & > div:last-child {
                  color: $G-3;
                  margin-left: 20px;
                  @extend .p-01-regular;

                  @include responsive-max(991px) {
                    margin-left: 5px;
                    font-size: 13px;
                  }
                }

              }

              &_container {
                gap: 8px;
                display: grid;
                padding: 0 30px;
                margin: 15px 0 40px;
                @include grid-display(2);

                @include responsive-max(991px) {
                  padding: 0;
                }

                @include responsive-max(768px) {
                  gap: 4px;
                  @include grid-display(1);
                }

                &_item {
                  width: 100%;
                  height: 105px;
                  cursor: pointer;
                  padding: 5px 16px;
                  background: $G-1-O;
                  border: 1px solid $G-6;
                  transition: 0.2s ease-in-out;
                  @extend %flex-justify-between-center;

                  &_blocked {
                    margin-right: 10px;
                    display: flex;
                    align-items: center;

                    svg {
                      width: 20px;
                    }
                  }

                  &.skeleton {
                    border: none;

                    &:hover {
                      border: none;
                    }
                  }

                  &:hover {
                    border: 1.5px solid $G-3;
                  }

                  @include responsive-max(991px) {
                    height: 75px;
                  }

                  &_info {
                    @extend %flex-align;

                    & > div:first-child {
                      img {
                        width: 90px;
                        height: 90px;

                        @include responsive-max(991px) {
                          width: 60px;
                          height: 60px;
                        }
                      }
                    }

                    & > div:last-child {
                      margin-left: 20px;

                      .title {
                        @extend .p-01-regular;
                        line-height: 16px;
                        font-weight: 700;
                      }

                      .group {
                        color: $G-3;
                        display: block;
                        font-size: 10px;
                        font-weight: 400;
                        margin: 4px 0 1px;
                      }

                      span {
                        color: $Black;
                      }
                    }
                  }

                  &_checkbox {
                    @extend %flex-align;
                    padding-right: 16px;

                    label {
                      margin-bottom: 0 !important;
                    }

                    &_quantity {
                      width: 40px;
                      padding: 1px;
                      height: 40px;
                      color: $Black;
                      @extend .n-link-u;
                      background: $White;
                      margin-right: 15px;
                      border-radius: 8px;
                      @extend %flex-center;
                      border: 1px solid #00000059;

                      @include responsive-max(991px) {
                        width: 35px;
                        height: 35px;
                      }

                      span {
                        display: block;

                      }
                    }
                  }
                }
              }

              &_btn {
                padding: 0 40px;

                .buttonV3 {
                  width: 100%;
                  padding: 0 40px;
                  max-width: 520px;
                  margin: 45px auto 0;
                }
              }
            }

            &_blocked {
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              padding-top: 65px;
              position: absolute;
              margin-left: -15px;
              margin-right: -15px;
              backdrop-filter: blur(5.699999809265137px);
              background: var(--background-backgraund-o, rgba(12, 13, 23, 0.90));

              @include responsive-max(991px) {
                padding-top: 40px;
              }
            }
          }
        }

        &_activity {
          padding: 24px 40px 0;

          @include responsive-max(991px) {
            padding: 0 0 40px !important;
          }
        }
      }
    }
  }
}