@import "src/scss/color2";
@import "src/scss/fontSize";

.dropdownItem {
  justify-content: space-between;
  display: flex;
  height: auto;
  padding: 20px;

  &:first-child {
    border-top: 1px solid $G-2-O;
  }

  .content {
    width: 100%;
  }

  border-bottom: 1px solid $G-2-O;
  transition: all 0.2s ease-out;

  &.active {
    border-bottom: 1px solid $A2-300;

    .more {
      background-color: $P50;

      span {
        color: $G-1;
      }
    }
  }

  &_title {
    min-height: 45px;
    display: flex;
    align-items: center;

    &.active {
      h3 {
        color: $A2-300;
      }
    }

    h3 {
      @extend .p-01-regular;
      transition: all 0.2s ease-out;
    }
  }

  &_content {
    @extend .p-01-regular;
    transition: all 0.2s ease-out;
    height: 0;
    margin-top: 0;
    overflow: hidden;

    &.active {
      margin-top: 10px;
      height: auto;
    }
  }


  .more {
    height: 35px;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%, 0% 20%);
    width: 35px;
    margin: 5px;
    background-color: $G-6;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 24px;
      color: $G-1;
      margin: 0;
      user-select: none;
    }
  }
}