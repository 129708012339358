@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.cosmetic-modal-template {
  &_title {
    @extend .modal-title
  }

  &_content {
    @extend .modal-content-margin;

    &_info {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      &_img {
        @extend .modal-img;
        background-color: red;
      }

      &_name {
        width: 74%;
        @extend %flex-justify-between-center;

        @include responsive-max(480px) {
          width: calc(100% - 105px);
          justify-content: space-between;
        }

        & > div:first-child {
          margin-right: 40px;

          @include responsive-max(480px) {
            margin-right: 0;
          }

          h6 {
            @extend .h-06-bold;
            margin-bottom: 8px;
            text-transform: uppercase;

            @include responsive-max(480px) {
              font-size: 12px;
              margin-bottom: 0;
              line-height: 16px;
            }
          }
        }

        & > div:last-child {
          padding-right: 20px;

          p {
            @extend .h-06-bold;
          }
        }
      }
    }


    &_btns {
      display: flex;
      gap: 25px;

      & > button {
        width: 100%;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    &_main {
      margin: 25px 0;
    }
  }
}

.modal-title {
  text-align: center;
  @extend .h-06-bold;
  text-transform: uppercase;
}

.modal-content-margin {
  margin-top: 20px;

  @media screen and (max-width: 991px) {
    margin-top: 25px;
  }
}

.modal-img {
  width: 120px;
  height: 120px;
  margin-right: 25px;

  @media screen and (max-width: 991px) {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }

  & > img {
    width: inherit;
    height: inherit;
  }
}