@import "src/scss/fontSize";
@import "src/scss/color2";

.no-cosmetics {
  width: 100%;
  display: flex;
  min-height: 545px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    min-height: 345px;
  }

  &_content {
    text-align: center;

    img {
      width: 184px;
      height: 184px;

      @media screen and(max-width: 991px) {
        width: 100px;
        height: 100px;
      }
    }

    p {
      color: $G-3;
      margin-top: 40px;
      @extend .p-01-regular;

      @media screen and (max-width: 991px) {
        margin-top: 25px;
      }
    }
  }
}