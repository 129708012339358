@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.footer-modernize {
  @extend %flex-center;

  &_block {
    text-align: center;
    margin-bottom: 60px;

    &_logo {
      img {
        width: 170px;
        height: 48px;

        @include responsive-max(991px) {
          width: 125px;
          height: 35px;
        }
      }
    }

    &_reserved {
      max-width: 300px;
      @extend .p-03-regular;
      margin: 25px auto 35px;

      p {
        color: $G-3-O !important;
      }

      @include responsive-max(991px) {
        margin: 15px auto 25px;
      }
    }

    &_term-links {
      ul {
        padding: 0;
        margin: auto;
        list-style-type: none;
        @extend %flex-justify-center;

        li {
          padding: 0 10px;
          @extend .p-04-bold;
          text-transform: uppercase;

          @include responsive-max(991px) {
            font-size: 10px;
            font-weight: 300;
            letter-spacing: 1px;
          }

          a {
            color: $G-5;

            &:hover {
              color: $White;
            }
          }
        }
      }
    }
  }
}