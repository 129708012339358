@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.bazaar {
  &_cosmetics {
    margin-top: 150px;

    @include responsive-max(991px) {
      margin-top: 75px;
    }

    &_tabs {
      width: 100%;

      @include responsive-max(991px){
        padding: 0 24px;
      }

      .tab-block-display {
        @include responsive-max(991px) {
          display: none;
        }
      }

      .tab-block-mobile {
        display: none;

        @include responsive-max(991px) {
          display: block;
        }
      }
    }

    &_content {
      display: flex;
      min-height: 500px;

      &.content-none {
        align-items: center;
        justify-content: center;
      }

      &_general {
        width: 100%;

        @include responsive-max(991px) {
          padding: 15px 0 0;
        }

        .TabContent {
          min-height: inherit;
        }

        &_listed {
          margin: auto;
          display: flex;
          max-width: 1440px;
          justify-content: space-between;

          &_items-container {
            width: 100%;
            min-height: 500px;
            padding: 30px 40px 0;

            @include responsive-max(1200px) {
              padding: 30px 25px 0;
            }

            @include responsive-max(991px) {
              padding: 18px 24px 0;
            }

            &_filtoration-tools {
              @extend %flex-justify-between-center;

              @include responsive-max(991px) {
                gap: 15px;
                padding: 0;
                flex-direction: column;

                .refresh-tool,
                .select-container {
                  display: none;
                }
              }

              .inp-container {
                width: 72%;
                margin: 0 8px;

                @include responsive-max(991px) {
                  margin: 0;
                  width: 100%;
                }
              }

              .select-container {
                margin-top: 5px;
                min-width: 250px;
              }

              .refresh-skeleton,
              .select-skeleton {
                @include responsive-max(991px) {
                  display: none;
                }
              }

              .refresh-skeleton {
                width: 25px;
                height: 25px;
              }

              .input-skeleton {
                width: 72%;
                height: 56px;
                margin: 0 8px;

                @include responsive-max(991px) {
                  width: 100%;
                  height: 48px;
                  margin: 0 0 15px;
                }
              }

              .select-skeleton {
                width: 250px;
                height: 56px;
              }

              &_mobile {
                gap: 15px;
                width: 100%;
                @extend %flex-justify-between;

                @include responsive-min(991px) {
                  display: none;
                }

                .filter-mobile-skeleton,
                .select-mobile-skeleton {
                  width: 50%;
                  height: 40px;
                }

                & > div {
                  width: 50%;
                }

                & > button {
                  width: 50%;
                }
              }
            }
          }
        }

        &_activity {
          padding: 24px 40px 0;

          @include responsive-max(991px) {
            padding: 0 24px 40px !important;
          }
        }
      }
    }
  }
}