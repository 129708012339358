@import "src/scss/color2";
@import "src/scss/fontSize";

.status {
  min-height: 20px;
  padding: 5px 12px;
  @extend .p-04-bold;
  text-transform: uppercase;

  &.success {
    background: $A1-75-O;
    color: $A1-300;
  }

  &.reject {
    background: $A2-75-O;
    color: $A2-300;
    span{
      color: $P50;
    }
  }

  &.none {
    background: none;
    color: $P50;
    opacity: 10;
  }
}