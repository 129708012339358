@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.process-modal {
  gap: 24px;
  text-align: center;
  @extend %flex-column-align;

  & > div {
    margin: 20px;
  }

  &_process-text {
    @extend .p-01-regular;
  }

  &_info-text {
    color: $G-5-O;
    @extend .p-02-light;

    @include responsive-max(991px) {
      font-size: 12px;
    }
  }
}