@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.protected-container {
  width: 100%;
  margin-top: 80px;
  min-height: 580px;
  @extend %flex-justify-center;
  @include background-cover-center("../../../assets/png/background-protected.png");

  @include responsive-max(991px) {
    margin-top: 60px;
    min-height: 365px;

    @include responsive-min-height(880px) {
      min-height: calc(100vh - 60px - 452px);
    }
  }

  @include responsive-min-height(1300px) {
    min-height: calc(100vh - 80px - 630px);
  }

  &_sign-in {
    width: 490px;
    height: 408px;
    margin: 85px 24px 0;
    padding: 60px 48px;
    text-align: center;
    border: 1px solid $G-1-O;
    backdrop-filter: blur(25px);
    background: var(--tanf, radial-gradient(160.94% 168.2% at 100% -22.38%, rgba(0, 0, 0, 0.75) 11.58%, rgba(11, 11, 11, 0.75) 22.91%, rgba(32, 31, 31, 0.75) 39.26%, rgba(0, 0, 0, 0.75) 70.36%), #000);

    @include responsive-max(991px) {
      margin-top: 40px;
      padding: 40px 24px;
    }

    @include responsive-max(991px) {
      width: 330px;
      height: 285px;
    }

    @include responsive-max(375px) {
      width: 100%;
    }

    &_img {
      margin: auto;
      width: 144px;
      height: 144px;
      background: $G-2;
      border-radius: 50%;
      @extend %flex-center;
      border: 1px solid $G-3;

      @include responsive-max(991px) {
        width: 80px;
        height: 80px;

        & > svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    &_text {
      color: $G-5-O;
      margin: 24px 0 35px;
      @extend .p-01-regular;

      @include responsive-max(991px) {
        margin: 24px 0;
      }

      @include responsive-max(400px) {
        padding: 0 5%;
      }
    }

    button {
      width: 100%;
    }
  }

  &_kompete-token-container {
    margin: 165px auto 100px;

    @include responsive-max(991px) {
      margin: 120px auto 50px;
    }

    &_modal {
      width: 750px;
      max-height: 635px;
      text-align: center;
      border: 1px solid #573A3A;
      backdrop-filter: blur(25px);
      @extend %flex-column-between;
      background: rgba(0, 0, 0, 0.5);

      @include responsive-max(991px) {
        width: 325px;
        max-height: 575px;
      }

      &_img {
        img {
          width: 145px;
          height: 145px;

          @include responsive-max(991px) {
            width: 80px;
            height: 80px;
          }
        }
      }

      &_text {
        @extend .p-01-regular;
        margin: 25px 0 35px;
        padding: 0 18%;

        @include responsive-max(991px) {
          margin: 25px 0;
          padding: 0;
        }
      }

      &_balance-img {
        width: 100%;
        border-top: 1px solid $G-2;
        padding-top: 35px;
        display: flex;

        @include responsive-max(991px) {
          flex-direction: column;
          padding: 25px;

          & > div:first-child {
            margin-bottom: 25px;
          }
        }

        img {
          width: 175px;
          height: 175px;
          margin-right: 40px;

          @include responsive-max(991px) {
            width: 100px;
            height: 100px;
            margin-right: 0;
          }
        }

        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;

          @include responsive-max(991px) {
            flex-direction: column;
          }

          button {
            width: 200px;

            &:first-child {
              margin-right: 40px;

              @include responsive-max(991px) {
                margin: 0 0 25px 0;
              }
            }
          }
        }
      }
    }

    &_btns-block {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      @include responsive-max(991px) {
        flex-direction: column;
      }

      button {
        width: 300px;

        @include responsive-max(991px) {
          width: 315px;
        }

        &:first-child {
          margin-right: 40px;

          @include responsive-max(991px) {
            margin: 0 0 25px 0;
          }
        }
      }
    }
  }
}
