@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.sign-in-modal {
  gap: 24px;
  padding-top: 24px;
  @extend %flex-column;

  &_social-auth {
    padding: 0 30px;
    @extend %flex-justify-around;

    @include responsive-max(480px) {
      padding: 0 10px;
      justify-content: space-between;
    }

    & > button {
      cursor: pointer;

      &.twitter {
        color: $White;
      }

      &.facebook {
        color: #1877F2;
      }

      &.apple {
        color: $White;
      }

      svg {
        width: 40px;
        height: 40px;

        @include responsive-max(480px) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &_email-auth {
    gap: 24px;
    @extend %flex-column;

    &_or-line {
      height: 32px;
      @extend %flex-align-between;

      @include responsive-max(991px) {
        height: 12px;
      }

      span {
        display: block;
        @extend .p-01-regular;

        &:first-child, &:last-child {
          width: 45%;
          border-bottom: 1px solid $White;
        }
      }
    }
  }

  &_create-account {
    & > p {
      color: $G-5-O;
      margin-bottom: 6px;
      text-align: center;
      @extend .p-02-light;
    }

    &_btn {
      width: 100%;
      height: 48px;
      cursor: pointer;
      @extend %flex-align;
      transition: 0.3s ease-in-out;

      &:hover{
        background: $G-2;
      }

      &_img {
        width: 48px;
        height: 48px;
        background-size: cover;
        background: url("../../../../assets/png/K_LOGO.png") no-repeat center center;
      }

      &_text {
        height: 48px;
        padding-left:12px;
        border: 1px solid $G-2;
        border-left: none;
        width: calc(100% - 48px);
        @extend %flex-justify-between-center;

        span {
          color: $P50;
          @extend .h-05-e-bold;
          text-transform: uppercase;

          @include responsive-max(991px){
            font-size: 12px;
            line-height: 19px;
          }
        }

        svg{
          width: 18px;
          height: 18px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}