@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.page-not-found-container {
  margin-top: 100px;
  @extend %flex-center;

  @include responsive-min(666px) {
    height: calc(100vh - 280px);
  }

  &_block {
    padding: 10px;
    max-width: 520px;
    text-align: center;

    @include responsive-max(991px) {
      max-width: 280px;
      padding: 5px;
    }

    &_img {
      img {
        width: 255px;
        height: 255px;

        @include responsive-max(991px) {
          width: 135px;
          height: 135px;
        }
      }
    }

    &_title {
      @extend .h-02-e-bold;
      margin-top: 25px;

      @include responsive-max(991px) {
        text-transform: uppercase;
      }
    }

    &_text {
      @extend .p-01-regular;
      color: $G-4;
      margin: 20px 0 50px;

      @include responsive-max(991px) {
        margin: 5px 0 15px;
      }
    }

    button {
      margin: auto;
      padding: 0 55px;

      @include responsive-max(991px) {
        padding: 0 29px;
        height: 32px !important;
        font-size: 9px !important;
      }
    }
  }
}