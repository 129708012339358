@import "src/scss/fontSize";
@import "src/scss/color2";

.row {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  &_img {
    width: 49%;

    @media screen and (max-width: 991px) {
      width: 100%;
      min-height: 220px;
    }

    img {
      width: 100%;
    }
  }

  &_content {
    width: 49%;
    padding-top: 45px;

    @media screen and (max-width: 991px) {
      padding-top: 15px;
    }

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    h4 {
      margin-bottom: 30px;
      @extend .h-04-e-bold;
      text-transform: uppercase;

      @media screen and (max-width: 991px) {
        margin-bottom: 15px;
      }
    }
  }

  &.right {
    flex-direction: row-reverse;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
}