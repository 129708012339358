@import "src/scss/color2";

.switch {
  width: 45px;
  height: 25px;
  position: relative;
  display: inline-block;

  &.disabled{
    opacity: 0.4;
  }


  &_input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &_slider {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: .4s;
    position: absolute;
    background-color: $G-4;
    -webkit-transition: .4s;

    &:before {
      content: "";
      width: 20px;
      right: 22px;
      bottom: 2.5px;
      height: 20px;
      transition: .4s;
      position: absolute;
      -webkit-transition: .4s;
      background-color: $White;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

.switch_input:checked + .switch_slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

