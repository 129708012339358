@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.free-item-section {
  display: flex;
  margin-top: 80px;
  min-height: 800px;
  position: relative;
  align-items: center;

  @include responsive-max(991px) {
    margin-top: 0;
    min-height: 675px;
  }

  &_content {
    z-index: 1;
    margin: 80px auto 45px;

    @include responsive-max(991px) {
      margin: 55px auto 0;
    }

    &_deadline {
      margin: 25px 0;

      @include responsive-max(991px) {
        margin: 10px 0 25px;
      }
    }

    &_description {
      min-height: 120px;

      &_title {
        @extend .block-title
      }

      p {
        color: $P50;
        @extend .p-01-regular
      }
    }

    &_congratulations {
      text-align: center;

      h1 {
        @extend .h-01-e-bold;
        text-transform: uppercase;

        @include responsive-max(1200px) {
          font-size: 40px;
        }

        @include responsive-max(991px) {
          font-size: 24px;
        }
      }

      h4 {
        @extend .h-04-e-bold;
        text-transform: uppercase;

        @include responsive-max(1200px) {
          font-size: 20px;
        }

        @include responsive-max(1200px) {
          font-size: 16px;
        }
      }
    }

    button {
      width: 100%;
      margin-top: 25px;

      @include responsive-max(991px) {
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }
  }
}

.block-title {
  color: $G-4;
  display: block;
  @extend .n-link-u;
  margin-bottom: 6px;
  text-transform: uppercase;

  @include responsive-max(991px) {
    margin-bottom: 12px;
  }
}