@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.shop-divine {
  width: 100%;
  min-height: 715px;
  @extend %flex-align;

  .right {
    .buttonV3 {
      width: 100%;
    }
  }

  &.divine {
    background: linear-gradient(149deg, rgba(0, 0, 0, 0.00) 35.87%, rgba(0, 0, 0, 0.82) 64.86%), url("../../../../assets/png/divine-background-v2.png") no-repeat;
    background-size: cover;

    @include responsive-max(991px) {
      background-position: right;
    }

    @include responsive-max(480px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 42.2%, rgba(0, 0, 0, 0.8) 48.68%), url("../../../../assets/png/divine-background-mobile-v2.png") no-repeat;
      background-size: cover;
    }
  }

  &_content {
    margin: 80px auto 45px;

    @include responsive-max(991px) {
      margin: 10px auto 25px;
    }

    &_rarity {
      height: 18px;
      display: flex;
      min-width: 100px;
      align-items: center;

      span {
        display: block;
      }

      & > span:first-child {
        color: $G-4;
        height: 16px;
        margin-right: 10px;
        @extend .c-u-t-light;
        text-transform: uppercase;
      }
    }

    &_deadline {
      margin: 25px 0;

      @include responsive-max(991px) {
        margin: 10px 0 25px;
      }
    }

    &_price-info {
      display: flex;
      margin-bottom: 25px;

      &_price {
        min-width: 115px;
        margin-right: 15px;

        &_title {
          @extend .block-title
        }

        &_content {
          margin-left: 10px;
          @extend %flex-align;

          img {
            width: 28px;
            height: 44px;

            @include responsive-max(991px) {
              width: 19px;
              height: 30px;
            }
          }

          & > span {
            color: $P50;
            height: 36px;
            min-width: 77px;
            margin-left: 20px;
            @extend .h-04-e-bold;

            @include responsive-max(991px) {
              width: 36px;
              height: 20px;
              margin-left: 15px;
            }
          }
        }
      }

      &_quantity {
        &_title {
          @extend .block-title
        }
      }
    }

    &_description {
      margin-top: 25px;

      &_title {
        @extend .block-title
      }

      p {
        color: $P50;
        @extend .p-01-regular
      }
    }

    &_eligibility-block {
      width: 100%;
      height: 55px;
      margin: 25px 0;
      background: $A2-75-O;
      @extend %flex-center;
      border: 1px solid $A2-300;

      @include responsive-max(991px) {
        margin: 25px 0 10px;
      }

      & > span {
        color: $White;
        display: block;
        @extend .h-04-e-bold;
        text-transform: uppercase;
      }

      &.eligible {
        color: $A1-300 !important;
        border: 1px solid $A1-300 !important;

        span {
          color: $A1-200 !important;
        }
      }
    }

    &_eligibility-item-block {
      margin: 25px 0;

      @include responsive-max(991px) {
        margin: 25px 0;
      }

      &_title {
        @extend .p-01-regular;
        text-transform: uppercase;
        text-decoration: underline;

        @include responsive-max(480px) {
          font-size: 12px;
        }
      }

      &_item {
        width: 100%;
        height: 45px;
        padding: 10px;
        margin-top: 5px;
        border: 1px solid #2D2D2D;
        @extend %flex-justify-between-center;

        &.skeleton {
          border: none;
        }

        @include responsive-max(991px) {
          padding: 10px;
        }

        & > div:first-child {
          @extend .p-01-regular;
        }

        & > div:last-child {
          @extend %flex-align;
        }
      }
    }
  }

  //listing update modal
  &_listing-update {
    &_title {
      @extend .modal-title
    }

    &_content {
      @extend .modal-content-margin;

      &_info {
        margin-bottom: 25px;
        @extend %flex-align;

        &_img {
          @extend .modal-img;
        }

        &_name {
          @extend %flex-align;

          @include responsive-max(480px) {
            width: calc(100% - 105px);
            justify-content: space-between;
          }

          & > div:first-child {
            margin-right: 40px;

            @include responsive-max(480px) {
              margin-right: 0;
            }

            h6 {
              @extend .h-06-bold;
              margin-bottom: 8px;
              text-transform: uppercase;

              @include responsive-max(480px) {
                font-size: 12px;
                margin-bottom: 0;
                line-height: 16px;
              }
            }
          }

          & > div:last-child {
            p {
              @extend .h-06-bold;
            }
          }
        }
      }

      &_btns {
        display: flex;

        @include responsive-max(480px) {
          flex-direction: column;
        }

        & > button:first-child {
          margin-right: 25px;

          @include responsive-max(480px) {
            margin: 0 0 25px 0;
          }
        }

        & > button {
          width: 100%;
        }
      }
    }
  }

  //order modal
  &_order-summery {
    &_content {
      margin-top: 20px;

      @include responsive-max(991px) {
        margin-top: 25px;
      }

      &_info {
        display: flex;
        margin-bottom: 25px;

        &_img {
          width: 120px;
          height: 120px;
          margin-right: 25px;

          @include responsive-max(991px) {
            width: 90px;
            height: 90px;
            margin-right: 15px;
          }

          & > img {
            width: inherit;
            height: inherit;
          }
        }

        &_details {
          @extend %flex-align;

          @include responsive-max(480px) {
            width: calc(100% - 105px);
            justify-content: space-between;
          }

          &_title {
            margin-right: 40px;

            @include responsive-max(480px) {
              margin-right: 0;
            }

            h6 {
              margin-top: 8px;
              @extend .h-06-bold;
            }
          }

          &_qantity {
            @extend .h-06-bold;
            color: $P50;
          }
        }
      }

      &_payments-methods {

        &_head {
          @extend %flex-justify-between;

          &_quantity {
            @extend .p-02-light;
            margin-bottom: 3px;

            @include responsive-max(991px) {
              font-size: 12px;
              margin-bottom: 1px;
            }

            span:first-child {
              color: $P50;
            }

            span:last-child {
              color: $G-5;
            }
          }

          &_error {
            color: $A2-300;
            @extend .p-03-regular;

            @include responsive-max(991px) {
              display: none !important;
            }
          }

          & > span {
            display: block;
          }
        }

        &_bottom {
          &_error {
            p {
              color: $A2-300;
              padding-top: 15px;
              text-align: center;
              @extend .p-03-regular;

              @include responsive-min(991px) {
                display: none;
              }
            }
          }
        }
      }

      &_total {
        margin-top: 10px;
        @extend %flex-justify-between;

        div {
          color: $P50;
          @extend .n-link-u;
          text-transform: uppercase;
        }
      }

      &_mobile-error {
        display: none;
        color: $A2-300;
        padding-top: 25px;
        text-align: center;
        @extend .p-03-regular;

        @include responsive-max(991px) {
          display: block;
        }
      }

      &_buttons {
        margin-top: 25px;
        @extend %flex-justify-between;

        @include responsive-max(480px) {
          flex-direction: column;
        }

        button {
          order: 1;
          width: 48%;

          @include responsive-max(480px) {
            width: 100%;

            &:last-child {
              order: -1;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  &_web2-browser {

    p {
      color: $G-3;
      margin: 10px 0;
      text-align: center;
      @extend .p-03-regular;
    }
  }

  .interrogative-color {
    color: $A2-400;
  }
}

.block-title {
  color: $G-4;
  display: block;
  @extend .n-link-u;
  margin-bottom: 6px;
  text-transform: uppercase;

  @include responsive-max(991px) {
    margin-bottom: 12px;
  }
}

.modal-padding {
  padding: 40px;

  @include responsive-max(991px) {
    padding: 30px;
  }

  @include responsive-max(480px) {
    padding: 15px;
  }
}

.modal-title {
  text-align: center;
  @extend .h-06-bold;
  text-transform: uppercase;
}

.modal-content-margin {
  margin-top: 20px;

  @include responsive-max(991px) {
    margin-top: 25px;
  }
}

.modal-img {
  width: 120px;
  height: 120px;
  margin-right: 25px;

  @include responsive-max(991px) {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }

  & > img {
    width: inherit;
    height: inherit;
  }
}