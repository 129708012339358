@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.locker-current-cosmetic {
  &_section {
    min-height: 800px;
    @extend %flex-align;

    &_content {
      margin: 80px auto 45px;

      @include responsive-max(991px) {
        margin: 85px auto 25px;
      }
    }

    &_description {
      &_title {
        @extend .block-title
      }

      p {
        color: $P50;
        @extend .p-01-regular
      }
    }

    &_price {
      min-width: 115px;
      margin-bottom: 25px;

      &_title {
        @extend .block-title
      }

      &_content {
        @extend %flex-align;

        svg {
          width: 44px;
          height: 44px;
          margin-right: 10px;

          @include responsive-max(991px) {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }

        span {
          color: $P50;
          height: 36px;
          min-width: 77px;
          @extend .h-04-e-bold;

          @include responsive-max(991px) {
            height: 20px;
            min-width: 36px;
          }
        }
      }
    }

    &_not-listed {
      width: 100%;
      padding: 25px;
      margin: 25px 0;
      background: $G-1-O;
      backdrop-filter: blur(2px);

      &_inp {
        margin: 15px 0 30px;

        & > div {
          margin-bottom: 10px;
        }

        & > button {
          width: 100%;
        }
      }

      &_link {
        text-align: center;

        p {
          color: $G-4;
          font-size: 10px;
        }

        a {
          color: $P50;
          @extend .l-t-regular;
          text-decoration: underline;

          &:hover {
            color: $A2-300;
            transition: 0.1s ease-in-out;
          }
        }
      }
    }

    &_web2 {
      margin: 25px 0;
      padding: 44px 0;
      text-align: center;
      background: $G-1-O;
      border: 1px solid $G-1-O;
      backdrop-filter: blur(2px);
      @extend %flex-justify-center;

      p {
        color: $G-4;
        @extend %flex-align;
        @extend .p-01-regular;

        svg {
          width: 17px;
          height: 21px;
          margin-right: 5px;
        }
      }
    }
  }
}

.block-title {
  color: $G-4;
  display: block;
  @extend .n-link-u;
  margin-bottom: 6px;
  text-transform: uppercase;

  @include responsive-max(991px) {
    margin-bottom: 12px;
  }
}

.locker-transaction-history-section,
.locker-price-history-section {
  .custom-accordion {
    margin: 25px 0;
  }
}