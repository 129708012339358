// @extends
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align {
  display: flex;
  align-items: center;
}

%flex-stretch {
  display: flex;
  align-items: stretch;
}

%flex-align-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-column-align {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-justify-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-justify-around-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

%flex-justify-between {
  display: flex;
  justify-content: space-between;
}

%flex-justify-around {
  display: flex;
  justify-content: space-around;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-column-center-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%flex-column-between-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

%flex-align-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-end-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

%flex-align-end {
  display: flex;
  justify-content: flex-end;
}

%flex-start {
  display: flex;
  justify-content: flex-start;
}

%flex-column-align-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

%flex-column-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

%flex-align-start {
  display: flex;
  align-items: flex-start;
}

%flex-justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

%flex-justify-evenly-center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

%flex-align-center-row-reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

%flex-justify-start-align {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-justify-end-align {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

%filter {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
}