@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";

.area {
  &_period-buttons {
    display: flex;
    margin-bottom: 50px;

    @include responsive-max(991px) {
      margin-bottom: 30px;
    }

    button {
      width: 55px;
      color: $P50;
      outline: none;
      padding: 8px 0;
      cursor: pointer;
      background: none;
      text-align: center;
      margin-right: 15px;
      @extend .p-02-light;
      border: 1px solid $G-2;
      transition: 0.3s ease-out;

      &:hover {
        background: $G-2;
        color: $A2-500;
        border: 1px solid $A2-500;
      }

      @include responsive-max(991px) {
        width: 42px;
        padding: 5px;
        font-size: 12px;
        margin-right: 8px;
      }
    }

    .checked {
      background: $G-4;
    }
  }
}