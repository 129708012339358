@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.connect-wallet-modal {
  gap: 24px;
  @extend %flex-column;

  & > p {
    color: $G-5-O;
    margin-top: 24px;
    text-align: center;
    @extend .p-02-light;

    @include responsive-max(991px) {
      font-size: 12px;
    }
  }
}