@import "src/scss/color2";
@import "src/scss/fontSize";
@import "src/scss/vars";

@keyframes openAccordion {
  0% {
    height: 0;
    opacity: 0
  }
  50% {
    height: 50%;
    opacity: 0.5
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}

.custom-accordion {
  width: 100%;
  margin: 8px 0;

  &-item {

    .custom-accordion-header {
      height: 56px;
      margin: 0;

      .custom-accordion-button {
        margin: 0;
        width: 100%;
        color: $White;
        font-size: 16px;
        cursor: pointer;
        font-weight: 300;
        padding: 15px 14px;
        background-color: $Black;
        border: 1px solid $G-2-O;
        @extend %flex-justify-between-center;

        div:first-child {
          float: left;
          color: $A2-300;
          display: flex;
          align-items: center;

          svg {
            margin-right: 9px;
          }

          span {
            color: $White;
            margin-left: 9px;
            @extend .p-02-light;
          }
        }

        div:last-child {
          float: right;
          height: 24px;

          svg {
            transition: 0.3s ease-out;
          }

          &.openIcon {
            svg {
              transition: 0.1s ease-out;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .custom-accordion-body {
      width: 100%;
      display: none;
      margin-top: 15px;
      background-color: $Black;
      transition: 0.3s ease-out;
    }
  }
}

.according-open {
  display: block !important;
  animation: openAccordion 0.3s ease forwards;
}

.body-border {
  padding: 16px 24px;
  border: 1px solid $G-2-O;
}