@import "src/scss/color2";
@import "src/scss/fontSize";
@import "src/scss/mixin";

.current-news-container {
  &_post {
    margin: 165px 0 85px 0;
    min-height: 100vh;

    @media screen and (max-width: 991px) {
      margin: 60px 0 0;
      padding: 0 !important;
    }

    &_content {
      background-color: $G-1;
      padding: 90px 0;
      max-width: 1000px;
      margin: auto;

      @media screen and (max-width: 991px) {
        padding: 55px 24px 50px;
      }

      &_bd-wrap {
        margin: 0 auto;
        max-width: 800px;

        h1 {
          @extend .d-02-e-bold;
          color: $White;
        }

        .gold-underline {
          margin: 10px 0 25px;

          @media screen and (max-width: 991px) {
            margin: 5px 0 12px;
          }
        }

        &_author {
          @extend .p-02-light;
          color: $G-4;
          margin-bottom: 15px;

          @media screen and (max-width: 991px) {
            margin-bottom: 10px;
          }

          &_name {
            color: $White;
          }

          span {
            color: $White;
          }

          em {
            display: inline-block;
            font-style: normal;
            margin: 0 5px;
          }
        }

        &_thumb {
          margin: 60px 0 45px;

          @media screen and (max-width: 991px) {
            margin: 30px 0;
          }

          img {
            width: 100%;
          }
        }

        &_content {
          @extend .p-01-regular;
          color: $White;
          padding-bottom: 60px;
          border-bottom: 1px solid $G-3;
          margin-bottom: 45px;

          @media screen and (max-width: 991px) {
            margin-bottom: 20px;
            padding-bottom: 40px;
          }

          a {
            color: $A2-300
          }

          iframe {
            width: 100%;
            height: 450px;
            padding: 25px 0;

            @media screen and (max-width: 991px) {
              height: 350px;
            }

            @media screen and (max-width: 480px) {
              height: 280px;
            }
          }

          p, li, li > div {
            @extend .p-01-regular;
          }

          li {
            margin-bottom: 12px;
          }

          h1 {
            @extend .h-01-e-bold;
          }

          h2 {
            @extend .h-02-e-bold;
            margin: 15px 0 10px;
          }

          h3 {
            @extend .h-03-e-bold;
          }

          ul {
            padding-left: 35px;
          }
        }

        .p-share {
          display: flex;

          button:first-child {
            margin-right: 15px;
          }
        }

        .p-facebook {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-image: url(../../../../assets/svg/facebook-red.svg);
        }

        .p-twitter {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          color: red;
          background-image: url(../../../../assets/svg/twitter-x-red.svg);
        }

        .reddit {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-image: url(../../../../assets/png/reddit.png);
        }

        .telegram {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-image: url(../../../../assets/svg/telegram-red.svg);
        }
      }

      p {
        margin-bottom: 15px;
      }

      h1, h2, h3, h4 {
        margin-bottom: 15px;
      }
    }
  }

  &_news {
    padding: 85px 0;
    background: $G-1-O;

    @media screen and (max-width: 991px) {
      padding: 50px 0;
    }

    & > .container {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 20px;

      @media screen and (max-width: 1200px) {
        @include grid-display(3)
      }
      @media screen and(max-width: 991px) {
        @include grid-display(2);
        margin-top: 30px;
      }
    }
  }
}


