@import "src/scss/color2";
@import "src/scss/fontSize";

.countdown {
  display: flex;
  color: #212121;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .date {
    font-size: 12px;
    font-weight: bold;
    color: #212121;
    text-transform: uppercase;
    line-height: 12px;
  }

  .numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 59px;

    @media screen and (max-width: 991px) {
      height: 42px;
    }

    .divider {
      font-size: 32px;
      font-weight: bold;
      color: $White;
      margin-bottom: 12px;

      @media screen and (max-width: 991px) {
        font-size: 24px;
      }
    }

    .num-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      min-width: 50px;

      @media screen and (max-width: 991px){
        margin: 0;
      }

      .num {
        @extend .h-02-e-bold;
        color: $White;
        margin-bottom: 5px;
        height: 44px;

        @media screen and (max-width: 991px) {
          height: 24px;
        }
      }

      .num-label {
        @extend .p-04-bold;
        color: $White;
        text-transform: uppercase;
      }
    }
  }
}