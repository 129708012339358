@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/fontSize";

.shop {

  &_tabs {
    margin-top: 150px;

    @include responsive-max(991px) {
      margin-top: 75px;
    }

    width: 100%;

    .tab-block-display {
      @include responsive-max(991px) {
        display: none;
      }
    }

    .tab-block-mobile {
      display: none;

      @include responsive-max(991px) {
        display: block;
        padding: 0 15px;
        margin-bottom: 5px;
      }
    }
  }
}