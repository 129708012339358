//Primary
$P50: #f8f3e9;
$P75: #e3cfa6;
$P100: #d7bb81;
$P200: #c69d4b;
$P300: #ba8926;
$P400: #82601b;
$P500: #715417;

$P75-O: rgba(186, 137, 38, 0.05);
$P100-O: rgba(186, 137, 38, 0.15);
$P200-O: rgba(186, 137, 38, 0.3);
$P300-O: rgba(186, 137, 38, 0.45);
$P400-O: rgba(186, 137, 38, 0.6);
$P500-O: rgba(186, 137, 38, 0.85);

//Accent 1
$A1-50: #e6fdf7;
$A1-75: #96f7df;
$A1-100: #6bf4d1;
$A1-200: #2befbd;
$A1-300: #00ecb0;
$A1-400: #00a57b;
$A1-500: #00906b;

$A1-75-O: rgba(0, 236, 176, 0.05);
$A1-100-O: rgba(0, 236, 176, 0.15);
$A1-200-O: rgba(0, 236, 176, 0.3);
$A1-300-O: rgba(0, 236, 176, 0.45);
$A1-400-O: rgba(0, 236, 176, 0.6);
$A1-500-O: rgba(0, 236, 176, 0.85);

//Accent 2
$A2-50: #ffeaeb;
$A2-75: #ffaaad;
$A2-100: #ff868b;
$A2-200: #ff5259;
$A2-300: #ff2f37;
$A2-350: #FF282A;
$A2-400: #b32127;
$A2-500: #9c1d22;


$A2-75-O: rgba(255, 47, 55, 0.05);
$A2-100-O: rgba(255, 47, 55, 0.15);
$A2-200-O: rgba(255, 47, 55, 0.3);
$A2-300-O: rgba(255, 47, 55, 0.45);
$A2-400-O: rgba(255, 47, 55, 0.6);
$A2-500-O: rgba(255, 47, 55, 0.85);

//Gradients
$GR-Y1: linear-gradient(315deg, #643D07 0%, #BA8926 100%);
$GR-Y2: linear-gradient(270deg, #643D07 0%, #BA8926 100%);
$GR-Y3: linear-gradient(315.38deg, #643D07 32.22%, #BA8926 100%);
$GR-White: linear-gradient(90deg, #FFFFFF 0%, rgba(128, 128, 128, 0.45) 100%);
$GR-Red: linear-gradient(89.96deg, #F51919 0.03%, #770001 99.8%);

//Tick Tags
$TT-Grey: linear-gradient(180deg, #929292 0%, #5F5F5F 100%);
$TT-Green: linear-gradient(180deg, #25924F 0%, #1E5E46 100%);
$TT-Blue: linear-gradient(180deg, #1B2CC9 0%, #0F1C4E 100%);
$TT-Purple: linear-gradient(180deg, #8822C7 0%, #2B2046 100%);
$TT-Yellow: linear-gradient(180deg, #9AA439 0%, #8E6220 100%);

//Background
$White: #ffffff;
$Black: #000000;
$Black-100-0: rgba(0, 0, 0, 0.8);
$Black-200-0: rgba(0, 0, 0, 0.5);
$Dark-Violet: #0C0D17;
$Dark-Violet-O: rgba(12, 13, 23, 0.9);
$Gray: #141413;

//Grays
$G-1: #141414;
$G-2: #2D2D2D;
$G-3: #525252;
$G-4: #777777;
$G-5: #9C9C9C;
$G-6: #DADADA;

$G-0-0: rgba(20, 20, 20, 0.8);
$G-1-O: rgba(255, 255, 255, 0.05);
$G-2-O: rgba(255, 255, 255, 0.15);
$G-3-O: rgba(255, 255, 255, 0.3);
$G-4-O: rgba(255, 255, 255, 0.45);
$G-5-O: rgba(255, 255, 255, 0.6);
$G-6-O: rgba(255, 255, 255, 0.85);

//Rarity Colors
$Ordinary: #E4E4E4;
$Common: #0BFB6B;
$Rare: #0B23FB;
$Elite: #A00BFB;
$Legendary: #FB9B0B;
$Divine: linear-gradient(89.96deg, #F51919 0.03%, #770001 99.8%);

//Rarity Colors V2

$Ordinary-V2: #3C3C3C;
$Common-V2: #064500;
$Rare-V2: #002F76;
$Elite-V2: #69009F;
$Legendary-V2: #8C2600;
$Divine-V2: #A70021;
