@import "src/scss/mixin";
@import "src/scss/color2";

.news {

  .main-page_title {
    & > p {
      max-width: 280px;

      @include responsive-max(991px){
        max-width: 230px;
      }
    }
  }

  &_content-container {
    margin-top: 85px;
    min-height: 500px;

    @media screen and (max-width: 991px) {
      margin-top: 50px;
    }

    &_loading {
      width: 100%;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_news {
      padding-top: 80px;

      @media screen and (max-width: 991px) {
        padding-top: 30px;
      }

      & > div:first-child {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 20px;

        @media screen and (max-width: 1200px) {
          @include grid-display(3)
        }
        @media screen and(max-width: 992px) {
          @include grid-display(2)
        }
      }

      &_more-btn {
        display: flex;
        margin-top: 15px;
        justify-content: center;

        button {
          color: $A2-300;
          margin: 0 10px;
          cursor: pointer;
          background: none;
          padding: 10px 15px 8px;
          border: 1px solid $A2-300;
          transition: 0.3s ease-in-out;

          @include responsive-max(991px) {
            padding: 5px 10px 3px;

            svg {
              width: 7px;
              height: 15px;
            }
          }

          &:hover {
            color: $White;
            background: $A2-200-O;
          }
        }

        &.hidden {
          button:last-child {
            display: none;
          }
        }
      }
    }
  }
}