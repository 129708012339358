@import "src/scss/fontSize";

.termsConditionMobile {

  .main-page_back-img {

    @media screen and (max-width: 480px) {
      object-fit: contain;
      height: 345px;

      img {
        height: 345px;
      }
    }
  }
}

.page-main_title-block_terms-text {
  width: 560px;

  @media screen and (max-width: 991px) {
    width: 330px;
  }

  @media screen and (max-width: 375px) {
    width: auto;
  }
}

h3 {
  @media screen and (max-width: 480px) {
    font-size: 27px;
  }
}

.list-latin {
  list-style-type: lower-latin;
  padding-left: 48px;

  @media screen and (max-width: 991px) {
    padding-left: 29px;
  }

  @media screen and (max-width: 425px) {
    padding-left: 0;
  }

  > li {
    margin: 16px 0;
    padding-left: 10px;
  }
}

.list-none {
  list-style-type: none;
  padding: 0;
}

.list-roman {
  margin-top: 8px;
  list-style-type: lower-roman;

  > li {
    padding-left: 10px;
    margin: 16px 0;
  }
}

.pad {
  padding-left: 20px;

  @media screen and (max-width: 991px) {
    padding-left: 10px;
  }
}

.pad-2 {
  padding-left: 22px;

  @media screen and (max-width: 91px) {
    padding-left: 11px;
  }
}

.pad-p {
  padding-left: 30px;

  @media screen and (max-width: 991px) {
    padding-left: 15px;
  }
}

.page-main_title-block{
  h1:after{
    margin: 0 0 0px -44px;

    @media screen and (max-width: 991px) {
      margin: 0 0 0px -22px;
      width: 44px;
      height: 2px;
    }
  }
}