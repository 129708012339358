@import "src/scss/color2";
@import "src/scss/fontSize";

.article-cart {
  margin-bottom: 35px;
  max-width: 358px;
  cursor: pointer;

  @media screen and(max-width: 992px) {
    margin-bottom: 0;
  }

  &_img {
    margin-bottom: 20px;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      margin-bottom: 15px;
    }

    img {
      width: 100%;
      height: 100%;
      min-height: 208px;
      max-height: 208px;
      display: block;
      transition: all ease 0.6s;

      @media screen and (max-width: 1200px) {
        min-height: 173px;
        max-height: 173px;
      }

      @media screen and (max-width: 991px) {
        min-height: 195px;
        max-height: 195px;
      }

      @media screen and (max-width: 768px) {
        min-height: 144px;
        max-height: 144px;
      }

      @media screen and (max-width: 565px) {
        min-height: 100px;
        min-width: 154px;
        max-height: 100px;
      }

      &:hover {
        transform: scale(1.1);
        opacity: 0.9;
      }
    }
  }

  &_info {
    &_date {
      @extend .c-u-t-light;
      margin-bottom: 15px;
      color: $G-3;

      @media screen and (max-width: 991px) {
        margin-bottom: 8px;
      }
    }

    h3 {
      @extend .h-06-bold;
      color: $P50;
      min-height: 50px;
      line-height: 20px;

      @media screen and (max-width: 991px) {
        min-height: 30px;
        line-height: 12px;
      }
    }

    &_readmore {
      @extend .n-link-u;
      text-transform: uppercase;
      color: $A2-300;
      transition: all ease 0.4s;
      position: relative;
      left: 0;

      @media screen and (max-width: 991px) {
        font-size: 10px;
        font-weight: 300;
        line-height: 12px;
      }

      &:hover {
        left: 5px;
      }
    }
  }
}