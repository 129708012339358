@import "src/scss/color2";
@import "src/scss/fontSize";
@import "src/scss/mixin";

.links {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: url("../../assets/png/linktree-background.png") no-repeat center center;
  background-size: 100% 100%;

  @media screen and (min-height:720px) {
    height: 100vh !important;
  }

  @include responsive-max(420px) {
    background: url("../../assets/png/linktree-mobile-background.png") no-repeat;
    background-size: cover;
  }

  & > div:last-child {
    z-index: 10;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 12.83%, #000 100%);
  }

  &_block {
    z-index: 10;
    width: 585px;
    display: flex;
    margin-top: 3.5%;
    overflow-x: hidden;
    flex-direction: column;

    @include responsive-max(991px) {
      padding: 25px;
      margin-top: 3%;
    }

    @include responsive-max(480px) {
      width: 100%;
    }

    button {
      width: 100%;
      height: 60px;
      border: none;
      outline: none;
      max-width: 415px;
      margin:0 auto 25px;
      background: $White;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1.02);
      }

      a {
        color: $G-1;
        width: 100%;
        display: block;
        padding: 21px 0;
        @extend .button-u-bold;

        @include responsive-max(991px) {
          padding: 15px 0;
        }
      }
    }

    &_logo {
      text-align: center;
      margin-bottom: 8%;

      @media screen and (min-height:991px) {
        margin-bottom: 18%;
      }

      @include responsive-max(991px) {
        margin-bottom: 10%;
      }

      img {
        @include responsive-max(991px) {
          width: 370px;
        }
      }
    }
  }
}