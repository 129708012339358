@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.how-to-buy-token {

  &_one {
    height: 58vw;
    max-height: 865px;
    background: url("../../../../assets/png/fire-background-2.webp") no-repeat center bottom;
    background-size: cover;
    @extend %flex-center;
    width: 100%;

    @include responsive-max(991px) {
      height: 620px;
      padding: 0 30px;
      text-align: center;

      .gold-underline {
        margin: 15px auto;
      }

      &.main-page {
        margin-top: 20px;
      }
    }

    &_content {
      width: 80%;
      @extend %flex-justify-around-center;

      @include responsive-max(991px) {
        width: 100%;
        flex-direction: column-reverse;
      }

      &_left-part {
        width: 660px;

        @include responsive-max(1200px) {
          width: 550px;
        }

        @include responsive-max(991px) {
          width: 100%;
        }

        h1 {
          @extend .h-01-e-bold;
          text-transform: uppercase;

          span {
            color: $A2-300;
          }
        }

        p {
          max-width: 480px;
          @extend .p-01-regular;

          @include responsive-max(991px) {
            margin: auto;
          }
        }

        &_buttons {
          display: flex;
          margin-top: 45px;

          @include responsive-max(991px) {
            margin-top: 30px;
            flex-direction: column;
          }

          button {
            max-width: 315px;

            @include responsive-max(991px) {
              margin: auto;
            }

            &:first-child {
              margin-right: 40px;

              @include responsive-max(991px) {
                margin: 0 auto 25px;
              }
            }
          }
        }

        .gold-underline {
          margin: 20px 0 40px;

          @include responsive-max(991px) {
            margin: 5px auto 10px;
          }
        }
      }

      &_right-part {
        width: 351px;

        @include responsive-max(1200px) {
          width: 250px;
        }

        @include responsive-max(991px) {
          width: 151px;
          margin: 0 auto 10px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_two {
    @extend .title-gold-underline;

    &_step-one {
      margin-top:85px ;
      background-color: $G-1-O;
      @extend %flex-justify-center;

      @include responsive-max(991px) {
        margin-top: 24px;
      }

      &_container {
        display: flex;
        padding: 50px 0;
        max-width: 1050px;

        @include responsive-max(768px) {
          flex-direction: column;
          max-width: 375px;
          padding: 24px 0 48px;
        }

        &_block-one {
          width: 48%;
          text-align: center;
          margin-right: 75px;

          @include responsive-max(991px) {
            margin-right: 20px;
          }

          @include responsive-max(768px) {
            margin: 0 auto 15px;
            width: 100%;
          }

          img {
            @include responsive-max(768px) {
              width: 295px;
              height: 295px;
            }
          }
        }

        &_block-two {
          width: 48%;

          @include responsive-max(768px) {
            margin: auto;
            width: 100%;
            text-align: center;
          }

          &_title {
            @extend .steps-block-title
          }

          &_text {
            @extend .steps-block-text
          }

          &_btn {
            @extend .steps-block-btn;
          }
        }
      }
    }

    &_step-two {
      display: flex;
      padding: 85px 0;

      @include responsive-max(768px) {
        padding: 48px;
        flex-direction: column;
      }

      &_block-one {
        @extend .steps-block;
        margin-right: 75px;

        @include responsive-max(991px) {
          margin-right: 20px;
        }

        @include responsive-max(768px) {
          order: 1;
        }

        &_title {
          @extend .steps-block-title
        }

        &_text {
          @extend .steps-block-text
        }

        &_btn {
          @extend .steps-block-btn
        }

        &_iframe {
          @extend .steps-block-iframe
        }
      }

      &_block-two {
        width: 48%;

        @include responsive-max(991px) {
          width: 49%;
        }
        @include responsive-max(768px) {
          width: 100%;
        }
      }
    }

    &_step-three {
      background-color: $G-1-O;
      @extend %flex-justify-center;

      &_container {
        display: flex;

        @include responsive-max(768px) {
          padding: 48px 0;
          flex-direction: column;
        }

        &_block-one {
          width: 48%;
          margin-right: 75px;

          @include responsive-max(991px) {
            margin-right: 20px;
            width: 49%;
          }

          @include responsive-max(768px) {
            margin: auto;
            width: 100%;
          }

          img {
            width: 100%;
            height: 620px;

            @include responsive-max(1200px) {
              height: 490px;
            }

            @include responsive-max(991px) {
              height: 381px;
            }

            @include responsive-max(768px) {
              height: 335px;
            }
          }
        }

        &_block-two {
          @extend .steps-block;

          &_title {
            @extend .steps-block-title
          }

          &_text {
            @extend .steps-block-text
          }

          &_btn {
            @extend .steps-block-btn;
          }
        }
      }
    }

    &_step-four {
      display: flex;
      padding: 85px;

      @include responsive-max(768px) {
        padding: 48px;
        flex-direction: column;
      }

      &_block-one {
        margin-right: 75px;

        @include responsive-max(991px) {
          margin-right: 20px;
          width: 53% !important;
        }

        @include responsive-max(768px) {
          order: 1;
          width: 80% !important;
        }

        @include responsive-max(425px) {
          width: 90% !important;
        }

        @extend .steps-block;

        &_title {
          @extend .steps-block-title
        }

        &_text {
          @extend .steps-block-text;

          span {
            @include responsive-max(500px) {
              font-size: 12px;
            }

            @include responsive-max(375px) {
              font-size: 10px;
            }
          }
        }

        &_btn {
          @extend .steps-block-btn;
        }

        &_iframe {
          @extend .steps-block-iframe
        }
      }

      &_block-two {
        width: 48%;

        @include responsive-max(991px) {
          width: 47%;
        }
        @include responsive-max(768px) {
          text-align: center;
          width: 100%;
        }

        img {
          @include responsive-max(768px) {
            width: 333px;
          }
        }
      }
    }
  }

  &_three {
    padding: 85px 0;

    @include responsive-max(991px) {
      padding: 30px;
    }

    &_success {
      margin-top: 65px;
      @extend %flex-justify-between-center;

      @include responsive-max(991px) {
        margin-top: 50px;
      }

      @include responsive-max(768px) {
        flex-direction: column;
        margin-top: 30px;
      }

      &_block-one {
        width: 48%;
        @extend .title-gold-underline;

        @include responsive-max(1200px) {
          margin-right: 45px;
        }

        @include responsive-max(991px) {
          width: 49%;
          margin-right: 20px;
        }

        @include responsive-max(768px) {
          order: 1;
          width: 100%;
          margin: 30px auto 0;
        }

        &_title {
          @extend .h-02-e-bold;
          @extend .steps-block-title;

          @include responsive-max(768px) {
            text-align: center;
          }
        }

        &_text {
          @extend .steps-block-text;

          @include responsive-max(768px) {
            text-align: center;
          }
        }

        button {
          width: 300px;

          @include responsive-max(991px) {
            margin: 15px 0 0;
          }

          @include responsive-max(768px) {
            margin: 15px auto 0;
          }

          @include responsive-max(480px) {
            width: 100%;

          }
        }
      }

      &_block-two {
        width: 48%;

        @include responsive-max(991px) {
          width: 49%;
        }

        @include responsive-max(768px) {
          width: 100%;
          text-align: center;
        }

        img {
          height: 485px;
          margin: auto;

          @include responsive-max(1200px) {
            height: 370px;
          }

          @include responsive-max(991px) {
            height: 270px;
          }

          @include responsive-max(768px) {
            width: 200px;
            height: 190px;
          }
        }
      }
    }
  }

  &_four {
    &_block {
      background: $G-1-O;
      padding: 80px 0 95px;
      @extend .title-gold-underline;

      .gold-underline {
        margin: auto;
      }

      @include responsive-max(991px) {
        padding: 40px 0;
      }

      &_title {
        @extend .steps-block-title;
        text-align: center;
      }

      &_socials {
        flex-wrap: wrap;
        margin-top: 65px;
        @extend %flex-justify-center;

        @include responsive-max(768px) {
          margin-top: 50px;
        }

        @include responsive-max(480px) {
          justify-content: space-between;
        }

        & > div {
          width: 125px;
          margin: 0 30px;
          padding: 15px 0;
          text-align: center;

          &:hover {
            a {
              span {
                color: $A2-300;
                transition: 0.2s ease-in-out;
              }
            }
          }

          @include responsive-max(991px) {
            margin: 0 10px;
          }

          @include responsive-max(480px) {
            margin: 0;
          }

          a {
            > span {
              display: block;
              font-size: 12px;
              font-weight: 400;
              margin-top: 40px;
              text-transform: uppercase;
              color: rgba(255, 255, 255, 0.25);

              @include responsive-max(991px) {
                font-size: 10px;
                margin-top: 18px;
              }
            }

            img {
              width: 72px;
              height: 51px;

              @include responsive-max(991px) {
                width: 47px;
                height: 48px;
              }
            }
          }
        }

        & > div:last-child {
          @include responsive-max(768px) {
            flex: 1 0 100%;
          }
        }
      }
    }
  }
}

.steps-block {
  width: 48%;
  padding-top: 30px;

  @include responsive-max(991px) {
    width: 49%;
  }

  @include responsive-max(768px) {
    width: 295px;
    margin: auto;
    padding-top: 15px;
    text-align: center;
  }
}

.steps-block-title {
  @extend .h-02-e-bold;
  text-transform: uppercase;

  span {
    color: $A2-300;
  }
}

.steps-block-text {
  margin: 40px 0;
  @extend .p-01-regular;

  @include responsive-max(991px) {
    margin: 10px 0 0;
  }
}

.steps-block-btn {
  margin-top: 40px;

  @include responsive-max(991px) {
    margin-top: 30px;
  }

  button {
    width: 300px;
    @include responsive-max(768px) {
      width: 240px;
      margin: auto;
    }
  }
}

.steps-block-iframe {
  width: 544px;
  height: 620px;
}

.title-gold-underline {
  .gold-underline {
    margin-top: 15px;

    @include responsive-max(991px) {
      margin-top: 5px;
    }

    @include responsive-max(768px) {
      margin: 5px auto 0;
    }
  }
}
