@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";

.bazaar-token {
  display: flex;
  min-height: 710px;
  align-items: center;
  background: url("../../../../../assets/png/token-background.png") no-repeat center center;
  background-size: cover;

  &_content {
    margin-bottom: 25px;

    &_current-price, &_btns, &_description {
      margin-top: 25px;

      & > span {
        color: $G-4;
        display: block;
        @extend .n-link-u;
        margin-bottom: 10px;
        text-transform: uppercase;
      }

      & > p {
        @extend .p-01-regular;
      }
    }

    &_btns {
      button:not(:first-child) {
        margin-top: 8px;
      }

      & > button {
        width: 100%;
      }

    }

    &_current-price {
      &_info {
        display: flex;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
          margin-right: 8px;
        }

        span {
          height: 35px;
          min-width: 89.5px;
          @extend .h-04-e-bold;

          @include responsive-max(991px) {
            height: 20px;
            min-width: 60px;
          }
        }
      }
    }
  }
}
