@import "src/scss/color2";
@import "src/scss/fontSize";

.inp-container {
  position: relative;

  &_label-block {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: flex-end;

    &_name {
      @extend .c-u-t-light;
      color: $G-4;
      min-width: 100px;
    }

    &_btn {
      @extend .l-t-regular;
      background: none;
      outline: none;
      border: none;
      color: $P50;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        transition: 0.2s ease-out;
        color: $A2-300;
      }
    }

    &_error {
      @extend .c-u-t-light;
      text-align: right;

      &.error {
        color: $A2-300-O;
      }

      &.success {
        color: $A1-300-O;
      }

      &.warning {
        color: $P300-O;
      }

      &.right{
        justify-content: flex-end !important;
      }
    }
  }

  input {
    outline: none;
    padding:0 15px;
    width: 100%;
    transition: 0.2s ease-out;
    @extend .p-02-light;

    &::placeholder {
      @extend .p-02-light;
      color: $G-3;
    }

    &.default {
      border: 1px solid $G-2-O;
      background: $Black;
      color: $G-6-O;

      &:hover {
        border: 1px solid $G-3-O;
      }

      &:focus {
        border: 1px solid $G-6-O;
      }
    }

    &.error {
      border: 1px solid $A2-200-O;
      background: $Black;
      color: $G-6-O;

      &:hover {
        background: $A2-75-O !important;
      }

      &:focus {
        border: 1px solid $A2-300-O;
      }

      &:valid {
        background: $A2-75-O;
      }

      &:placeholder-shown {
        background: $Black;
      }

      &:not(:placeholder-shown) {
        background: $A2-75-O
      }
    }

    &.success {
      border: 1px solid $A1-200-O;
      background: $Black;
      color: $G-6-O;

      &:hover {
        background: $A1-75-O !important;
      }

      &:focus {
        border: 1px solid $A1-300-O;
      }

      &:placeholder-shown {
        background: $Black;
      }

      &:not(:placeholder-shown) {
        background: $A1-75-O
      }
    }

    &.warning {
      border: 1px solid $P200-O;
      background: $Black;
      color: $G-6-O;

      &:hover {
        background: $P75-O !important;
      }

      &:focus {
        border: 1px solid $P300-O;
      }

      &:placeholder-shown {
        background: $Black;
      }

      &:not(:placeholder-shown) {
        background: $P75-O
      }
    }

    &.lg {
      height: 56px;

      @media screen and (max-width: 991px) {
        height: 48px;
      }
    }

    &.md {
      height: 48px;
    }
  }

  input[type="password"] {
    padding-right: 50px;
  }

  input[type="number"] {
   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
     display: none;
   }
  }

  &_password-icon , &_icon {
    position: absolute;
    bottom: 15px;
    right: 20px;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 991px) {
      bottom: 11px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}