@import "scss/fontSize";
@import "scss/color2";
@import "scss/mixin";
@import "scss/vars";

.terms-text-style {
  color: $G-5-O;
  text-align: center;
  @extend .p-02-light;
}

.sign-up-modal {
  gap: 24px;
  @extend %flex-column;

  & > div:first-child {
    margin-top: 24px;
  }

  & > p {
    @extend .terms-text-style;

    @include responsive-max(991px) {
      font-size: 12px;
    }

    & > a {
      @extend .terms-text-style;
      text-decoration: underline;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $G-4-O;
      }
    }
  }
}