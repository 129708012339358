@import "color2";
@import "mixin";
@import "fontSize";

html {
  scroll-behavior: smooth;
}

* {
  font-family: Sora, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $Black;
  color: $P50;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $A2-300;
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $A2-200;
  }
}

h1, h2, h3, h4, h5, h6, p {
  color: $P50;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  background: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(1.5rem * .5);
  padding-right: calc(1.5rem * .5);
  width: 100%;
  max-width: 1140px;

  @media screen and (max-width: 1200px) {
    max-width: 960px;
  }

  @media screen and (max-width: 992px) {
    max-width: 720px;
  }

  @media screen and (max-width: 768px) {
    max-width: 540px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container-allPage {
  min-height: 700px;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000009c;
  z-index: 215;
}

.fire-back {
  background: url("../assets/png/404-background.png") no-repeat center center;
  @extend .static-background;
}

.hero-back {
  background: url("../assets/png/hero.png") no-repeat center center;
  @extend .static-background;
}

.static-background {
  background-size: cover;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  flex-direction: column;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: $A2-300-O;
  border-radius: 7px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
                    90deg,
                    rgba($A2-300, 0) 0,
                    rgba($A2-300, 0.2) 20%,
                    rgba(#ff2f3794, 0.5) 60%,
                    rgba(#ff2f3794, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.row-right-img {
  min-height: 411px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    min-height: 350px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  & > div:first-child {
    width: 520px;
    flex-shrink: 0;

    @media screen and (max-width: 1200px) {
      width: 440px;
    }

    @media screen and (max-width: 991px) {
      width: 80%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    h2 {
      @extend .h-02-e-bold;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }

      span {
        color: $A2-300;
      }
    }

    p {
      @extend .p-01-regular;
      margin-bottom: 15px;
    }
  }

  & > div:last-child {
    margin: 0 -167px 0 80px;
    width: 100%;

    @media screen and (max-width: 1200px) {
      margin: 0 -125px 0 30px;
    }

    @media screen and (max-width: 991px) {
      width: 80%;
      margin: 0 0 15px 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.row-left-img {
  min-height: 411px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    min-height: 350px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  & > div:last-child {
    width: 520px;
    flex-shrink: 0;

    @media screen and (max-width: 1200px) {
      width: 440px;
    }

    @media screen and (max-width: 991px) {
      width: 80%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    h2 {
      @extend .h-02-e-bold;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }

      span {
        color: $A2-300;
      }
    }

    p {
      @extend .p-01-regular;
      margin-bottom: 15px;
    }
  }

  & > div:first-child {
    margin: 0 80px 0 -167px;
    width: 100%;

    @media screen and (max-width: 1200px) {
      margin: 0 30px 0 -125px;
    }

    @media screen and (max-width: 991px) {
      width: 80%;
      margin: 0 0 15px 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.black-gradient-filter {
  @media screen and (min-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1769082633) 83%, rgba(0, 0, 0, 0.9920343137) 100%);
  }
}

.overlay-bottom {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 74.09%, #000 100%) !important;
}

.overlay-top {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(0, rgba(0, 0, 0, 0.00) 74.09%, #000 100%) !important;
}

.section-padding {
  padding: 80px 0;

  @media screen and (max-width: 991px) {
    padding: 40px 0
  }
}

$backgroundClasses: (
        ordinary: '../assets/png/ordinary-background.png' '../assets/png/ordinary-background-mobile.png',
        common: '../assets/png/common-background.png' '../assets/png/common-background-mobile.png',
        rare: '../assets/png/rare-background.png' '../assets/png/rare-background-mobile.png',
        legendary: '../assets/png/legendary-background.png' '../assets/png/legendary-background-mobile.png',
        elite: '../assets/png/elite-background.png' '../assets/png/elite-background-mobile.png',
        divine: '../assets/png/divine-background-v2.png' '../assets/png/divine-background-mobile-v2.png'
);

$backgroundSmallClasses: (
        ordinary-small: (url: "../assets/png/ordinary-background-small.png", color: $Ordinary-V2),
        common-small: (url: "../assets/png/common-background-small.png", color: $Common-V2),
        rare-small: (url: "../assets/png/rare-background-small.png", color: $Rare-V2),
        legendary-small: (url: "../assets/png/legendary-background-small.png", color: $Legendary-V2),
        elite-small: (url: "../assets/png/elite-background-small.png", color: $Elite-V2),
        divine-small: (url: "../assets/png/divine-background-small.png", color: $Divine-V2),
);

@each $class, $urls in $backgroundClasses {
  .#{$class} {
    background: linear-gradient(106deg, rgba(0, 0, 0, 0.00) 49.2%, rgba(0, 0, 0, 0.59) 76.68%), url(nth($urls, 1)) no-repeat #141413;
    background-size: cover;

    @include responsive-max(991px) {
      background-position: right;
    }

    @include responsive-max(480px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.2%, rgba(0, 0, 0, 0.6) 57.68%), url(nth($urls, 2)) no-repeat;
      background-size: cover;
    }
  }
}

@each $class, $values in $backgroundSmallClasses {
  .#{$class} {
    border: 1px solid map-get($values, color);
    background: url(map-get($values, url)) no-repeat $Gray;
    background-size: cover;
  }
}

.block-title {
  color: $G-4;
  display: block;
  @extend .n-link-u;
  margin-bottom: 6px;
  letter-spacing: 0.52px;
  text-transform: uppercase;

  @media screen and (max-width: 991px) {
    margin-bottom: 12px;
  }
}

.modal-img {
  width: 120px;
  height: 120px;
  margin-right: 25px;

  @media screen and (max-width: 991px) {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }

  & > img {
    width: inherit;
    height: inherit;
  }
}

.snackbar-container {
  display: none;
}