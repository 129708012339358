@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

@keyframes cartShow {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.nft-card-wrapper {
  background-color: $Gray;
}

.nft-card {
  width: inherit;
  height: inherit;
  border: 1px solid;
  position: relative;
  transition: 0.2s ease-in-out;

  &_img {
    width: 100%;
    position: relative;
    padding-top: 100%;
    margin: 17.5px 0;
    overflow: hidden;
    text-align: center;
    @extend %flex-align;

    @include responsive-max(480px) {
      margin: 7px 0;
    }

    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      position: absolute;

      @include responsive-max(480px) {
        padding: 10px;
      }
    }

    &_count {
      top: 15px;
      left: 15px;
      z-index: 10;
      width: 35px;
      height: 35px;
      color: $Black;
      display: flex;
      @extend .n-link-u;
      position: absolute;
      background: $White;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      border: 1px solid $G-3-O;
      text-transform: uppercase;

      @include responsive-max(480px) {
        top: 8px;
        left: 13px;
        width: 25px;
        height: 25px;
        font-size: 8px;
        font-weight: 700;
      }
    }

    &_trade {
      top: 15px;
      z-index: 10;
      right: 15px;
      position: absolute;

      @include responsive-max(480px) {
        top: 8px;
        right: 13px;

        svg {
          width: 18px;
          height: 22px;
        }
      }
    }
  }

  &_info {
    height: 35px;

    &.big-size {
      margin-top: 40px;

      @include responsive-max(991px) {
        margin-top: 0;
      }
    }

    @include responsive-max(480px) {
      height: 22px;
    }

    h6 {
      padding: 9px 0;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;

      @include responsive-max(991px) {
        font-size: 12px;
      }

      @include responsive-max(480px) {
        padding: 6px 0;
        font-size: 10px;
      }
    }

    &_price {
      padding: 6px;
      max-height: 32px;
      background: #1B1B1E;
      @extend %flex-center;

      @include responsive-max(991px) {
        padding: 4px;
        max-height: 20px;
      }

      svg {
        width: 22px;

        @include responsive-max(991px) {
          width: 14px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;

        @include responsive-max(991px) {
          font-size: 10px;
          margin-left: 3px;
        }
      }
    }
  }

  &.card {
    background: $Gray;

    &_common {
      background-size: cover;
      border: 2px solid $Common-V2;
      background-image: linear-gradient(180deg, rgba(25, 60, 20, 0) 66%, rgb(6 69 0 / 35%) 98%), url("../../../../assets/png/common-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(25, 60, 20, 0) 0%, rgb(6 69 0 / 55%) 100%), url("../../../../assets/png/common-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Common-V2;
      }
    }

    &_rare {
      background-size: cover;
      border: 2px solid $Rare-V2;
      background-image: linear-gradient(180deg, rgba(25, 60, 20, 0) 66%, rgb(0 47 118 / 35%) 98%), url("../../../../assets/png/rare-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(25, 60, 20, 0) 0%, rgb(0 47 118 / 55%) 100%), url("../../../../assets/png/common-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Rare-V2;
      }
    }

    &_legendary {
      background-size: cover;
      border: 2px solid $Legendary-V2;
      background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 66%, rgb(140 38 0 / 35%) 100%), url("../../../../assets/png/legendary-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 0%, rgb(140 38 0 / 55%) 100%), url("../../../../assets/png/legendary-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Legendary-V2;
      }
    }

    &_elite {
      background-size: cover;
      border: 2px solid $Elite-V2;
      background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 66%, rgb(131 30 187 / 35%) 100%), url("../../../../assets/png/elite-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(59, 14, 82, 0) 0%, rgb(131 30 187 / 55%) 100%), url("../../../../assets/png/elite-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Elite-V2;
      }
    }

    &_ordinary {
      background-size: cover;
      border: 2px solid $Ordinary-V2;
      background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 66%, rgb(60 60 60 / 35%) 100%), url("../../../../assets/png/ordinary-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(59, 14, 82, 0) 0%, rgb(60 60 60 / 55%) 100%), url("../../../../assets/png/ordinary-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Ordinary-V2;
      }
    }

    &_divine {
      background-size: cover;
      border: 2px solid $Divine-V2;
      background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 66%, rgb(167 0 33 / 35%) 100%), url("../../../../assets/png/divine-cart-background-linear.png");

      &:hover {
        border: 2px solid $White;
        background-image: linear-gradient(180deg, rgba(85, 42, 9, 0) 0%, rgb(167 0 33 / 55%) 100%), url("../../../../assets/png/legendary-cart-background-linear.png");
      }

      .nft-card_info {
        background-color: $Divine-V2;
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }
}