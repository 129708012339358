@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

//-- Token Hero --
.token-hero {
  background-image: url(../../../assets/png/hero-main-background.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 920px;
  @extend %flex-align;

  @include responsive-max(991px) {
    height: 840px;
    margin-top: 60px;
    padding-bottom: 20px;
    background-position: center center;
  }

  @include responsive-max(480px) {
    background-image: url(../../../assets/png/hero-main-background-mobile.png);
  }

  .flex {
    @extend %flex-justify-between-center;

    @include responsive-max(991px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .thumb {
      flex-shrink: 0;
      margin-left: 60px;
      text-align: center;

      @include responsive-max(991px) {
        margin-left: 0;
      }

      img {
        width: 390px;
        height: 390px;

        @include responsive-max(991px) {
          width: 200px;
          height: 200px;
        }

        @include responsive-max(480px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .description {
      @include responsive-max(991px) {
        text-align: center;
      }

      h1 {
        @extend .d-01-e-bold;
        text-transform: uppercase;

        @include responsive-max(991px) {
          margin-top: 40px;
        }
      }

      .gold-underline {
        margin: 20px 0;

        @include responsive-max(991px) {
          margin: 10px auto 25px;
        }
      }

      p {
        color: $White;
        margin: 0 0 40px 0;
        @extend .p-01-regular;

        @include responsive-max(991px) {
          margin: 0 0 20px 0;
        }
      }
    }
  }
}

//-- Token Two --
.token-two {
  height: 525px;
  max-width: 1115px;
  margin: 30px auto 0;

  @include responsive-max(991px) {
    height: 460px;
  }

  @include responsive-min(480px) {
    padding: 0 15px;
  }

  &_content {
    height: inherit;
    position: relative;
    @extend %flex-align;

    @include responsive-max(991px) {
      padding: 0 !important;
      flex-direction: column;
    }

    &_title {
      width: 50%;

      @include responsive-max(480px) {
        width: 330px;
      }

      h1 {
        margin: auto;
        max-width: 540px;
        @extend .h-01-e-bold;
        text-transform: uppercase;

        @include responsive-max(991px) {
          position: absolute;
          top: 25%;
        }

        @include responsive-max(991px) {
          text-align: center;
          font-size: 24px !important;
        }

        span {
          color: $A2-300;
        }

        @include responsive-max(991px) {
          font-size: 16px;
          max-width: 270px;
        }
      }
    }

    &_img {
      background: url("../../../assets/png/hero-with-tokens.png") no-repeat center;
      background-size: cover;
      height: inherit;
      width: 75%;

      @include responsive-min(991px) {
        background-size: cover;
      }

      @include responsive-max(991px) {
        width: 100%;
        height: 375px;
        background-size: contain;
      }

      @include responsive-max(480px) {
        background-size: cover !important;
        background: url("../../../assets/png/hero-with-tokens-mobile.png") no-repeat center;
      }
    }
  }
}

// -- Token Three--
.token-three {
  background-color: #000;
  @extend .token-section-padding;

  .tokenomics {
    max-width: 890px;
    margin: 100px auto 0;
    @extend %flex-justify-between;

    @include responsive-max(768px) {
      flex-wrap: wrap;
      margin: 50px auto 0;
    }

    @include responsive-max(768px) {
      & > .item:first-child {
        margin: 0 0 48px 0;
      }
    }

    .item:first-child {
      img {
        width: 226px;

        @include responsive-max(768px) {
          width: 162px;
        }
      }
    }

    .item:last-child {
      img {
        width: 272px;

        @include responsive-max(768px) {
          width: 195px;
        }
      }
    }

    .item {
      max-width: 390px;
      text-align: center;

      @include responsive-max(991px) {
        width: 100%;
        max-width: 100%;
      }

      .thumb {
        margin-bottom: 40px;

        @include responsive-max(991px) {
          margin-bottom: 25px;
        }

        img {
          height: 265px;

          @include responsive-max(768px) {
            height: 190px;
          }
        }
      }

      h3 {
        display: block;
        margin-bottom: 20px;
        @extend .h-02-e-bold;
        text-transform: uppercase;

        @include responsive-max(991px) {
          margin-bottom: 10px;
        }

        span {
          color: $A2-300;
        }
      }

      p {
        @extend .p-01-regular;
        color: $P50;

        @include responsive-max(991px) {
          margin: 0 auto;
          max-width: 300px;
        }
      }
    }
  }
}

//-- Token Four --
.token-four {
  background-color: $G-1-O;
  @extend .token-section-padding;

  .steps {
    max-width: 600px;
    margin: 90px auto 0;
    @extend %flex-justify-between;

    @include responsive-max(991px) {
      margin: 30px auto 0;
    }

    @include responsive-max(768px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .step-item {
      text-align: center;
      min-width: 250px;

      @include responsive-max(768px) {
        min-width: 80px;
        padding: 0 15px;
      }

      @include responsive-max(375px) {
        min-width: 80px;
        padding: 0 10px;
      }

      button {
        @include responsive-max(768px) {
          font-size: 9px;
          height: 32px;
          width: 100px;
        }

      }

      .icon {
        height: 152px;

        @include responsive-max(991px) {
          height: 80px;
        }

        @include responsive-max(768px) {
          height: 42px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          @include responsive-max(991px) {
            width: 80px;
            height: 80px;
          }

          @include responsive-max(768px) {
            width: 42px;
            height: 42px;
          }
        }
      }

      p {
        margin: 25px 0;
        font-size: 24px;
        @extend .h-04-e-bold;
        text-transform: uppercase;

        @include responsive-max(767px) {
          font-size: 12px;
          line-height: 16px;
          margin: 15px 0 25px;
        }
      }
    }

    & > div:last-child {
      @include responsive-max(330px) {
        margin-top: 20px;
      }
    }
  }

  .control {
    text-align: center;
    padding: 40px 0 0 0;

    @include responsive-max(767px) {
      padding-top: 32px;
    }
  }
}

//--Token Five --
.token-five {
  @extend .token-section-padding;

  @include responsive-min(991px) {
    padding-bottom: 260px !important;
  }

  @include responsive-max(991px) {
    padding-bottom: 80px !important;
  }

  .chart {
    margin-top: 60px;

    @include responsive-max(991px) {
      margin-top: 25px;
    }

    @include responsive-max(480px) {
      height: 400px;

      iframe {
        height: 400px !important;
      }
    }
  }
}

// -- Token Six --
.token-six {
  background: $G-1-O;
  position: relative;
  padding: 115px 0 45px;

  @include responsive-max(991px) {
    padding: 115px 0 24px;
  }

  .c1 {
    top: -145px;
    left: -40px;
    width: 383px;
    position: absolute;

    @include responsive-max(1200px) {
      width: 250px;
    }

    @include responsive-max(991px) {
      width: 105px;
      height: 105px;
    }

    @include responsive-max(768px) {
      top: -23px;
      left: -15px;
    }
  }

  .c2 {
    top: 305px;
    width: 82px;
    left: 480px;
    height: 82px;
    position: absolute;

    @include responsive-max(1200px) {
      left: 350px;
    }

    @include responsive-max(991px) {
      display: none;
    }
  }

  .c3 {
    top: 160px;
    width: 210px;
    right: 145px;
    position: absolute;

    @include responsive-max(1200px) {
      width: 150px;
    }

    @include responsive-max(991px) {
      top: 30px;
      right: 40px;
      width: 110px;
    }

    @include responsive-max(768px) {
      top: 30px;
      width: 52px;
      right: 30px;
    }
  }

  .c4 {
    bottom: 0;
    left: 280px;
    z-index: 10;
    width: 139px;
    position: absolute;

    @include responsive-max(1200px) {
      width: 90px;
    }

    @include responsive-max(991px) {
      width: 32px;
      top: 65px;
      left: 50%;
    }
  }

  .content {
    min-height: 411px;
    margin-top: 255px;
    @extend %flex-justify-between-center;

    @include responsive-max(1200px) {
      min-height: 350px;
      margin-top: 150px;
    }

    @include responsive-max(991px) {
      margin-top: 0;
      text-align: center;
      flex-direction: column-reverse;
    }

    > div:first-child {
      width: 485px;
      flex-shrink: 0;

      h3 {
        @extend .h-02-e-bold;
        text-transform: uppercase;

        span {
          color: $A2-300;
        }

        @include responsive-max(991px) {
          margin-bottom: 10px;
        }
      }

      p {
        @extend .p-01-regular;
        margin: 15px 0 20px;

        @include responsive-max(991px) {
          margin: 10px 0 30px;
        }
      }

      button {
        width: 300px;

        @include responsive-max(991px) {
          margin: auto;
          width: 180px;
          height: 32px;
          font-size: 9px;
        }
      }

      @include responsive-max(768px) {
        width: 320px;
      }
    }

    > div:last-child {
      width: 100%;
      margin: 0 -167px 0 100px;

      @include responsive-max(1200px) {
        margin: 0 -125px 0 30px;
      }

      @include responsive-max(991px) {
        width: 80%;
        margin: 30px 0 70px;

        img {
          margin-right: -50px;
        }
      }

      @include responsive-max(768px) {
        width: 100%;
      }
    }
  }
}

// -- Token Seven --
.token-seven {
  padding: 60px 0;
  background: $G-1-O;
  position: relative;

  @include responsive-max(991px) {
    padding: 24px 0 48px;
  }

  .c5 {
    top: 85px;
    width: 107px;
    right: 130px;
    position: absolute;

    @include responsive-max(991px) {
      display: none;
    }
  }

  .c6 {
    left: 24px;
    bottom: -174px;
    position: absolute;

    @include responsive-max(991px) {
      top: 9px;
      left: -4px;
      width: 73px;
      height: 66px;
    }
  }

  .c3 {
    right: 15px;
    height: 84px;
    width: 103px;
    bottom: -42px;
    position: absolute;

    @include responsive-min(991px) {
      display: none;
    }
  }

  .content {
    min-height: 400px;
    @extend %flex-justify-between-center;

    @include responsive-max(991px) {
      flex-direction: column;
      text-align: center;
      margin-top: 25px;
    }

    @include responsive-max(1200px) {
      min-height: 300px;
    }

    > div:first-child {
      width: 100%;
      margin: 0 100px 0 -167px;

      img {
        width: 100%;
        height: 100%;
      }

      @include responsive-max(1200px) {
        margin: 0 30px 0 -125px;
      }

      @include responsive-max(991px) {
        margin: 0;
        width: 80%;
      }

      @include responsive-max(768px) {
        width: 100%;
      }
    }

    > div:last-child {
      width: 485px;
      flex-shrink: 0;

      h2 {
        margin-bottom: 55px;
        @extend .h-02-e-bold;
        text-transform: uppercase;

        @include responsive-max(991px) {
          margin: 24px 0 48px;
        }

        span {
          color: $A2-300;
        }
      }

      @include responsive-max(991px) {
        width: 80%;
      }

      @include responsive-max(768px) {
        width: 100%;
      }

      > div:not(:first-child) {
        margin-top: 25px;

        @include responsive-max(991px) {
          margin-top: 16px;
        }
      }

      > div {
        height: 64px;
        @extend %flex-align;

        @include responsive-max(991px) {
          height: 40px;
          margin-top: 24px;
        }

        div:first-child {
          width: 64px;
          height: 64px;
          margin-right: 18px;
          border-radius: 50px;
          background: $A2-300;
          @extend %flex-center;
          border: 3px solid $A2-100;

          @include responsive-max(991px) {
            width: 40px;
            height: 40px;
            margin-right: 16px;
          }

          span {
            display: block;
            font-size: 32px;
            font-weight: 700;

            @include responsive-max(991px) {
              font-size: 24px;
            }
          }
        }

        div:last-child {
          @extend .p-01-regular;

          @include responsive-max(375px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

//-- Token Eight --
.token-eight {
  padding-bottom: 90px;
  background: $G-1-O;

  @include responsive-max(991px) {
    padding: 25px 0;
  }

  h2 {
    @include responsive-max(991px) {
      margin-bottom: 60px;
    }
  }

  .book {
    top: 35px;
    z-index: 2;
    text-align: center;
    position: relative;

    @include responsive-max(991px) {
      top: 0;
      margin-top: 25px;
    }

    img {
      width: 497px;
      height: 274px;

      @include responsive-max(991px) {
        width: 260px;
        height: 143px;
      }
    }
  }

  .book-box {
    max-width: 625px;
    min-height: 100px;
    margin: 0 auto 0 auto;
    background-color: $White;
    padding: 60px 60px 60px 60px;

    @include responsive-max(991px) {
      max-width: 450px;
      margin-top: -30px;
      padding: 40px 25px 25px;
    }

    &_title {
      text-align: center;

      h3 {
        color: $Black;
        margin-bottom: 12px;
        @extend .h-04-e-bold;
        text-transform: uppercase;

        @include responsive-max(991px) {
          margin-bottom: 8px;
        }
      }

      span {
        width: 140px;
        height: 35px;
        margin: auto;
        color: $White;
        padding: 11px;
        display: block;
        font-size: 11px;
        font-weight: 800;
        background: #26BA6A;
        border-radius: 50px;
        text-transform: uppercase;

        @include responsive-max(991px) {
          font-size: 9px;
          width: 115px;
          height: 25px;
          padding: 7px;
        }
      }
    }

    &_text {
      margin: auto;
      max-width: 450px;
      text-align: center;

      p {
        @extend .p-01-regular;
        margin: 30px 0;
        color: $Black;

        @include responsive-max(991px) {
          margin: 25px 0;
        }
      }

      button {
        width: 210px;
        margin: auto;

        @include responsive-max(480px) {
          width: 130px;
          height: 32px;
          font-size: 9px;
        }
      }
    }
  }
}

//-- Token Nine --
.token-nine {
  @extend .token-section-padding;
  background: $G-1;

  .book-financial {
    margin-top: 85px;
    padding: 40px 70px;
    position: relative;
    background-color: $Black;
    @extend %flex-justify-between-center;

    @include responsive-max(991px) {
      flex-direction: column-reverse;
      padding: 40px 31px;
      margin-top: 25px;
    }

    &_left {
      width: 51%;

      @include responsive-max(1200px) {
        width: 60%;
      }

      @include responsive-max(991px) {
        width: 100%;
        text-align: center;
      }

      h3 {
        @extend .h-02-e-bold;
        text-transform: uppercase;

        span {
          color: $A2-300;
        }

        @include responsive-max(991px) {
          font-size: 16px;
        }
      }

      p {
        @extend .p-01-regular;

        @include responsive-max(991px) {
          width: 64%;
          margin: auto;
        }

        @include responsive-max(768px) {
          width: 90%;
          margin-top: 10px;
        }

        @include responsive-max(425px) {
          width: 265px;
        }

        @include responsive-max(375px) {
          width: 100%;
        }
      }
    }

    &_right {
      position: relative;

      @include responsive-max(991px) {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        justify-content: center;
      }

      .c1 {
        width: 292px;
        height: 292px;

        @include responsive-max(1200px) {
          top: 24px;
          right: 6px;
          width: 220px;
          height: 220px;
        }

        @include responsive-max(991px) {
          top: 0;
          width: 124px;
          height: 124px;
          position: relative;
        }
      }
    }

    .c2 {
      top: 23px;
      width: 57px;
      left: 518px;
      height: 57px;
      position: absolute;

      @include responsive-max(991px) {
        top: 30px;
        left: 25px;
        width: 40px;
        height: 40px;
      }
    }

    .c4 {
      left: 633px;
      width: 139px;
      height: 127px;
      bottom: -35px;
      position: absolute;

      @include responsive-max(1200px) {
        left: 466px;
        width: 139px;
        height: 127px;
        bottom: -45px;
      }

      @include responsive-max(991px) {
        left: 7px;
        width: 72px;
        height: 66px;
        bottom: -15px;
      }
    }

    .c5 {
      top: 48px;
      width: 67px;
      height: 73px;
      right: -10px;
      position: absolute;

      @include responsive-max(991px) {
        top: 94px;
        width: 49px;
        right: 25px;
        height: 53px;
      }
    }
  }
}

//-- Token White Paper --
.token-white-paper {
  @extend .token-section-padding;
  background: url("../../../assets/png/kompete-token-book.png") no-repeat $G-1 right bottom;

  @include responsive-min(991px) {
    min-height: 690px;
  }

  @include responsive-max(991px) {
    background-image: none;
  }

  &_content {
    @extend %flex-center;

    @include responsive-max(991px) {
      flex-direction: column;
    }

    & > div {

      @include responsive-min(991px) {
        height: 385px;
        width: 50%;
      }
    }

    & > div:first-child {
      @extend %flex-column;
      justify-content: flex-end;

      & > div {
        max-width: 450px;
        margin-bottom: 30px;

        @include responsive-max(991px) {
          max-width: 400px;
          margin-bottom: 0;
        }

        @include responsive-max(480px) {
          max-width: 280px;
        }

        p {
          margin-bottom: 30px;
          @extend .p-01-regular;

          @include responsive-max(991px) {
            text-align: center;
            margin-top: 60px;
          }
        }

        button {
          min-width: 210px;

          @include responsive-max(991px) {
            margin: auto;
          }

          @include responsive-max(768px) {
            width: 130px;
            height: 32px;
            font-size: 9px;
          }
        }
      }
    }

    & > div:last-child {

      @include responsive-max(991px) {
        width: 375px;
        margin-bottom: -44px;
      }

      img {
        @include responsive-min(991px) {
          display: none;
        }
      }
    }
  }
}

//-- Token Buying Guild --
.token-buying-guid {
  min-height: 700px;
  padding: 85px 0 0;
  background-color: #141414;

  @include responsive-max(991px) {
    min-height: 550px;
    padding: 30px 0 0;
  }

  &_content {
    margin-top: -100px;
    @extend %flex-justify-between-center;

    @include responsive-max(991px) {
      flex-direction: column;
      margin-top: 0;
    }

    & > div:first-child {
      p:first-child {
        @include responsive-max(991px) {
          margin-top: 30px;
        }
      }

      p {
        @extend .p-01-regular;

        @include responsive-max(991px) {
          text-align: center;
        }
      }

      button {
        margin-top: 45px;
        width: 250px;

        @include responsive-max(991px) {
          margin: 30px auto 0;
        }
      }
    }
  }
}

.token-section-padding {
  padding: 85px 0;

  @include responsive-max(991px) {
    padding: 40px 0;
  }
}

