@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

@keyframes online {
  100% {
    transform: scale(0, 0);
  }
  0% {

    transform: scale(1, 1);
  }
}

@keyframes showOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  height: 80px;
  margin: auto;
  position: fixed;
  padding-left: 40px;
  transition: all ease 0.3s;
  background-color: $Black-100-0;

  &.mobile-menu {
    background-color: $Dark-Violet;
  }

  @include responsive-max(991px) {
    height: 60px;
  }

  @include responsive-max(480px) {
    height: 60px;
  }

  &.logoCenter {
    padding: 0;
    display: flex;
    background: rgba(32, 31, 31, 0.82);
  }

  &.banner-place {
    top: 30px !important;
  }

  .navigation {
    margin: auto;
    @extend %flex-justify-between;

    @include responsive-max(991px) {
      height: 60px;
    }

    &_logo {
      display: flex;
      align-items: center;

      &_block {
        width: 154px;
        height: 41px;
        cursor: pointer;

        @include responsive-max(991px) {
          width: 120px;
          height: 30px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &_pages {
      width: 890px;
      max-width: 890px;

      @include responsive-max(992px) {
        display: none;
      }

      > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @extend %flex-justify-around;

        > li {
          height: 80px;
          color: $White;
          cursor: pointer;
          padding: 0 21px;
          @extend .n-link-u;
          position: relative;
          @extend %flex-align;
          transition: all ease 0.3s;
          text-transform: uppercase;

          @include responsive-max(1200px) {
            padding: 0 15px;
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: 0;
            height: 4px;
            background-color: $A2-300;
            transition: all ease 0.3s;
            transform: skewX(-15deg);
          }

          &:hover {
            color: $A2-300;
          }

          &.active {
            color: $A2-300;

            &:before {
              left: 0;
              right: 0;
            }
          }
        }
      }
    }

    &_auth {
      @extend %flex-align;

      &_btn-mobile {
        width: 24px;
        height: 24px;
        display: none;
        cursor: pointer;
        margin: 8px 40px 0 0;

        @include responsive-max(991px) {
          margin: 8px 20px 0 0;
        }

        span {
          height: 1px;
          width: 100%;
          display: block;
          margin: 0 0 7px 0;
          position: relative;
          background-color: $A2-300;
          transition: all ease 0.3s;

          &:last-child {
            margin: 0;
            width: 13px;
            float: right;
          }
        }

        &.open {
          span {
            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(1) {
              top: 7px;
              opacity: 1;
              transform: rotate(45deg);
            }

            &:nth-child(3) {
              top: -9px;
              opacity: 1;
              width: 100%;
              transform: rotate(-45deg);
            }
          }
        }

        @include responsive-max(991px) {
          display: block;
        }
      }

      &_signIn-btn {
        padding-right: 40px;

        @include responsive-max(991px) {
          padding-right: 10px;
        }

        button {
          width: 137px;

          @include responsive-max(425px) {
            width: 97px;
          }
        }
      }

      &_user-details {
        @include responsive-max(991px) {
          padding: 12px 0;
        }

        &_panel {
          width: 320px;
          padding: 12px 0;
          @extend %flex-justify-around;
          background-color: $G-1;

          @include responsive-max(991px) {
            width: 80px;
            padding: 11px;
            background: none;
          }

          &_switch-block {
            @extend %flex-column-between;

            &_chain-name {
              display: block;
              padding-top: 3px;
              text-align: center;
              @extend .p-03-regular;
            }

            @include responsive-max(991px) {
              display: none;
            }
          }

          &_info {
            height: 55px;
            @extend %flex-column-center;

            &_user-nickname {
              min-width: 50px;
              min-height: 14px;
              margin-bottom: 5px;
              @extend .p-03-regular;
            }

            @include responsive-max(991px) {
              display: none;
            }

            &_balance {
              @extend %flex-justify-center;

              &_kompete, &_voolah {
                width: 68px;
                text-align: center;
              }

              img {
                width: 24px;
                height: 24px;
              }

              span {
                display: block;
                line-height: 11px;
                @extend .c-u-t-light
              }
            }
          }

          &_options {
            position: relative;
            @extend %flex-align;

            &_img {
              width: 56px;
              height: 56px;
              position: relative;

              @media screen and (max-width: 991px) {
                width: 32px;
                height: 32px;
              }

              .online {
                top: 5px;
                left: 52px;
                width: 8px;
                height: 8px;
                position: absolute;
                border-radius: 50%;
                background: $A1-300;
                border: 2px solid $G-1;
                animation: online 2s ease-in-out infinite alternate;

                @include responsive-max(991px) {
                  left: 28px;
                }
              }
            }

            &_selectDown {
              height: 56px;
              color: $White;
              cursor: pointer;
              padding-left: 10px;
              @extend %flex-align;

              @media screen and (max-width: 991px) {
                height: 33px;
                padding-left: 8px;
              }
            }

            button {
              border: none;
              display: flex;
              cursor: pointer;
              background: none;

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }

            &_hidden-block {
              top: 66px;
              opacity: 1;
              right: -10px;
              width: 280px;
              background: $G-1;
              position: absolute;
              padding: 8px 45px 8px 20px;
              border-top: 1px solid $G-1-O;
              animation: showOpacity 0.2s ease forwards;

              @include responsive-max(991px) {
                display: none;
              }

              &_option-item {
                gap: 16px;
                width: 100%;
                color: $White;
                padding: 10px 0;
                @extend .p-02-light;
                transition: 0.3s ease;
                justify-content: flex-end;

                &:not(:disabled):hover {
                  color: $A2-300;
                }
              }
            }

            .hidden-balance {
              display: none;

              @include responsive-max(991px) {
                display: block;
              }
            }
          }
        }
      }

      .btn-close {
        span:nth-child(2) {
          opacity: 0;
        }

        span:nth-child(1) {
          top: 7px;
          opacity: 1;
          transform: rotate(45deg);
        }

        span:nth-child(3) {
          top: -9px;
          opacity: 1;
          width: 100%;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.navigation-mobile {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 120;
  overflow: auto;
  position: fixed;
  background-color: $Black;
  transition: all ease 0.3s;
  animation: showOpacity 0.1s ease-in-out;

  &.mobile-menu {
    background-color: $Dark-Violet !important;
  }

  &_content {
    min-height: 90vh;
    @extend %flex-column;

    &.safari {
      min-height: 85vh !important;
    }

    &_menu {
      flex-grow: 1;
      @extend %flex-center;

      ul {
        padding: 0;
        list-style: none;
        margin-top: 80px;

        li {
          color: $White;
          display: block;
          padding: 18px 0;
          cursor: pointer;
          margin: 0 0 13px 0;
          text-align: center;
          @extend .h-01-e-bold;
          transition: all ease 0.3s;
          text-transform: uppercase;

          &:hover {
            color: $A2-300;
          }

          @include responsive-max(991px) {
            margin: 0;
          }
        }
      }
    }

    &_user-menu {
      flex-grow: 1;
      margin-top: 20px;
      text-align: center;
      @extend %flex-column-center;

      h3 {
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
      }

      img {
        width: 125px;
        height: 125px;
        margin: 10px 0 15px 0;
      }

      &_balance {
        gap: 15px;
        width: 100%;
        height: 40px;
        background: $G-1-O;
        @extend %flex-justify-center;

        img {
          width: 24px !important;
          height: 24px !important;
          margin: 0 8px 0 0 !important;
        }

        & > div {
          @extend %flex-align;
        }

        .navigation_auth_user-details_panel_switch-block {
          gap: 8px;

          &_chain-name {
            min-width: 35px;
            @extend .p-02-light;
          }
        }
      }

      &_options {
        @extend %flex-column;

        button, a {
          border: none;
          outline: none;
          color: $White;
          font-size: 24px;
          cursor: pointer;
          font-weight: 400;
          margin-top: 40px;
          background: none;
          text-transform: uppercase;
          transition: 0.2s ease-in-out;

          &:not(:disabled):hover {
            color: $A2-300;
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    &_social {
      @extend %flex-justify-center;
    }
  }
}

.no-wallet, .no-balance {
  opacity: 0.4;
}