//Heading
.d-01-e-bold {
  font-size: 72px;
  line-height: 76px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 36px;
    line-height: 40px;
  }
}

.d-02-e-bold {
  font-size: 56px;
  line-height: 68px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.h-01-e-bold {
  font-size: 48px;
  line-height: 56px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.h-02-e-bold {
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.h-03-e-bold {
  font-size: 32px;
  line-height: 44px;
  font-weight: 800;
}

.h-04-e-bold {
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.h-05-e-bold {
  font-size: 18px;
  line-height: 32px;
  font-weight: 800;
}

.h-06-bold {
  font-size: 18px;
  line-height: 32px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 16px;
  }
}

//Body Text
.p-01-regular {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.p-02-light {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-weight: 300;
  }
}

.p-03-regular {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    line-height: 12px;
  }
}

.p-03-bold {
  font-size: 8px;
  line-height: 10px;
  font-weight: 700;

  @media screen and (max-width: 991px) {
    font-weight: 400;
  }
}

.p-04-bold {
  font-size: 8px;
  line-height: 10px;
  font-weight: 700;

  @media screen and (max-width: 991px) {
    font-weight: 400;
  }
}

//other
.n-link-u {
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}

.c-u-t-light {
  font-size: 10px;
  line-height: 14px;
  font-weight: 300;

  @media screen and (max-width: 991px) {
    line-height: 12px;
  }
}

.l-t-regular {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 10px;
    line-height: 12px;
  }
}

.button-u-bold {
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

