@import "src/scss/color2";
@import "src/scss/fontSize";

.rarity-block {
  padding: 4px 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: $White;

  &.rare {
    background: $Rare-V2;
  }

  &.common {
    background: $Common-V2;
  }

  &.ordinary {
    background: $Ordinary-V2;
  }

  &.elite {
    background: $Elite-V2 ;
  }

  &.legendary {
    background: $Legendary-V2;
  }

  &.divine {
    background:$Divine-V2;
  }

  &.default{
    color: $G-6;
    background: #2D2D2D;
  }

  &.lg {
    padding: 7px 12px;
    @extend .p-04-bold;

    @media screen and (max-width: 991px){
      font-size: 6px;
      padding: 4px 12px;
    }
  }

  &.md {
    font-size: 6px;
    line-height: 7.56px;
  }

  &.sm {
    font-size: 5px;
    line-height: 6.3px;
  }
}