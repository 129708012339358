@import "src/scss/fontSize";
@import "src/scss/color2";
@import "src/scss/mixin";
@import "src/scss/vars";

.sidebar-container {
  top: 80px;
  width: 50px;
  min-height: 770px;
  background-color: $G-1-O;

  &.open {
    min-width: 300px;
    width: 300px;

    .sidebar-container_content,
    .sidebar-container_apply-btn {
      display: block;

      button {
        width: 100%;
      }
    }
  }

  @include responsive-max(991px) {
    display: none;
  }

  &_header {
    padding: 15px;
    @extend %flex-justify-between-center;

    & > div {
      cursor: pointer;
      transform: rotate(-180deg);
      transition: 0.3s ease-in-out;
    }

    h6 {
      display: none;
      @extend .h-06-bold;
    }

    &.sideBarOpen {
      & > div {
        transform: rotate(0deg);
      }

      h6 {
        display: block;
      }
    }
  }

  &_content {
    min-height: 597px;
    display: none;
  }

  &_apply-btn {
    display: none;
    padding: 40px 20px 15px;
  }

  &_skeleton {
    width: 300px;
    height: 770px;

    @include responsive-max(991px) {
      display: none;
    }
  }
}

.dropdown {
  height: auto;
  transition: all 0.2s ease-out;

  &.active {
    .dropdown_head {
      background: $G-1-O;

      &_title {
        h4 {
          @extend .p-02-light;
        }
      }

      &_btn {
        transform: rotate(180deg);
        color: $G-3;
      }
    }
  }

  &_head {
    cursor: pointer;
    padding: 8px 25px;
    @extend %flex-justify-between;
    border-bottom: 1px solid $G-1-O;

    &_title {
      @extend %flex-align;

      h4 {
        margin: 0;
        @extend .p-02-light;
        transition: all 0.2s ease-out;
      }
    }

    &_btn {
      color: $White !important;
      transition: all 0.3s ease-out;
    }
  }

  &_content {
    height: 0;
    margin-top: 0;
    overflow: hidden;
    @extend .p-02-light;
    transition: all 0.2s ease-out;

    &.active {
      padding: 1px;
      height: auto;
      margin: 17px 0;
    }

    & > div {
      padding: 0 25px;
      @extend .p-02-light;
      @extend %flex-justify-between-center;

      label {
        padding-left: 15px;
      }
    }

    & > div:not(:first-child) {
      margin-top: 12px;
    }
  }
}

.filter-mobile-wrapper {
  &_btn {
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    background: $Black;
    @extend %flex-center;
    border: 1px solid $G-2-O;

    svg {
      color: $White;
      margin-right: 10px;
    }

    span {
      color: $White;
      @extend .p-02-light;
    }

    &:hover {
      transition: 0.3s ease-out;
      border: 1px solid $G-3-O;
    }
  }

  &_open {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 201;
    position: fixed;
    background: $Black;

    &_head {
      height: 75px;
      position: relative;
      border-bottom: 0.5px solid $G-2;

      h4 {
        width: 100%;
        padding: 25px 0;
        text-align: center;
        @extend .p-02-light;
      }

      &_close {
        border: none;
        color: $G-3-O;
        cursor: pointer;
        background: none;

        svg {
          top: 13px;
          right: 13px;
          position: absolute;
        }

        &:hover {
          svg {
            color: $White
          }
        }
      }
    }

    &_content {
      margin: auto;
      padding: 25px;
      overflow-y: auto;
      max-width: 375px;
      height: calc(100vh - 150px);

      background-color: $Black;
      color: $P50;

      /* width */
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $A2-300;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $A2-200;
      }
    }

    &_btn-block {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 25px;
      display: flex;
      position: absolute;
      background: $Black;
      justify-content: center;
      border-top: 0.5px solid $G-2;

      button {
        width: 325px !important;
      }
    }
  }
}